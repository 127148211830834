export class environment {

    static authUrl = '/api/ShopifyAuth/Auth';
    //#to change to real one in production
    static mainAppOriginUrl =  window.location.origin.match('localhost') ? "http://localhost:3002" : ""; // 'https://www.app.vatshub.com'; //Fto add putback original line 
    static routePaths = {
        mainHost: 'https://www.app.vatshub.com',
        customizeSettings: '/api/CustomizeSettings',
        isAuthedUser: '/api/ShopifyAuth/IsAuthedUser',
        logout: '/api/ShopifyAuth/Logout',
        vatshubSubscriptions: '/api/VastshubSubscriptions',
        vatshubSubscriptions2: '/api/VastshubSubscriptions/GetVastshubSubscription2',
        vatshubNumbers: "/api/VatshubNumbers",
        abandonedCartAlerts2: '/api/AbandonedCartAlerts/GetAbandonedCartAlert2',
        privacyPolicy: '/privacy-policy.html',
        getHomePageData: '/api/main/GetHomePageData',
        setHomePageData: '/api/main/SetHomePageData',
        apiVatsMessagesGetVatsMessageBySuubscriptionId: '/api/VatsMessages/GetVatsMessageBySuubscriptionId',
        getWhatsappMessages: '/api/main/GetMessagesWP',
        sendWhatsappMessage: '/api/main/SendMessageWP',
        get_new_chat_messages: '/api/get_new_chat_messages',
        delete_chat_message: '/api/delete_chat_message',
        jsonStorages: 'api/JsonStorages',
        templates: "/api/Templates",
    }
    static shop = null;
    static literals = {
        planNameFree: 'Free',
        planNameEssential: "Essential",
        planNameStandard: "Standard",
        planNameBusiness: "Business",
        planNameElite: "Elite",
        templateTypeAbandonedCheckout: "abandonedCheckout",
        templateTypeBackInStock: "backInStock",
        templateTypeOrderConfirmation: 'orderConfirmation',
        templateTypeOrderShipAlert: 'orderShipAlert',
        waApiUrl: window.location.origin.match('localhost') ? "http://localhost:3072" : "https://www.wa-api.vatshub.com",
        myshopifyDotcom: ".myshopify.com",
    };
    static abandonedCartRecoveryDeveloped = true;
    static buffer = null;

    static startTime = null;
    static waitB4Call() {
        if (!this.startTime) this.startTime = new Date().getTime();
        return new Promise((resolve, reject) => {
            const t = new Date().getTime();
            window['callingWaitB4Call'] = t;
            console.log('callingWaitB4CallBeforeSettime')
            setTimeout(() => {
                if (window['callingWaitB4Call'] != t) {
                    if (this.startTime && new Date().getTime() - this.startTime >= 600) {
                        console.log('bad_resolve')
                        this.startTime = null;
                        resolve(1);
                    }
                    return;
                }
                else {
                    console.log('good_resolve')
                    console.log('callingWaitB4CallInsideSettime')
                    this.startTime = null;
                    resolve(1);
                }

            }, 200);
        });
    }

    static removeMyshopifyDotCom(shop) {
        if (!shop) return shop;
        const re = new RegExp(environment.literals.myshopifyDotcom, 'gi')
        shop = (shop + '').replace(re, '');
        return shop;
    }

    static addMyshopifyDotCom(shop) {
        if (!shop) return shop;
        const re = new RegExp(environment.literals.myshopifyDotcom, 'gi')
        shop = (shop + '').replace(re, '') + environment.literals.myshopifyDotcom;
        return shop;
    }

    static addWaChat() {


        let defaultFontFamily = `'Work Sans','HelveticaNeue','Helvetica Neue',sans-serif`;

        const s = 'cursor:default;color:' + ('black') + ';background-color:' + ('#e0f7cd')
            + ';margin-right:9px;padding:5px;padding-right: 8px; padding-left: 8px;box-shadow:-1px 2px 5px 0px grey;border-radius: 8px;'
            + `font-family:${defaultFontFamily}`

        let displayTextNearChatBtnHtml = '<div><span style="' + s + '">' + 'Chat with us' + '</span></div>';

        //const right = 200;right: ` + right + `px;
        const left = 20;
        const bottom = 20;

        let div = document.createElement('div');

        div.innerHTML = `
            <div id="waChatContainer" style="                
                height: 60px;
                line-height: 60px;
                border-radius: 30px;
                position: fixed;
                left: ` + left + `px;
                bottom: ` + bottom + `px;
                cursor:pointer;
                z-index: 99999;
                display:flex;
            ">
                        
            <iframe id="whatsappIconIframe" style="` + '' + `height: 70px; width: 70px; border: none;" src="` + environment.mainAppOriginUrl
            + `/content/whatsapp-icon internal.html?color=`
            + '' + `&bgcolor=` + '' + `&whatsappPhoneNumber=919865252229&t=` + new Date().getTime() + `"></iframe>
            
            ${displayTextNearChatBtnHtml}

            </div>
            `;

        document.body.append(div);

    }

    static download(content, fileName, mimeType) {//#to reomve this and use commons/xls-export'; if it has csv option
        var a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
                type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    }
}