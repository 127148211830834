import React, { useState } from 'react';
import { Page, LegacyCard, DropZone, LegacyStack, Button } from '@shopify/polaris';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { environment } from '../../environment';

interface RowData {
    Column1: number;
    Column2: string;
}

const FileUpload: React.FC = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [jsonData, setJsonData] = useState<RowData[] | null>(null);

    const handleDropZoneChange = (files: File[]) => {
        setFiles(files);
        setJsonData(null); // Reset previous JSON data
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            alert('Please select a file');
            return;
        }

        const file = files[0];
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            const fileData = e.target?.result as ArrayBuffer;

            // Determine file type (CSV or XLSX)
            if (file.name.endsWith('.csv')) {
                const result = Papa.parse(fileData, { header: true });
                // Validate CSV data
                if (
                    result.errors.length > 0 ||
                    !result.data.every((row) => !isNaN(Number(row['Column1'])) && row['Column2'].trim() !== '')
                ) {
                    alert('Invalid CSV data');
                    return;
                }
                const jsonData = result.data as RowData[];
                setJsonData(jsonData);
                sendJsonData(jsonData);
            } else if (file.name.endsWith('.xlsx')) {
                const arrayBuffer = new Uint8Array(fileData);
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet) as RowData[];

                // Validate XLSX data
                let col1, col2, count = 0;
                for (const prop in jsonData[0]) {
                    if (count === 0)
                        col1 = prop;
                    else if (count === 1)
                        col2 = prop;
                    else break;
                    count++;
                }

                if (!jsonData.every((row) => {
                    return !isNaN(Number(row[col1])) && row[col2].trim() !== ''
                })) {
                    alert('Invalid XLSX data');
                    return;
                }

                setJsonData(jsonData);
                sendJsonData(jsonData);
            } else {
                console.log('unsupported');
                window.alert('Unsupported file format');
            }
        };

        fileReader.readAsArrayBuffer(file);
    };

    const sendJsonData = async (jsonData: RowData[]) => {
        try {
            // Send jsonData to the server using Axios
            const response = await axios.post( environment.mainAppOriginUrl +  '/api/main/bulk-send-data', jsonData);
            // // // const response = await axios({
            // // //     method:'POST',
            // // //     url:'',
            // // //     headers:{"Content-Type":'application/json'},
            // // //     data:jsonData
            // // // })
            console.log('Server response:', response.data);
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    return (
        <Page title="File Upload and Conversion">
            <LegacyCard sectioned>
                <DropZone accept=".csv, .xlsx" onDrop={handleDropZoneChange} errorOverlayText='Invalid file'>
                    <DropZone.FileUpload />
                </DropZone>
            </LegacyCard>
            <LegacyCard sectioned>
                <LegacyStack alignment="center">
                    <Button primary onClick={handleUpload}>
                        Upload, Convert, and Send
                    </Button>
                </LegacyStack>
            </LegacyCard>
            {jsonData && (
                <LegacyCard sectioned title="JSON Data">
                    <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                </LegacyCard>
            )}
        </Page>
    );
};

export default FileUpload;
