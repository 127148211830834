import * as React from 'react';
import { Link, Page, Card, DataTable, Spinner, TextField, Select, Button, Toast, Icon, Pagination, SelectOption } from '@shopify/polaris';
import { environment } from '../../../environment';
import { ModalWithElements } from '../../sub-components/ModalWithElements';
import { SubscriptionEdit } from '../SubscriptionEdit';
import { YesOrNoModal } from '../../sub-components/YesOrNoModal';
import { SearchMajor } from '@shopify/polaris-icons';
import { DisplayMessageModal } from '../../sub-components/DisplayMessageModal';
import axios from 'axios';
import * as XLSX from 'xlsx/xlsx.mjs';

export class SubscriptionsTable extends React.Component {

    shop: any = environment.shop
    emptyValue: any = null;
    responseJson;
    chk: 'chk_3255972739';
    state = {
        pageSize: 20,
        pageNumber: 1,
        sortedRows: [],
        ready: false,
        rows: this.emptyValue,
        totalRows: 0,//#to add figure out pagination
        polarisPaginationHasNext: true,
        polarisPaginationHasPrevious: true,
        showEditModel: false,
        editingObject: null,
        showYesOrNoForRemoval: false,
        showErrorToast: false,
        showChangesSavedToast: false,
        showVatsmessageModel: false,
        showingVatsmessage: null,
        filterColumn: "Product Title",
        filterCondition: "Contains",
        filterKeyword: "",
        showErrorToastForInvalidSearch: false,
        invalidSearchErrorText: "Please provide search keyword",
        filterOperatorDisabled: false,
        showNormalToast: false,
        normalToastMessage: '',
        exportBtnLoading: false
    }
    sort_dir = -1;
    sort_by = 'createdTime';

    abortController = new AbortController();
    async componentDidMount() {
        try {
            let varToDestroy = await this.getPageData()//({ target: { className: "NextButton"}});
            varToDestroy = null; varToDestroy = undefined;
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }


    whichofnextOrPrevButtonClicked = null;
    getPageData = async (e?) => {
        try {
            let hasPrev = false;
            let hasNext = true;

            //if (e && e.target) {
            //    if (e.target.className.match('PreviousButton'))
            //        this.state.pageNumber--;
            //    else
            //        this.state.pageNumber++;
            //}
            //else {
            //    this.state.pageNumber = 1;
            //    console.log('no event')
            //}

            if (this.state.pageNumber > 1) hasPrev = true;
            else hasPrev = false;

            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.vatshubSubscriptions2 +
                '?shop=' + this.shop + '.myshopify.com&page_number=' + this.state.pageNumber + '&page_size=' + this.state.pageSize +
                '&filter_column=' + this.state.filterColumn + '&filter_condition=' + this.state.filterCondition
                + '&filter_keyword=' + this.state.filterKeyword + (this.sort_by ? '&sort_by=' + this.sort_by : '')
                + (this.sort_dir ? '&sort_dir=' + this.sort_dir : ''), { signal: this.abortController.signal });

            this.responseJson = await resp.json();

            let dataOptimized: any = [];

            this.responseJson.data.forEach((it) => {
                let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
                it.createdTime = it.createdTime || "";
                it.productTitle = it.productTitle || "";
                it.variantTitle = it.variantTitle || "";

                let productTitle = it.productTitle.length > 20 ? it.productTitle.substring(0, 20) + '...' : it.productTitle;

                dataOptimized.push([it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{productTitle}</Link>,
                it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No",
                //<Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button>
                <div className="action-btns">
                    <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button>
                    <Button onClick={() => { this.viewMessage(it.id); }} plain>View Message</Button>
                </div>
                    //<Link id={it.id}> View Message </Link>
                ]);
            });

            let totalRows = this.responseJson.totalCount;
            if (((this.state.pageNumber * this.state.pageSize)) >= totalRows) hasNext = false;

            setTimeout(() => {
                this.setState({
                    rows: dataOptimized,
                    polarisPaginationHasNext: hasNext,
                    polarisPaginationHasPrevious: hasPrev,
                    totalRows: totalRows,
                    pageSize: this.state.pageSize,
                    pageNumber: this.state.pageNumber
                })
            }, 50);
        } catch (e) {
            console.log(e);
            this.setState({
                rows: [],
                polarisPaginationHasNext: false,
                polarisPaginationHasPrevious: false,
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0
            });
        }
    }

    render() {
        var handleFilterColumnChange = (val) => {
            let filterOperatorDisabled = val !== 'Product Title'; let filterCondition;
            if (filterOperatorDisabled) filterCondition = 'Is equal to'; this.setState({ filterColumn: val, filterOperatorDisabled, filterCondition })
        }
        var handleFilterConditionChange = (val) => { this.setState({ filterCondition: val }) }, handleFilterKeywordValueChange = (val) => { this.setState({ filterKeyword: val }) };

        return (
            this.state.rows ? <div className="subscriptions-by-product">

                {this.state.showEditModel && <ModalWithElements modalObject={this.state.editingObject} handleYesOrNoResult={this.handleCancelOrSave} elements={SubscriptionEdit} messageToDisplay="Edit Restock Alert Subscription" />}
                {this.state.showYesOrNoForRemoval && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to remove?" secondaryMessageToDisplay="This back in stock alert will be deleted and won't trigger." />}
                {this.state.showVatsmessageModel && <DisplayMessageModal handleOkClick={this.handleMessageModelClose} messageToDisplay="Message sent" secondaryMessageToDisplay={this.vatsMessageToDisplay} />}
                {this.state.showChangesSavedToast && <Toast content="Changes saved" duration={2000} onDismiss={() => { this.setState({ showChangesSavedToast: false }) }} />}
                {this.state.showErrorToast && <Toast error content="Something went wrong" duration={2000} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
                {this.state.showErrorToastForInvalidSearch && <Toast error content={this.state.invalidSearchErrorText} duration={2000} onDismiss={() => { this.setState({ showErrorToastForInvalidSearch: false }) }} />}
                {this.state.showNormalToast && <Toast content={this.state.normalToastMessage} duration={5000} onDismiss={() => { this.setState({ showNormalToast: false }) }} />}
                <div className="paginator-container ">
                    <Pagination
                        hasNext={this.state.polarisPaginationHasNext}
                        onNext={() => {
                            this.setState({ rows: this.emptyValue, pageNumber: this.state.pageNumber + 1 }, () => {
                                this.getPageData()
                            });
                        }}
                        hasPrevious={this.state.polarisPaginationHasPrevious}
                        onPrevious={() => {
                            this.setState({ rows: this.emptyValue, pageNumber: this.state.pageNumber - 1 }, () => {
                                this.getPageData()
                            });
                        }}
                    />
                </div>
                <Page>
                    <label>Filter:</label>
                    <div className="filter-conatiner">

                        <div className="filtering-section">
                            <Select
                                labelHidden
                                label="Filter Column"
                                options={['Product Title', 'WhatsApp Number', 'Product ID', 'Variant ID', 'Alert Sent (Yes or No)']}
                                value={this.state.filterColumn}
                                onChange={handleFilterColumnChange}
                            />
                        </div>
                        <div className="filtering-section">
                            <Select
                                labelHidden
                                label="Filter Condition"
                                options={['Is equal to', "Contains"]}
                                value={this.state.filterCondition}
                                onChange={handleFilterConditionChange}
                                disabled={this.state.filterOperatorDisabled}
                            />
                        </div>
                        <div className="filtering-section">
                            <TextField
                                placeholder="Filter keyword"
                                prefix={<Icon source={SearchMajor} />}
                                labelHidden
                                label="Filter Keyword"
                                value={this.state.filterKeyword}
                                onChange={handleFilterKeywordValueChange}
                                clearButton
                                onClearButtonClick={() => { this.setState({ filterKeyword: "" }, this.search); }}
                                autoComplete='off'
                            />
                        </div>
                        <div className='search-save-container'>
                            <Button primary onClick={this.search}>Search  </Button>
                            <Button plain onClick={this.exportData} loading={this.state.exportBtnLoading}>Export</Button>
                        </div>


                    </div>

                    <Card>
                        <DataTable

                            columnContentTypes={[
                                'text',
                                'text',
                                'numeric',
                                'numeric',
                                "text",
                                "text"
                            ]}
                            headings={[
                                'Created on',
                                'Product',
                                'Variant',
                                'Whatsapp Number',
                                'Alert Sent',
                                'Actions'
                            ]}
                            rows={this.state.rows}
                            sortable={[true, false, false, true]}
                            defaultSortDirection="descending"
                            initialSortColumnIndex={0}
                            onSort={this.handleSort}
                            footerContent={`Showing ${(((this.state.pageNumber - 1) * this.state.pageSize) + 1) + ' to ' + (((this.state.pageNumber - 1) * this.state.pageSize) + this.state.rows.length)} of ${this.state.totalRows} results`}

                        />
                    </Card>
                </Page></div> : <div className="home-page-loading-spinner-container"><Spinner accessibilityLabel="Loading the data" size="large" /></div>



        );

    }
    handleSort = (headingIndex, direction) => {
        console.log({ headingIndex, direction });

        if (direction === 'descending') {
            this.sort_dir = -1
        }
        else if (direction === 'ascending') {
            this.sort_dir = 1
        }

        if (headingIndex == 0) {
            this.sort_by = 'createdTime';
        }
        else if (headingIndex == 3) {
            this.sort_by = 'vatshubnumber';
        }
        else return;

        this.state.pageNumber = 0;
        this.getPageData(); //sort_dir
    }

    editingId
    editClicked = (id) => {
        console.log('edit clicked');//edit things from a separate page
        this.editingId = id;

        let vatshubSubscription;
        this.responseJson.data.some((it) => {
            if (it.id == this.editingId) {
                vatshubSubscription = it;
                return;
            }
        });
        console.log(vatshubSubscription)
        this.setState({
            showEditModel: true,
            editingObject: vatshubSubscription
        });
    }

    viewingId
    vatsMessageToDisplay
    viewMessage = async (id) => {
        //if (this) return;
        try {//to check this loading not displayed showVatsmessageModel
            let showingVatsmessage: any = {
                body: "Loading"
            };

            this.vatsMessageToDisplay = <div className="text-align-center" ><Spinner /></div>;
            this.setState({
                // showModalSpinner: true
                //<div className="text-align-center" ><Spinner  /></div>
                showVatsmessageModel: true
            }, () => {
                console.log('done');
            });

            this.viewingId = id;

            let vatshubSubscription;
            this.responseJson.data.some((it) => {
                if (it.id == this.viewingId) {
                    vatshubSubscription = it;
                    return;
                }
            });

            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.apiVatsMessagesGetVatsMessageBySuubscriptionId +
                '?check=' + vatshubSubscription.id);

            console.log(resp);
            console.log("before redirect check");
            if (resp && resp.redirected === true) {
                console.log("inside redirected");
                window.location.href = "/account/login"
                return
            }
            console.log("after redirect check");
            let responseJson = await resp.json();
            console.log(responseJson);

            // let showVatsmessageModel = {body:"Not found"};
            if (!Array.isArray(responseJson) || responseJson.length < 1) {
                console.log('invalid response');
                showingVatsmessage = { body: <span>Not found</span> };
            }
            else {
                showingVatsmessage = responseJson[0];
            }
            this.vatsMessageToDisplay = showingVatsmessage.body

            // setTimeout(() => {
            this.setState({
                showingVatsmessage: showingVatsmessage,
                showVatsmessageModel: true
            });
            //  }, 2000);
        } catch (e) {
            console.log(e);
            console.log("something went wrong");
        }
    }


    handleCancelOrSave = async (resultingObject) => {

        let showSuccessMsg = false;
        let showErrorMsg = false;
        let editingObject;
        try {
            if (resultingObject && resultingObject.id) {
                const updateResponse = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + resultingObject.id, {
                    method: 'PUT',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(resultingObject)
                });


                if (updateResponse.ok) {
                    editingObject = resultingObject;
                    showSuccessMsg = true;
                }
            }

        } catch (e) {
            console.log(e);
            showErrorMsg = true
        }

        if (editingObject && editingObject.id) {
            this.responseJson.data.some((it, i) => {
                if (it.id === editingObject.id) {
                    let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
                    it.createdTime = it.createdTime || "";
                    it.productTitle = it.productTitle || "";
                    it.variantTitle = it.variantTitle || "";
                    this.state.rows[i] = [it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{it.productTitle.substring(0, 30)}</Link>,
                    it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No", <div> <Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button> <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button></div>];
                    return true;
                }
            });
        }


        this.setState({
            showEditModel: false,
            showErrorToast: showErrorMsg,
            showChangesSavedToast: showSuccessMsg
        });
    }

    idToRemove = null;
    removeClick = (id) => {
        this.idToRemove = id;
        this.setState({ showYesOrNoForRemoval: true });
    }

    handleYesOrNoResult = async (val) => {
        let showChangesSavedToast = false;
        try {

            if (val === 'yes') {
                const resp = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + this.idToRemove, {
                    method: 'DELETE'
                });
                console.log(resp);
                if (resp.ok) {
                    this.responseJson.data.forEach((it, i) => {
                        if (it.id === this.idToRemove) {
                            this.state.rows.splice(i, 1);
                        }
                    })
                    showChangesSavedToast = true;
                }

            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            showYesOrNoForRemoval: false,
            showChangesSavedToast: showChangesSavedToast
        });
    }


    search = () => {
        this.setState({ pageNumber: 1 }, () => {
            this.getPageData();
        });
    }

    exportData = async () => {
        this.setState({
            normalToastMessage: 'Please wait while preparing the list. If the number of records is high, it may take 1 to 3 minutes.',
            showNormalToast: true,
            exportBtnLoading: true
        });
        try {

            const resp = await axios(environment.mainAppOriginUrl + environment.routePaths.vatshubSubscriptions2 +
                '?shop=' + this.shop + '.myshopify.com' +
                '&filter_column=' + this.state.filterColumn + '&filter_condition=' + this.state.filterCondition
                + '&filter_keyword=' + this.state.filterKeyword + (this.sort_by ? '&sort_by=' + this.sort_by : '')
                + (this.sort_dir ? '&sort_dir=' + this.sort_dir : '') + '&page_size=30000');

            const data = await resp.data;
            let mainArr = [];
            data.data.forEach((dataIt) => {
                let alertSent = "No";
                if (dataIt.triggerCount >= 1) alertSent = "Yes";

                let createdOn = '';
                if (dataIt.createdTime) {
                    dataIt.createdTime = dataIt.createdTime + '';
                    createdOn = dataIt.createdTime.substring(0, dataIt.createdTime.indexOf('T'));
                }
                mainArr.push({
                    "Variant Id": dataIt.variantid || '', SKU: dataIt.variantSKU || '',
                    "Product Id": dataIt.productid, "Whatsapp Number": dataIt.vatshubnumber,
                    "Alert Sent": alertSent, "Product Title": dataIt.productTitle || '',
                    "Variant Title": dataIt.variantTitle, "Creted On": createdOn, "Public Product Link": dataIt.productLink
                });
            });

            // var xls = new XlsExport(mainArr, 'subscription_data');
            // xls.exportToXLS('Subscription_Data_Vatshub_' + new Date().toLocaleDateString().replace(/[^0-9^_]/g, '_') + '_' + new Date().getTime() + '.xlsx');
            const worksheet = XLSX.utils.json_to_sheet(mainArr);
            worksheet["!cols"] = [{ wch: 17 },{ wch: 17 },{ wch: 17 },{ wch: 17 },{ wch: 12 },{ wch: 17 },{ wch: 17 },{ wch: 15 },{ wch: 17 }];
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'BIS_Subscription_Data_Vatshub');
            const fileName = 'BIS_Subscription_Data_Vatshub_' + new Date().toLocaleDateString().replace(/[^0-9^_]/g, '_') + '_' + new Date().getTime() + '.xlsx'
            XLSX.writeFile(workbook, fileName);
        } catch (error) {
            console.log(error);
        }
        this.setState({
            showNormalToast: false,
            exportBtnLoading: false
        });

    }

    handleMessageModelClose = () => {

        this.setState({
            showVatsmessageModel: false,
            showingVatsmessage: null
        }, () => {

        });

    }

}