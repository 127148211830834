import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './old-index-sass.css';
import App from './App';
//import 'mdbreact/dist/css/mdb.css';
import '@shopify/polaris/build/esm/styles.css'
import { AuthService } from './commons/AuthService';
import { BrowserRouter } from 'react-router-dom';
import { environment } from './environment';


console.log('check___________')
AuthService.authenticate().then((d: any) => {
  console.log('AuthService.isAuthed_____' + AuthService.isAuthed + '__' + AuthService.shop);
  environment.shop = AuthService.shop;
  //console.log('shop_----' + environment.shop)

  //var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  
  if (!document.cookie.match('byBrPA=1') && !window.location.origin.match('localhost')) {
    

    let obj: any = {
      'shop': environment.shop,
      'plan': AuthService.planName || 'no_plan',
    };


    if (window['smartlook']) {
      try {
        window['smartlook']('identify', obj.shop, obj);
      } catch (error) {
        console.log(error);
      }
    }
    else console.log('no_smartlook');

    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/60e66744649e0a0a5ccb1814/1fa206pqn';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      s1.onload = function () {
        setTimeout(() => {
          try {
            if (window['Tawk_API']) {


              if (AuthService.email) {
                obj.email = AuthService.email;
              }
              if (AuthService.charge_active === true) {
                obj.charge_active = true;
              }

              if (AuthService.user) {
                obj.name = AuthService.user;
              }

              window['Tawk_API'].setAttributes(obj, function (error) { if (error) console.log(error) });


            }
            else {
              console.log("no window['Tawk_API']");
            }

          } catch (error) {
            console.log(error);
          }
        }, 2000);
      }

      
       //<script src="http://localhost:3002/js/waButton.js"></script>

    

    })();
  }

  environment.addWaChat();

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  console.log('root_render_called'); //           <React.StrictMode>
  root.render(

    <BrowserRouter>
      <App />
    </BrowserRouter>

  );
}).catch((er) => {

  console.log('error in auth fucntions call');
  console.log(er);
});