import * as React from 'react';
import Welcome from './Welcome';
//import { MDBRow } from 'mdbreact';
//import AdminCardSection1 from './sections/AdminCardSection1';
//import AdminCardSection2 from './sections/AdminCardSection2';
//import TableSection from './sections/TableSection';
//import BreadcrumSection from './sections/BreadcrumSection';
//import ChartSection1 from './sections/ChartSection1';
//import ChartSection2 from './sections/ChartSection2';
//import ModalSection from './sections/ModalSection';


class DashboardPage extends React.Component {

    state = {
        selected: 0
    }

    render() {
        return (
            <Welcome></Welcome>
        )

    }


    handleTabChange = (v) => {

        this.setState({
            selected: v
        })
    };


}

export default DashboardPage;