import * as React from 'react';
import { Tabs, Button, Page, PageActions, TextField, Select, Toast, OptionList, LegacyStack, ButtonGroup, Tooltip, LegacyCard, HorizontalGrid, Checkbox, RangeSlider, Banner, ExceptionList, Autocomplete } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import { Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer } from '@shopify/polaris';
import { environment } from '../../environment';
import { ICustomizeSetting } from '../../models/CustomizeSetting';
import { VatsColorPicker } from '../sub-components/VatsColorPicker';
import { YesOrNoModal } from '../sub-components/YesOrNoModal';
import { PageInteractor } from '../../commons/PageInteractor';


/*
                         <TextField id="abandonedCheckoutAlertSendAfterMinutesSecond"
                            label="Send second alert after "
                            type="number"
                            value={this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesSecond}
                            onChange={this.handleTextFieldChange}
                            connectedRight={
                                <Select
                                    label=""
                                    options={[{ label: "Minutes", title: "Minutes", value: "minute" },

                                    ]}
                                    onChange={this.minutesOrHours}
                                    value={this.state.minuteOrHour}

                                />
                            }
                        />
 */

class CustomizeSetting implements ICustomizeSetting {
    id?: number;
    shop?: string;
    mainWhatsAppMeBtnText?: string;
    mainWhatsAppMeBtnColor?: string;
    mainWhatsAppMeBtnBgColor?: string;
    mainWhatsAppMeBtnPosition?: string;
    formBgColor?: string;
    formFormHeading?: string;
    formFormHeadingTextColor?: string;
    formFormHeadingTextSize?: string;
    formSelectBoxBgColor?: string;
    formWhatsAppNumberTextboxColor?: string;
    formWhatsAppNumberTextboxBgColor?: string;
    formWhatsAppNumberTextboxPlaceholderText?: string;
    formWhatsAppNumberTextboxPlaceholderTextForError?: string;
    whatsAppMeBtnText?: string;
    whatsAppMeBtnColor?: string;
    whatsAppMeBtnBgColor?: string;
    confirmationMessageText?: string;
    confirmationMessageColor?: string;
    whatsappMessageTemplateId?: string;
    abandonedCartAlertTemplateId?: string;
    mainWhatsAppMeBtnWhenToDisplay: any;
    abandonedCheckoutAlertSendAfterMinutesFirst;
    showYesOrNoForActiveTemplateDelete;
    templateIdOrderConfirmation?: string;
    templateIdOrderShipAlert?: string;

    whatsappPhoneNumber?: string;
    chatButtonPosition?: string;
    chatButtonLineColor?: string;
    chatButtonFillColor: string;
    prefilledMessage?: string;
    textNearButtonTurnedOn?: boolean;
    textNearChatButton: string;
    textNearChatButtonColor: string;
    textNearChatButtonBgColor: string;
    textNearChatButtonFontFamily: string;
    buttonSize: number;
    distanceLeft: number;
    distanceRight: number;
    distanceBottom: number;
}


const options = [
    { value: `default`, label: `'Work Sans','HelveticaNeue','Helvetica Neue',sans-serif` },
    { value: `cursive`, label: `cursive` },
    { value: `emoji`, label: `emoji` },
    { value: `fangsong`, label: `fangsong` },
    { value: `fantasy`, label: `fantasy` },
    { value: `math`, label: `math` },
    { value: `monospace`, label: `monospace` },
    { value: `sans-serif`, label: `sans-serif` },
    { value: `serif`, label: `serif` },
    { value: `system-ui`, label: `system-ui` },
    { value: `ui-monospace`, label: `ui-monospace` },
    { value: `ui-rounded`, label: `ui-rounded` },
    { value: `ui-sans-serif`, label: `ui-sans-serif` },
    { value: `Optima, sans-serif`, label: `Optima, sans-serif` },
    { value: `Arial Narrow, sans-serif`, label: `Arial Narrow, sans-serif` },
    { value: `Times, Times New Roman, serif`, label: `Times, Times New Roman, serif` },
    { value: `Courier New, monospace`, label: `Courier New, monospace` },
    { value: `Comic Sans MS, Comic Sans, cursive`, label: `Comic Sans MS, Comic Sans, cursive` },
    { value: `Apple Chancery, cursive`, label: `Apple Chancery, cursive` },
    { value: `Google Sans`, label: `'Google Sans',sans-serif` },
    { value: `Bradley Hand, cursive`, label: `Bradley Hand, cursive` },
    { value: `Brush Script MT, Brush Script Std, cursive`, label: `Brush Script MT, Brush Script Std, cursive` },
    { value: `Snell Roundhand, cursive`, label: `Snell Roundhand, cursive` },
    { value: `Impact, fantasy`, label: `Impact, fantasy` },
    { value: `Luminari, fantasy`, label: `Luminari, fantasy` },
    { value: `Chalkduster, fantasy`, label: `Chalkduster, fantasy` },
    { value: `Marker Felt, fantasy`, label: `Marker Felt, fantasy` },
    { value: `Trattatello, fantasy`, label: `Trattatello, fantasy` }

]
//var templateOptions = []
//var templateoptionsAbandonedCart = []
export class CustomizePage extends React.Component<any, any> {
    setSubNavColors() {

        const subNavigationItems = [
            {
                url: '/customize?tab=subscription-form',
                excludePaths: ['#'],
                label: 'Subscription Form - Restock Alert',

            },
            {
                url: '/customize?tab=bis-template',
                excludePaths: ['#'],
                label: 'Template-Restock alert',


            },
            {
                url: '/customize?tab=abandoned-checkout',
                excludePaths: ['#'],
                label: 'Abandoned Checkout',

            },
            {
                url: '/customize?tab=order-confirmation',
                excludePaths: ['#'],
                label: 'Order Confirmation',

            },
            {
                url: '/customize?tab=order-ship-alert',
                excludePaths: ['#'],
                label: 'Order Ship Alert',

            },
            {
                url: '/customize?tab=chat-button',
                excludePaths: ['#'],
                label: 'Chat Button',

            }
        ];
        subNavigationItems.some((it) => {
            if (window.location.pathname + window.location.search === it.url) {
                setTimeout(() => {
                    const allAEl: any = window.document.querySelectorAll('.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text');
                    for (let i = 0; i < allAEl.length; i++) {
                        allAEl[i].style.color = 'unset';
                        allAEl[i].parentElement.style.backgroundColor = 'unset';
                        if (allAEl[i].innerText == it.label) {
                            allAEl[i].style.color = 'rgba(0, 122, 92, 1)';
                            allAEl[i].parentElement.style.backgroundColor = '#dfe9e3';
                        }
                    }
                }, 100);
            }
        });
    }

    resizeUpdateTime = 0;
    customizeSettingsUnchangedCopy = ""
    customizeSettingsUnchangedCopyNested = {
        copy1: new CustomizeSetting()
    }
    shop = environment.shop;
    toastMessage = '';
    errorToastMessage = '';
    constructor(props: any) {

        super(props);
        console.log('CustomizePage page__')
        /*
        for (var i = templateOptions.length - 1; i > -1; i--) {
            if (this.shop && templateOptions[i].shop && !templateOptions[i].shop.match(this.shop.replace('.myshopify.com',''))) {
                templateOptions.splice(i, 1)
            }
        }

        for (var i = templateoptionsAbandonedCart.length - 1; i > -1; i--) {
            if (this.shop && templateoptionsAbandonedCart[i].shop && !templateoptionsAbandonedCart[i].shop.match(this.shop.replace('.myshopify.com', '')) ) {
                templateoptionsAbandonedCart.splice(i, 1)
            }
        }
        */


        const tab = PageInteractor.getQueryValueByName('tab');
        let selectedTabIndex = 0;
        if (tab) {
            this.tabs.some((it, i) => {
                if (it.id.toLowerCase() === tab.toLowerCase()) {
                    selectedTabIndex = i;
                }
            });
        }

        this.state = {
            ready: false,
            customizeSettings: new CustomizeSetting(),
            customizeSettingsIsDefault: false,
            customizeSettingsId: null,
            saveBtnDisabled: true,
            saveBtnDisabledTemplate: true,
            saveBtnDisabledTemplateAbandonedCart: true,
            saveBtnLoading: false,
            saveBtnLoadingTemplate: false,
            showSaveSuccessToast: false,
            showSaveErrorToast: false,
            showToast: false,
            selectedTabIndex,
            selectedTemplateOption: [''],//[templateOptions[1].value],
            selectedTemplateIndex: 0,
            templateMessagePreview: [''], //[templateOptions[1].message],
            showYesOrNo: false,
            selectedTemplateOptionAbandonedCart: [''],//[templateoptionsAbandonedCart[1].value],
            selectedTemplateIndexAbandonedCart: 0,//selectedTemplateIndexAbandonedCart
            templateMessagePreviewAbandonedAlert: [''],
            loadSimulator: true,
            minuteOrHour: "minute",
            ownTemplateTitle: "",
            ownTemplateText: "Hello, We are From {SHOP_NAME}, {PRODUCT_TITLE}, the product you subscribed alert for is now selling on our shop, grab yours={PRODUCT_LINK}",
            disableAddTemplateButton: true,
            ownTemplateAbandCartTitle: "",
            ownTemplateAbandCartText: "Hi {CUSTOMER_NAME}, You left some items at your cart on our site! get them before they're gone through this link: {CHECKOUT_URL}",
            disableAddTemplateAbandCartButton: true,
            templateOptions: [],
            templateoptionsAbandonedCart: [],
            templateOptionsOrderConfirmation: [],
            templateOptionsOrderShipAlert: [],
            disableDeleteSelectedTemplateButton: true,
            disableDeleteSelectedTemplateButtonAbandonedCart: true,
            showCanceledToast: false,
            showErrorToast: false,
            showYesOrNoForActiveTemplateDelete: false,
            saveBtnDisabledTemplateOrderConfirmation: true,
            selectedTemplateOptionOrderConfirmation: [''],
            disableDeleteSelectedTemplateButtonOrderConfirmation: true,
            ownTemplateTitleOrderConfirmation: '',
            ownTemplateTextOrderConfirmation: "Hi {CUSTOMER_NAME}, Thank you for purchase from our site {SHOP_NAME}, we received your order {ORDER_NUMBER}. Once your package ships, we will send you a message with a tracking number so that you can track the movement of your package. Order link: {ORDER_LINK}",
            disableAddTemplateButtonOrderConfirmation: true,
            selectedTemplateIndexOrderConfirmation: 0,
            templateMessagePreviewOrderConfirmation: [''],
            saveBtnDisabledTemplateOrderShipAlert: true,
            selectedTemplateOptionOrderShipAlert: [''],
            disableDeleteSelectedTemplateButtonOrderShipAlert: true,
            ownTemplateTitleOrderShipAlert: '',
            ownTemplateTextOrderShipAlert: "Hi there, {CUSTOMER_NAME}, good news! Your order {ORDER_NUMBER} is on the way to you. Track your order movements by this tracking number:{TRACKING_NUMBER} and tracking url:{TRACKING_URL}. Order link:{ORDER_LINK}",
            disableAddTemplateButtonOrderShipAlert: true,
            selectedTemplateIndexOrderShipAlert: 0,
            templateMessagePreviewOrderShipAlert: [''],
            selectedOptions: []
        }

    }
    state = {
        ready: false,
        customizeSettings: new CustomizeSetting(),
        customizeSettingsIsDefault: false,
        customizeSettingsId: null,
        saveBtnDisabled: true,
        saveBtnDisabledTemplate: true,
        saveBtnDisabledTemplateAbandonedCart: true,
        saveBtnLoading: false,
        saveBtnLoadingTemplate: false,
        showSaveSuccessToast: false,
        showSaveErrorToast: false,
        showToast: false,
        selectedTabIndex: 0,
        selectedTemplateOption: [''],//[templateOptions[1].value],
        selectedTemplateIndex: 0,
        templateMessagePreview: [''], //[templateOptions[1].message],
        showYesOrNo: false,
        selectedTemplateOptionAbandonedCart: [''],//[templateoptionsAbandonedCart[1].value],
        selectedTemplateIndexAbandonedCart: 0,//selectedTemplateIndexAbandonedCart
        templateMessagePreviewAbandonedAlert: [''],//[templateoptionsAbandonedCart[1].message],
        loadSimulator: true,
        minuteOrHour: "minute",
        ownTemplateTitle: "",
        ownTemplateText: "Hello, We are From {SHOP_NAME}, {PRODUCT_TITLE}, the product you subscribed alert for is now selling on our shop, grab yours={PRODUCT_LINK}",
        disableAddTemplateButton: true,
        ownTemplateAbandCartTitle: "",
        ownTemplateAbandCartText: "Hi {CUSTOMER_NAME}, You left some items at your cart on our site! get them before they're gone through this link: {CHECKOUT_URL}",
        disableAddTemplateAbandCartButton: true,
        templateOptions: [],
        templateoptionsAbandonedCart: [],
        templateOptionsOrderConfirmation: [],
        templateOptionsOrderShipAlert: [],
        disableDeleteSelectedTemplateButton: true,
        disableDeleteSelectedTemplateButtonAbandonedCart: true,
        showCanceledToast: false,
        showErrorToast: false,
        showYesOrNoForActiveTemplateDelete: false,
        saveBtnDisabledTemplateOrderConfirmation: true,
        selectedTemplateOptionOrderConfirmation: [''],
        disableDeleteSelectedTemplateButtonOrderConfirmation: true,
        ownTemplateTitleOrderConfirmation: '',
        ownTemplateTextOrderConfirmation: "Hi {CUSTOMER_NAME}, Thank you for purcahse from our site {SHOP_NAME}, we received your order {ORDER_NUMBER}. Once your package ships, we will send you a message with a tracking number so that you can track the movement of your package. Order link: {ORDER_LINK}",
        disableAddTemplateButtonOrderConfirmation: true,
        selectedTemplateIndexOrderConfirmation: 0,
        templateMessagePreviewOrderConfirmation: [''],
        saveBtnDisabledTemplateOrderShipAlert: true,
        selectedTemplateOptionOrderShipAlert: [''],
        disableDeleteSelectedTemplateButtonOrderShipAlert: true,
        ownTemplateTitleOrderShipAlert: '',
        ownTemplateTextOrderShipAlert: "Hi there, {CUSTOMER_NAME}, good news! Your order {ORDER_NUMBER} is on the way to you. Track your order movements by this tracking number:{TRACKING_NUMBER} and tracking url:{TRACKING_URL}. Order link:{ORDER_LINK}",
        disableAddTemplateButtonOrderShipAlert: true,
        selectedTemplateIndexOrderShipAlert: 0,
        templateMessagePreviewOrderShipAlert: [''],
        selectedOptions: []

    }

    setSelectedTab = (selectedTabIndex) => {
        let selectedTabId;
        this.tabs.some((t, i) => {
            if (selectedTabIndex == i) {
                selectedTabId = t.id;
                return true;
            }
        });
        PageInteractor.setQueryString('tab', selectedTabId);
        this.setState({ selectedTabIndex: selectedTabIndex });
    }

    async componentDidMount() {
        try {
            document.title = "Customize-Vatshub Dashboard";

            await environment.waitB4Call();
            //console.log('environment.shop: ' + this.shop);
            const respTemplate = await fetch(environment.mainAppOriginUrl + '/api/Templates?shopOrEmpty='
                + this.shop);

            let dataTemplate = await respTemplate.json();

            const templateOptions = this.state.templateOptions;
            const templateoptionsAbandonedCart = this.state.templateoptionsAbandonedCart;
            const templateoptionsOrderConfirmation = this.state.templateOptionsOrderConfirmation;
            const templateoptionsOrderShipAlert = this.state.templateOptionsOrderShipAlert;
            let time = new Date().getTime();
            dataTemplate.forEach((template, index) => {
                template.key = time + index;
                if (template.type === environment.literals.templateTypeBackInStock) {
                    templateOptions.push(template);
                }
                else if (template.type === environment.literals.templateTypeAbandonedCheckout) {
                    templateoptionsAbandonedCart.push(template);
                }
                else if (template.type === environment.literals.templateTypeOrderConfirmation) {
                    templateoptionsOrderConfirmation.push(template);
                }
                else if (template.type === environment.literals.templateTypeOrderShipAlert) {
                    templateoptionsOrderShipAlert.push(template);
                }

            });

            this.setState({
                templateOptions,
                templateoptionsAbandonedCart
            });


            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.customizeSettings +
                '/' + this.shop);

            let data = await resp.json();

            if (data.formFormHeadingTextSize) {

                data.formFormHeadingTextSize = (data.formFormHeadingTextSize + '').replace(/[^0-9]/g, '');

            }

            let id = data.id;


            let customizeSettingsIsDefault = false
            if (data.shop == "default") {
                customizeSettingsIsDefault = true;
                data.shop = environment.shop;
                delete data.id;
            }
            //abandonedCartAlertTemplateId
            let selectedTemplateIndex = this.getIndexOfTemplateOptionsValue(data.whatsappMessageTemplateId);
            let selectedTemplateIndexAbandonedCart = this.getIndexOfTemplateOptionsValueAbandonedCart(data.abandonedCartAlertTemplateId || 0);

            let selectedTemplateIndexOrderConfirmation = this.getIndexOfTemplateOptionsValueOrderConfirmation(data.templateIdOrderConfirmation || 0);

            let selectedTemplateIndexOrderShipAlert = this.getIndexOfTemplateOptionsValueOrderShipAlert(data.templateIdOrderShipAlert || 0);

            data.mainWhatsAppMeBtnWhenToDisplay = data.mainWhatsAppMeBtnWhenToDisplay || '';
            data.templateIdOrderConfirmation = data.templateIdOrderConfirmation || '';
            data.templateIdOrderShipAlert = data.templateIdOrderShipAlert || '';
            this.setState({
                customizeSettings: data,
                customizeSettingsIsDefault: customizeSettingsIsDefault,
                customizeSettingsId: id,
                selectedTemplateIndex: selectedTemplateIndex,
                selectedTemplateOption: [data.whatsappMessageTemplateId],
                selectedTemplateIndexAbandonedCart,
                selectedTemplateOptionAbandonedCart: [data.abandonedCartAlertTemplateId],
                selectedTemplateIndexOrderConfirmation,
                selectedTemplateOptionOrderConfirmation: [data.templateIdOrderConfirmation],
                selectedTemplateIndexOrderShipAlert,
                selectedTemplateOptionOrderShipAlert: [data.templateIdOrderShipAlert],
                ready: true
            });

            console.log('here4');
            this.customizeSettingsUnchangedCopy = JSON.stringify(data);

            (() => {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                    // try {
                    //     window.document.querySelector('#subscription-form').scrollIntoView();
                    // } catch (error) {
                    //     console.log(error)
                    // }
                }, 50);
            })();

            console.log('scroll_-1');
            setTimeout(() => {
                let iframe: any = document.getElementsByClassName('simulator-iframe')[0];

                if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage({ customizeSettings: this.state.customizeSettings }, '*');

                }
                window.addEventListener('scroll', this.scrollListener);
            }, 200);

        } catch (e) {
            console.log(e);
        }
    }

    alreadySentForFormOpen = false;
    scrollListener = (e) => {//#to remove this listener once the component destroyed
        console.log('scroll_1');
        if (this.alreadySentForFormOpen) return;
        if (!this.state.loadSimulator) {
            this.loadSimulator();
        }
        let iframe: any = document.getElementsByClassName('simulator-iframe')[0];
        if (!iframe || !iframe.contentWindow) return;
        let contecntElement = document.getElementById('content');
        if (contecntElement) {
            let rects: any = contecntElement.getClientRects();
            if (rects[0].y < -550) {
                this.alreadySentForFormOpen = true;
                iframe.contentWindow.postMessage({ openForm: true }, '*');
            }

        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.scrollListener);
    }

    handleTabChange = (selectedTabIndex) => this.setSelectedTab(selectedTabIndex)
    tabs = [
        {
            id: 'subscription-form',
            content: 'Subscription Form - Restock Alert', //'Subscription Form',
            accessibilityLabel: 'Customize Subscription Form Display',
            panelID: 'all-customers-content',
        },
        {
            id: 'bis-template',
            content: 'Template - Restorck Alert',
            accessibilityLabel: 'Whatsapp Message',
            panelID: 'accepts-marketing-content',
        },
        {
            id: 'abandoned-checkout',
            content: 'Abandoned Checkout',
            accessibilityLabel: 'Abandoned Checkout',
            panelID: 'panelID3',
        },
        {
            id: 'order-confirmation',
            content: 'Order Confirmation',
            accessibilityLabel: 'Order Confirmation',
            panelID: 'order-confirmation',
        },
        {
            id: 'order-ship-alert',
            content: 'Order Ship Alert',
            accessibilityLabel: 'Order Ship Alert',
            panelID: 'order-ship-alert',
        },
        {
            id: 'chat-button',
            content: 'Chat Button',
            accessibilityLabel: 'Chat button',
            panelID: 'chat-button',
        },



    ];
    value1
    tabSelectionChange = (tabIndex) => {
        this.setSubNavColors();
        const mainWhatsAppMeBtnPositionOptions = [
            { key: 1, label: 'Left', value: 'left' },
            { key: 2, label: 'Right', value: 'right' },
            { key: 3, label: 'Top', value: 'top' },
            { key: 4, label: 'Bottom', value: 'bottom' },
            { key: 5, label: 'Under Add To Cart Button', value: 'under_add_to_cart_button' }
        ];
        const mainWhatsAppMeBtnWhenToDisplayOptions = [
            { key: 1, label: 'When any variant not available/soldout', value: 'when_any_variant_not_available_soldout' },
            { key: 2, label: 'Always', value: 'always' },
            { key: 3, label: 'When sold out variant selected', value: 'when_sold_out_variant_selected' }
        ];
        const chatButtonPositions = [
            { key: 2, label: 'Right Bottom', value: 'right_bottom' },
            { key: 1, label: 'Left Bottom', value: 'left_bottom' },
            { key: 4, label: 'Right Middle', value: 'right_middle' },
            { key: 3, label: 'Left Middle', value: 'left_middle' }
        ];

        const mainWhatsAppMeBtnPositionSelectionChange = (val) => {
            let x = this.state.customizeSettings
            x.mainWhatsAppMeBtnPosition = val
            this.setState({ customizeSettings: x });
            this.somethingChanged();
        }
        const mainWhatsAppMeBtnWhenToDisplaySelectionChange = (val) => {
            let x = this.state.customizeSettings
            x.mainWhatsAppMeBtnWhenToDisplay = val
            this.setState({ customizeSettings: x });
            this.somethingChanged();
        }

        const chatButtonPositionSelectionChange = (val) => {
            let x = this.state.customizeSettings
            x.chatButtonPosition = val
            this.setState({ customizeSettings: x });
            this.somethingChanged();
        }

        this.setToolTip("cancelBtn", "Cancel current changes");
        this.setToolTip("resetBtn", "Factory reset all customizations. All colors, texts, selections & positions across all tabs will go back to default.")

        if (tabIndex === 0) {
            // {this.state.showToast && <Toast content={this.toastMessage} onDismiss={() => { console.log('toast dismissed'); }} />}
            return (
                <Page primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }} narrowWidth={false} fullWidth={true}>

                    {this.state.showYesOrNo && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to reset?" secondaryMessageToDisplay="All settings under this Subscription Form tab will be replaced with default values" />}
                    <div id="content">
                        <LegacyCard title="Main WhatsApp me button" sectioned >


                            <Select
                                label="Choose when to display in product pages"
                                options={mainWhatsAppMeBtnWhenToDisplayOptions}
                                onChange={mainWhatsAppMeBtnWhenToDisplaySelectionChange}
                                value={this.state.customizeSettings.mainWhatsAppMeBtnWhenToDisplay}
                            />


                            <TextField id="mainWhatsAppMeBtnText"
                                label="Text"
                                value={this.state.customizeSettings.mainWhatsAppMeBtnText}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />
                            <Select
                                label="Position"
                                options={mainWhatsAppMeBtnPositionOptions}
                                onChange={mainWhatsAppMeBtnPositionSelectionChange}
                                value={this.state.customizeSettings.mainWhatsAppMeBtnPosition}
                            />


                            <div><label className="cust-page-sub-heading" >Colors:</label>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Text Color </label>
                                        <VatsColorPicker id="mainWhatsAppMeBtnColor"
                                            initialColor={this.state.customizeSettings.mainWhatsAppMeBtnColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>Background Color </label>
                                        {<VatsColorPicker id="mainWhatsAppMeBtnBgColor"
                                            initialColor={this.state.customizeSettings.mainWhatsAppMeBtnBgColor}
                                            onColorChange={this.handleColorChange} />}
                                    </div>
                                </div>
                                {/*<div className="color-preview-box" style={{ color: this.state.customizeSettings.mainWhatsAppMeBtnColor}}></div>*/}
                            </div>
                        </LegacyCard>

                        <LegacyCard title="Popup form" sectioned>
                            <TextField id="formFormHeading"
                                label="Title "
                                value={this.state.customizeSettings.formFormHeading}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />
                            {/*  <RangeSlider id="formFormHeadingTextSize" label="Title font size" min={0} value={{}} onChange={handleRangeSliderChange}
                        prefix={<p>Hue</p>} suffix={<p style={suffixStyles}>{rangeValue}</p>} max={15000} />*/}
                            <TextField id="formFormHeadingTextSize"
                                label="Title font size in px "
                                type="number"
                                value={this.state.customizeSettings.formFormHeadingTextSize}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />

                            <TextField id="formWhatsAppNumberTextboxPlaceholderText"
                                label="WhatsApp number textbox placeholder text"
                                value={this.state.customizeSettings.formWhatsAppNumberTextboxPlaceholderText}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />

                            <TextField id="formWhatsAppNumberTextboxPlaceholderTextForError"
                                label="WhatsApp number textbox placeholder text for empty/error input"
                                value={this.state.customizeSettings.formWhatsAppNumberTextboxPlaceholderTextForError}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />

                            {


                            }
                            <div><label>Colors:</label>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Title Text Color </label>
                                        <VatsColorPicker id="formFormHeadingTextColor"
                                            initialColor={this.state.customizeSettings.formFormHeadingTextColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>Form Background Color </label>
                                        <VatsColorPicker id="formBgColor"
                                            initialColor={this.state.customizeSettings.formBgColor}
                                            onColorChange={this.handleColorChange} />


                                    </div>
                                </div>

                            </div>

                            <div>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Dropdown background </label>
                                        <VatsColorPicker id="formSelectBoxBgColor"
                                            initialColor={this.state.customizeSettings.formSelectBoxBgColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>WhatsApp number textbox text color </label>
                                        <VatsColorPicker id="formWhatsAppNumberTextboxColor"
                                            initialColor={this.state.customizeSettings.formWhatsAppNumberTextboxColor}
                                            onColorChange={this.handleColorChange} />


                                    </div>
                                    <div className="color-box-column" >
                                        <label>WhatsApp number textbox background</label>
                                        <VatsColorPicker id="formWhatsAppNumberTextboxBgColor"
                                            initialColor={this.state.customizeSettings.formWhatsAppNumberTextboxBgColor}
                                            onColorChange={this.handleColorChange} />


                                    </div>
                                </div>

                            </div>

                        </LegacyCard>

                        <LegacyCard title="WhatsApp me button at the form" sectioned>

                            <TextField autoComplete='off' id="whatsAppMeBtnText"
                                label="Text"
                                value={this.state.customizeSettings.whatsAppMeBtnText}
                                onChange={this.handleTextFieldChange}
                            />


                            <div><label>Colors:</label>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Text Color </label>
                                        <VatsColorPicker id="whatsAppMeBtnColor"
                                            initialColor={this.state.customizeSettings.whatsAppMeBtnColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>Background Color </label>
                                        {<VatsColorPicker id="whatsAppMeBtnBgColor"
                                            initialColor={this.state.customizeSettings.whatsAppMeBtnBgColor}
                                            onColorChange={this.handleColorChange} />}


                                    </div>
                                </div>

                            </div>
                        </LegacyCard>
                        <LegacyCard title="Confirmation message at the form" sectioned>
                            {/*#to add privde text size changeability*/}
                            <TextField autoComplete='off' id="confirmationMessageText"
                                label="Text"
                                value={this.state.customizeSettings.confirmationMessageText}
                                onChange={this.handleTextFieldChange}
                            />


                            <div><label>Colors:</label>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Text Color </label>
                                        <VatsColorPicker id="confirmationMessageColor"
                                            initialColor={this.state.customizeSettings.confirmationMessageColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>

                                </div>

                            </div>
                        </LegacyCard>
                        <PageActions primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }}
                            secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabled, onAction: this.cancelChanges },
                                // { id: "resetBtn", content: 'Reset', disabled: this.state.saveBtnDisabled, onAction: this.resetCustomizeSettings } //enable this when and if going to factory reset cust. settings
                            ]} />
                    </div>
                </Page>


            );

        }
        // else if (tabIndex === 1) {
        //     // {this.state.showToast && <Toast content={this.toastMessage} onDismiss={() => { console.log('toast dismissed'); }} />}
        //     return (
        //         <Page primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }} narrowWidth={false} fullWidth={true}>
        //             {this.state.showYesOrNo && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to reset?" secondaryMessageToDisplay="All settings under this Subscription Form tab will be replaced with default values" />}
        //             <LegacyCard title="Main WhatsApp me button" sectioned >


        //                 <Select
        //                     label="Choose when to display in product pages"
        //                     options={mainWhatsAppMeBtnWhenToDisplayOptions}
        //                     onChange={mainWhatsAppMeBtnWhenToDisplaySelectionChange}
        //                     value={this.state.customizeSettings.mainWhatsAppMeBtnWhenToDisplay}
        //                 />


        //                 <TextField id="mainWhatsAppMeBtnText"
        //                     label="Text"
        //                     value={this.state.customizeSettings.mainWhatsAppMeBtnText}
        //                     onChange={this.handleTextFieldChange}
        //                     autoComplete='off'
        //                 />
        //                 <Select
        //                     label="Position"
        //                     options={mainWhatsAppMeBtnPositionOptions}
        //                     onChange={mainWhatsAppMeBtnPositionSelectionChange}
        //                     value={this.state.customizeSettings.mainWhatsAppMeBtnPosition}
        //                 />


        //                 <div><label className="cust-page-sub-heading" >Colors:</label>

        //                     <div className='row' >
        //                         <div className="color-box-column">
        //                             <label>Text Color </label>
        //                             <VatsColorPicker id="mainWhatsAppMeBtnColor"
        //                                 initialColor={this.state.customizeSettings.mainWhatsAppMeBtnColor}
        //                                 onColorChange={this.handleColorChange} />
        //                         </div>
        //                         <div className="color-box-column" >
        //                             <label>Background Color </label>
        //                             {<VatsColorPicker id="mainWhatsAppMeBtnBgColor"
        //                                 initialColor={this.state.customizeSettings.mainWhatsAppMeBtnBgColor}
        //                                 onColorChange={this.handleColorChange} />}
        //                         </div>
        //                     </div>
        //                     {/*<div className="color-preview-box" style={{ color: this.state.customizeSettings.mainWhatsAppMeBtnColor}}></div>*/}
        //                 </div>
        //             </LegacyCard>
        //             <LegacyCard title="Popup form" sectioned>
        //                 <TextField id="formFormHeading"
        //                     label="Title "
        //                     value={this.state.customizeSettings.formFormHeading}
        //                     onChange={this.handleTextFieldChange}
        //                     autoComplete='off'
        //                 />
        //                 {/*  <RangeSlider id="formFormHeadingTextSize" label="Title font size" min={0} value={{}} onChange={handleRangeSliderChange}
        //                 prefix={<p>Hue</p>} suffix={<p style={suffixStyles}>{rangeValue}</p>} max={15000} />*/}
        //                 <TextField id="formFormHeadingTextSize"
        //                     label="Title font size in px "
        //                     type="number"
        //                     value={this.state.customizeSettings.formFormHeadingTextSize}
        //                     onChange={this.handleTextFieldChange}
        //                     autoComplete='off'
        //                 />

        //                 <TextField id="formWhatsAppNumberTextboxPlaceholderText"
        //                     label="WhatsApp number textbox placeholder text"
        //                     value={this.state.customizeSettings.formWhatsAppNumberTextboxPlaceholderText}
        //                     onChange={this.handleTextFieldChange}
        //                     autoComplete='off'
        //                 />

        //                 <TextField id="formWhatsAppNumberTextboxPlaceholderTextForError"
        //                     label="WhatsApp number textbox placeholder text for empty/error input"
        //                     value={this.state.customizeSettings.formWhatsAppNumberTextboxPlaceholderTextForError}
        //                     onChange={this.handleTextFieldChange}
        //                     autoComplete='off'
        //                 />

        //                 {


        //                 }
        //                 <div><label>Colors:</label>

        //                     <div className='row' >
        //                         <div className="color-box-column">
        //                             <label>Title Text Color </label>
        //                             <VatsColorPicker id="formFormHeadingTextColor"
        //                                 initialColor={this.state.customizeSettings.formFormHeadingTextColor}
        //                                 onColorChange={this.handleColorChange} />
        //                         </div>
        //                         <div className="color-box-column" >
        //                             <label>Form Background Color </label>
        //                             <VatsColorPicker id="formBgColor"
        //                                 initialColor={this.state.customizeSettings.formBgColor}
        //                                 onColorChange={this.handleColorChange} />


        //                         </div>
        //                     </div>

        //                 </div>

        //                 <div>

        //                     <div className='row' >
        //                         <div className="color-box-column">
        //                             <label>Dropdown background </label>
        //                             <VatsColorPicker id="formSelectBoxBgColor"
        //                                 initialColor={this.state.customizeSettings.formSelectBoxBgColor}
        //                                 onColorChange={this.handleColorChange} />
        //                         </div>
        //                         <div className="color-box-column" >
        //                             <label>WhatsApp number textbox text color </label>
        //                             <VatsColorPicker id="formWhatsAppNumberTextboxColor"
        //                                 initialColor={this.state.customizeSettings.formWhatsAppNumberTextboxColor}
        //                                 onColorChange={this.handleColorChange} />


        //                         </div>
        //                         <div className="color-box-column" >
        //                             <label>WhatsApp number textbox background</label>
        //                             <VatsColorPicker id="formWhatsAppNumberTextboxBgColor"
        //                                 initialColor={this.state.customizeSettings.formWhatsAppNumberTextboxBgColor}
        //                                 onColorChange={this.handleColorChange} />


        //                         </div>
        //                     </div>

        //                 </div>

        //             </LegacyCard>

        //             <LegacyCard title="WhatsApp me button at the form" sectioned>

        //                 <TextField autoComplete='off' id="whatsAppMeBtnText"
        //                     label="Text"
        //                     value={this.state.customizeSettings.whatsAppMeBtnText}
        //                     onChange={this.handleTextFieldChange}
        //                 />


        //                 <div><label>Colors:</label>

        //                     <div className='row' >
        //                         <div className="color-box-column">
        //                             <label>Text Color </label>
        //                             <VatsColorPicker id="whatsAppMeBtnColor"
        //                                 initialColor={this.state.customizeSettings.whatsAppMeBtnColor}
        //                                 onColorChange={this.handleColorChange} />
        //                         </div>
        //                         <div className="color-box-column" >
        //                             <label>Background Color </label>
        //                             {<VatsColorPicker id="whatsAppMeBtnBgColor"
        //                                 initialColor={this.state.customizeSettings.whatsAppMeBtnBgColor}
        //                                 onColorChange={this.handleColorChange} />}


        //                         </div>
        //                     </div>

        //                 </div>
        //             </LegacyCard>
        //             <LegacyCard title="Confirmation message at the form" sectioned>
        //                 {/*#to add privde text size changeability*/}
        //                 <TextField autoComplete='off' id="confirmationMessageText"
        //                     label="Text"
        //                     value={this.state.customizeSettings.confirmationMessageText}
        //                     onChange={this.handleTextFieldChange}
        //                 />


        //                 <div><label>Colors:</label>

        //                     <div className='row' >
        //                         <div className="color-box-column">
        //                             <label>Text Color </label>
        //                             <VatsColorPicker id="confirmationMessageColor"
        //                                 initialColor={this.state.customizeSettings.confirmationMessageColor}
        //                                 onColorChange={this.handleColorChange} />
        //                         </div>

        //                     </div>

        //                 </div>
        //             </LegacyCard>
        //             <PageActions primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }}
        //                 secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabled, onAction: this.cancelChanges },
        //                     // { id: "resetBtn", content: 'Reset', disabled: this.state.saveBtnDisabled, onAction: this.resetCustomizeSettings } //enable this when and if going to factory reset cust. settings
        //                 ]} />
        //         </Page>

        //     );

        // }
        else if (tabIndex === 1) {

            return (
                <Page narrowWidth={false} fullWidth={true} >
                    <PageActions primaryAction={{ loading: this.state.saveBtnLoadingTemplate, content: 'Save', disabled: this.state.saveBtnDisabledTemplate, onAction: this.saveCustomizeSettingsTemplate }}

                        secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabledTemplate, onAction: this.cancelChanges },
                        ]}
                    />
                    <LegacyCard title="Whatsapp Message Template - Restock Alert" sectioned>

                        <LegacyCard.Section>
                            <OptionList
                                title="Select Template"
                                onChange={this.setSelectedTemplateOption}
                                options={this.state.templateOptions}
                                selected={this.state.selectedTemplateOption}
                            />

                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Tooltip content="You can delete only the templates created by you">
                                        <Button
                                            textAlign='right'
                                            disabled={this.state.disableDeleteSelectedTemplateButton}
                                            onClick={this.deleteSelectedTemplate}
                                            id='deleteSelectedBtn'
                                            destructive
                                        >
                                            Delete Selected Template
                                        </Button></Tooltip>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                    <LegacyCard title="Create your own template"
                        sectioned


                    >
                        <LegacyCard.Section>
                            <div>
                                <TextField autoComplete='off'
                                    label="Title"
                                    value={this.state.ownTemplateTitle}
                                    onChange={this.ownTemplateTitleChnaged}
                                />

                                <TextField autoComplete='off'
                                    label="Message Template"
                                    value={this.state.ownTemplateText}
                                    onChange={this.ownTemplateTextFieldChanged}
                                    multiline={4}
                                />



                            </div>
                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Button onClick={this.addTemplate} id="newTemplateSaveBtn" primary disabled={this.state.disableAddTemplateButton}>
                                        Add
                                    </Button>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Page>
            );
        }
        else if (tabIndex === 2) {

            return (
                <Page narrowWidth={false} fullWidth={true}>
                    <PageActions primaryAction={{ loading: this.state.saveBtnLoadingTemplate, content: 'Save', disabled: this.state.saveBtnDisabledTemplateAbandonedCart, onAction: this.saveCustomizeSettingsTemplate }}

                        secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabledTemplateAbandonedCart, onAction: this.cancelChanges },
                        ]}
                    />
                    <LegacyCard title="Whatsapp Message Template - Abandoned Checkout Alert" sectioned>

                        <LegacyCard.Section>
                            <OptionList
                                title="Select Template"
                                onChange={this.setSelectedTemplateOptionAbandonedCart}
                                options={this.state.templateoptionsAbandonedCart}
                                selected={this.state.selectedTemplateOptionAbandonedCart}
                            />


                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Tooltip content="You can delete only the templates created by you">
                                        <Button
                                            textAlign='right'
                                            disabled={this.state.disableDeleteSelectedTemplateButtonAbandonedCart}
                                            onClick={this.deleteSelectedTemplateAbandonedCart}
                                            id='deleteSelectedBtnAbandCart'
                                            destructive
                                        >
                                            Delete Selected Template
                                        </Button>
                                    </Tooltip>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>
                    </LegacyCard>


                    <LegacyCard title="Create your own template"
                        sectioned


                    >
                        <LegacyCard.Section>
                            <div>
                                <TextField autoComplete='off'
                                    label="Title"
                                    value={this.state.ownTemplateAbandCartTitle}
                                    onChange={this.ownTemplateAbandCartTitleChnaged}
                                />

                                <TextField autoComplete='off'
                                    label="Message Template"
                                    value={this.state.ownTemplateAbandCartText}
                                    onChange={this.ownTemplateAbandCartTextFieldChanged}
                                    multiline={4}
                                />



                            </div>
                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Button onClick={this.addTemplateAbandCart} id="newTemplateAbandCartSaveBtn" primary disabled={this.state.disableAddTemplateAbandCartButton}>
                                        Add
                                    </Button>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>


                    <LegacyCard title="Abandoned checkout delay settings" sectioned>

                        <TextField autoComplete='off' id="abandonedCheckoutAlertSendAfterMinutesFirst"
                            label="Send alert after "
                            type="number"
                            value={this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst}
                            onChange={this.handleTextFieldChange}
                            connectedRight={
                                <Select
                                    label=""
                                    options={[{ label: "Minutes", title: "Minutes", value: "minute" },

                                    ]}
                                    onChange={this.minutesOrHours}
                                    value={this.state.minuteOrHour}

                                />
                            }
                        />



                    </LegacyCard>
                </Page>
            );
        }
        else if (tabIndex === 3) {

            return (
                <Page narrowWidth={false} fullWidth={true} >
                    <PageActions primaryAction={{ loading: this.state.saveBtnLoadingTemplate, content: 'Save', disabled: this.state.saveBtnDisabledTemplateOrderConfirmation, onAction: this.saveCustomizeSettingsTemplate }}

                        secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabledTemplateOrderConfirmation, onAction: this.cancelChanges },
                        ]}
                    />
                    <LegacyCard title="Order Confirmation Whatsapp Message Template" sectioned>

                        <LegacyCard.Section>
                            <OptionList
                                title="Select Template"
                                onChange={this.setSelectedTemplateOptionOrderConfirmation}
                                options={this.state.templateOptionsOrderConfirmation}
                                selected={this.state.selectedTemplateOptionOrderConfirmation}
                            />

                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Tooltip content="You can delete only the templates created by you">
                                        <Button
                                            textAlign='right'
                                            disabled={this.state.disableDeleteSelectedTemplateButtonOrderConfirmation}
                                            onClick={this.deleteSelectedTemplateOrderConfirmation}
                                            id='deleteSelectedBtnOrderConfirmation'
                                            destructive
                                        >
                                            Delete Selected Template
                                        </Button></Tooltip>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                    <LegacyCard title="Create your own template"
                        sectioned


                    >
                        <LegacyCard.Section>
                            <div>
                                <TextField autoComplete='off'
                                    label="Title"
                                    value={this.state.ownTemplateTitleOrderConfirmation}
                                    onChange={this.ownTemplateTitleChnagedOrderConfirmation}
                                />

                                <TextField autoComplete='off'
                                    label="Message Template"
                                    value={this.state.ownTemplateTextOrderConfirmation}
                                    onChange={this.ownTemplateTextFieldChangedOrderConfirmation}
                                    multiline={4}
                                />



                            </div>
                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Button onClick={this.addTemplateOrderConfirmation} id="newTemplateSaveBtnOrderConfirmation" primary disabled={this.state.disableAddTemplateButtonOrderConfirmation}>
                                        Add
                                    </Button>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Page>
            );
        }
        else if (tabIndex === 4) {

            return (
                <Page narrowWidth={false} fullWidth={true} >
                    <PageActions primaryAction={{ loading: this.state.saveBtnLoadingTemplate, content: 'Save', disabled: this.state.saveBtnDisabledTemplateOrderShipAlert, onAction: this.saveCustomizeSettingsTemplate }}

                        secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabledTemplateOrderShipAlert, onAction: this.cancelChanges },
                        ]}
                    />
                    <LegacyCard title="Order Shipment Whatsapp Message Template" sectioned>

                        <LegacyCard.Section>
                            <OptionList
                                title="Select Template"
                                onChange={this.setSelectedTemplateOptionOrderShipAlert}
                                options={this.state.templateOptionsOrderShipAlert}
                                selected={this.state.selectedTemplateOptionOrderShipAlert}
                            />

                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Tooltip content="You can delete only the templates created by you">
                                        <Button
                                            textAlign='right'
                                            disabled={this.state.disableDeleteSelectedTemplateButtonOrderShipAlert}
                                            onClick={this.deleteSelectedTemplateOrderShipAlert}
                                            id='deleteSelectedBtnOrderShipAlert'
                                            destructive
                                        >
                                            Delete Selected Template
                                        </Button></Tooltip>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                    <LegacyCard title="Create your own template"
                        sectioned


                    >
                        <LegacyCard.Section>
                            <div>
                                <TextField autoComplete='off'
                                    label="Title"
                                    value={this.state.ownTemplateTitleOrderShipAlert}
                                    onChange={this.ownTemplateTitleChnagedOrderShipAlert}
                                />

                                <TextField autoComplete='off'
                                    label="Message Template"
                                    value={this.state.ownTemplateTextOrderShipAlert}
                                    onChange={this.ownTemplateTextFieldChangedOrderShipAlert}
                                    multiline={4}
                                />



                            </div>
                            <LegacyStack distribution='trailing'>
                                <ButtonGroup>
                                    <Button onClick={this.addTemplateOrderShipAlert} id="newTemplateSaveBtnOrderShipAlert" primary disabled={this.state.disableAddTemplateButtonOrderShipAlert}>
                                        Add
                                    </Button>
                                </ButtonGroup>
                            </LegacyStack>
                        </LegacyCard.Section>

                    </LegacyCard>
                </Page>
            );
        }
        else if (tabIndex === 5) {

            const textField = (
                <Autocomplete.TextField
                    id="textNearChatButtonFontFamily"
                    label="Font family"
                    value={this.state.customizeSettings.textNearChatButtonFontFamily}
                    onChange={this.handleTextFieldChange}
                    autoComplete='off'
                    placeholder="Font family"
                />
            );


            return (
                <Page primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }} narrowWidth={false} fullWidth={true}>
                    {this.state.showYesOrNo && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to reset?" secondaryMessageToDisplay="All settings under this Subscription Form tab will be replaced with default values" />}
                    <div id="content-chat-btn">
                        <LegacyCard title="Chat button" sectioned >

                            <TextField id="whatsappPhoneNumber"
                                label="WhatsApp phone number"
                                value={this.state.customizeSettings.whatsappPhoneNumber}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />


                            <TextField id="prefilledMessage"
                                label="Prefilled message"
                                value={this.state.customizeSettings.prefilledMessage}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />

                            <div style={{ height: '24px' }}></div>
                            <Select
                                label="Chat button position"
                                options={chatButtonPositions}
                                onChange={chatButtonPositionSelectionChange}
                                value={this.state.customizeSettings.chatButtonPosition}
                            />

                            <RangeSlider
                                id='buttonSize'
                                label="Button size"
                                value={this.state.customizeSettings.buttonSize}
                                onChange={this.handleRangeSliderChange}
                                suffix={this.state.customizeSettings.buttonSize + '0%'}
                                min={5}
                                max={20}
                            />

                            <RangeSlider
                                id='distanceRight'
                                label="Button distance right"
                                value={this.state.customizeSettings.distanceRight}
                                onChange={this.handleRangeSliderChange}
                                output
                                suffix={this.state.customizeSettings.distanceRight + "px"}
                                min={0}
                                max={200}
                            />

                            <RangeSlider
                                id='distanceLeft'
                                label="Button distance left"
                                value={this.state.customizeSettings.distanceLeft}
                                onChange={this.handleRangeSliderChange}
                                output
                                suffix={this.state.customizeSettings.distanceLeft + "px"}
                                min={0}
                                max={200}
                            />


                            <RangeSlider
                                id='distanceBottom'
                                label="Button distance from bottom"
                                value={this.state.customizeSettings.distanceBottom}
                                onChange={this.handleRangeSliderChange}
                                output
                                suffix={this.state.customizeSettings.distanceBottom + "px"}
                                min={0}
                                max={200}
                            />

                            <div style={{ height: '24px' }}></div>
                            <div><label className="cust-page-sub-heading" >Colors:</label>

                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Line Color </label>
                                        <VatsColorPicker id="chatButtonLineColor"
                                            initialColor={this.state.customizeSettings.chatButtonLineColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>Fill Color </label>
                                        {<VatsColorPicker id="chatButtonFillColor"
                                            initialColor={this.state.customizeSettings.chatButtonFillColor}
                                            onColorChange={this.handleColorChange} />}
                                    </div>
                                </div>
                                {/*<div className="color-preview-box" style={{ color: this.state.customizeSettings.mainWhatsAppMeBtnColor}}></div>*/}
                            </div>
                        </LegacyCard>


                        <LegacyCard title="Text near chat button" sectioned >

                            <Checkbox
                                label="Display Text near button"
                                checked={this.state.customizeSettings.textNearButtonTurnedOn}
                                onChange={this.textNearButtonTurnedOnChanged}
                            />

                            <TextField id="textNearChatButton"
                                label="Text near button"
                                value={this.state.customizeSettings.textNearChatButton}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />
                            {/*prop:textNearChatButtonFontFamily,  <fontFamily>"Work Sans","HelveticaNeue","Helvetica Neue",sans-serif</fontFamily> */}
                            <Autocomplete
                                options={options}
                                selected={this.state.selectedOptions}
                                onSelect={this.updateSelection}
                                textField={textField}
                            />

                            <div style={{ height: '24px' }}></div>
                            <div><label className="cust-page-sub-heading" >Colors:</label>
                                <div className='row' >
                                    <div className="color-box-column">
                                        <label>Text Color </label>
                                        <VatsColorPicker id="textNearChatButtonColor"
                                            initialColor={this.state.customizeSettings.textNearChatButtonColor}
                                            onColorChange={this.handleColorChange} />
                                    </div>
                                    <div className="color-box-column" >
                                        <label>Background Color </label>
                                        {<VatsColorPicker id="textNearChatButtonBgColor"
                                            initialColor={this.state.customizeSettings.textNearChatButtonBgColor}
                                            onColorChange={this.handleColorChange} />}
                                    </div>
                                </div>
                                {/*<div className="color-preview-box" style={{ color: this.state.customizeSettings.mainWhatsAppMeBtnColor}}></div>*/}
                            </div>
                        </LegacyCard>
                        <PageActions primaryAction={{ loading: this.state.saveBtnLoading, content: 'Save', disabled: this.state.saveBtnDisabled, onAction: this.saveCustomizeSettings }}
                            secondaryActions={[{ id: "cancelBtn", content: 'Cencel', disabled: this.state.saveBtnDisabled, onAction: this.cancelChanges },
                                // { id: "resetBtn", content: 'Reset', disabled: this.state.saveBtnDisabled, onAction: this.resetCustomizeSettings } //enable this when and if going to factory reset cust. settings
                            ]} />
                    </div>
                </Page>


            );

        }


    }

    reloadSimulator = () => {
        try {
            let iframe: any = document.getElementsByClassName('simulator-iframe')[0];
            iframe.src = iframe.src;

        } catch (e) {
            console.log(e);
        }
    }


    mobileScreenToggle = () => {
        try {
            let iframe: any = document.getElementsByClassName('simulator-iframe')[0];
            console.log(iframe.style.width);
            if (iframe.style.width === '395px') {
                iframe.style.width = '100%';
                document.getElementById('toggle-instruction').classList.add('t-i-desktop-screen');
            }
            else {
                iframe.style.width = '395px';
                document.getElementById('toggle-instruction').classList.remove('t-i-desktop-screen');
            }

        } catch (e) {
            console.log(e);
        }
    }

    loadSimulator = () => {
        this.setState({
            loadSimulator: true
        });
    }

    showSkeleton(tabIndex) {
        return <SkeletonPage primaryAction >
            <div >
                <Layout.Section>
                    <LegacyCard sectioned>
                        <SkeletonBodyText />
                    </LegacyCard>
                    <LegacyCard sectioned>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText />
                        </TextContainer>
                    </LegacyCard>
                    <LegacyCard sectioned>
                        <TextContainer>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText />
                        </TextContainer>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section secondary>
                    <LegacyCard>
                        <LegacyCard.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <SkeletonBodyText lines={1} />
                        </LegacyCard.Section>
                    </LegacyCard>
                    <LegacyCard subdued>
                        <LegacyCard.Section>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText lines={2} />
                            </TextContainer>
                        </LegacyCard.Section>
                        <LegacyCard.Section>
                            <SkeletonBodyText lines={2} />
                        </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
            </div>
        </SkeletonPage>
    }

    render() {

        let fixedSimulator = "fixed-simulator simulator";
        if (window.innerWidth < 768) {
            fixedSimulator = "simulator";
        }

        let iframeSrc = '';
        if (window.location.origin.match('localhost')) {
            iframeSrc = 'http://localhost:3002'
        }
        //className="customize-page-row" md="6" className={fixedSimulator}
        return (
            <HorizontalGrid gap="4" columns={2} >
                {this.state.showSaveSuccessToast && <Toast content="Changes saved" duration={2000} onDismiss={() => { this.setState({ showSaveSuccessToast: false }) }} />}
                {this.state.showSaveErrorToast && <Toast error content="Something went wrong" onDismiss={() => { console.log('toast dismissed'); }} />}
                {this.state.showCanceledToast && <Toast error content="Changes cancelled across all tabs" onDismiss={() => { console.log('toast dismissed'); }} />}
                {this.state.showToast && <Toast content={this.toastMessage} duration={2000} onDismiss={() => { this.setState({ showToast: false }) }} />}
                {this.state.showErrorToast && <Toast content={this.errorToastMessage} duration={3000} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
                {this.state.showYesOrNoForActiveTemplateDelete && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoForActiveTemplateDelete} affirmativeText="Delete" negativeText="Cancel" messageToDisplay="Active template" secondaryMessageToDisplay="You are about to delete the currently used template, please choose another template and save it, otherwise, the first template will be used as the default" />}


                <div>

                    <div className="tabs-container">
                        <Tabs tabs={this.tabs} selected={this.state.selectedTabIndex} onSelect={this.handleTabChange}>
                            <LegacyCard.Section >
                                {this.state.ready ? this.tabSelectionChange(this.state.selectedTabIndex) : this.showSkeleton(this.state.selectedTabIndex)}
                            </LegacyCard.Section>
                        </Tabs>
                    </div>
                </div >
                <div className={fixedSimulator}>


                    {this.state.selectedTabIndex === 0 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2>{this.state.loadSimulator && <Button onClick={this.reloadSimulator} primary>Reload  </Button>}<span style={{ width: '20px' }}></span>{this.state.loadSimulator && <Button onClick={this.mobileScreenToggle} primary>Toggle screen</Button>}{!this.state.loadSimulator && <Button onClick={this.loadSimulator} primary>Load Simulator  </Button>}</div>

                        <div className='simulator-container'>
                            {this.state.ready && this.state.loadSimulator && <iframe style={{ width: '395px',minWidth: '395px' }} className="simulator-iframe" src={iframeSrc + "/content/products/sample-product-pagev2.html?shop=" + this.shop + '&purpose=bis'}></iframe>}
                            {this.state.ready && this.state.loadSimulator && <span id='toggle-instruction' className='toggle-instruction'>
                                <ExceptionList
                                    items={[
                                        {
                                            icon: CircleInformationMajor,
                                            description:
                                                'Use "Toggle screen" button to toggle between mobile screen and desktop screen'
                                        },
                                    ]}
                                />
                            </span>}
                        </div>

                    </LegacyCard>}
                    {this.state.selectedTabIndex === 1 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2></div>
                        {this.state.ready && <iframe className="simulator-iframe whatsapp-screen-iframe" src={iframeSrc + "/content/whatsapp-screen?shop=" + this.shop + "&template_index=" + this.state.selectedTemplateIndex}></iframe>}
                    </LegacyCard>}
                    {this.state.selectedTabIndex === 2 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2></div>
                        {this.state.ready && <iframe className="simulator-iframe whatsapp-screen-iframe" src={iframeSrc + "/content/whatsapp-screen?shop=" + this.shop + "&template_index=" + this.state.selectedTemplateIndexAbandonedCart + "&simulator_for=abandoned_cart"}></iframe>}
                    </LegacyCard>}
                    {this.state.selectedTabIndex === 3 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2></div>
                        {this.state.ready && <iframe className="simulator-iframe whatsapp-screen-iframe" src={iframeSrc + "/content/whatsapp-screen?shop=" + this.shop + "&template_index=" + this.state.selectedTemplateIndexOrderConfirmation + "&simulator_for=order_confirmation"}></iframe>}
                    </LegacyCard>}

                    {this.state.selectedTabIndex === 4 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2></div>
                        {this.state.ready && <iframe className="simulator-iframe whatsapp-screen-iframe" src={iframeSrc + "/content/whatsapp-screen?shop=" + this.shop + "&template_index=" + this.state.selectedTemplateIndexOrderShipAlert + "&simulator_for=order_ship_alert"}></iframe>}
                    </LegacyCard>}

                    {this.state.selectedTabIndex === 5 && <LegacyCard>
                        <div className="simulator-toolbox"><h2 className="simulator-headiing">Simulator</h2>{this.state.loadSimulator && <Button onClick={this.reloadSimulator} primary>Reload  </Button>}<span style={{ width: '20px' }}></span>{this.state.loadSimulator && <Button onClick={this.mobileScreenToggle} primary>Toggle screen</Button>}{!this.state.loadSimulator && <Button onClick={this.loadSimulator} primary>Load Simulator  </Button>}</div>
                        <div className='simulator-container'>
                            {this.state.ready && this.state.loadSimulator && <iframe style={{ width: '395px',minWidth: '395px' }} className="simulator-iframe" src={iframeSrc + "/content/products/sample-product-pagev2.html?shop=" + this.shop + '&purpose=chat_button'}></iframe>}
                            {this.state.ready && this.state.loadSimulator && <span id='toggle-instruction' className='toggle-instruction'>
                                <ExceptionList
                                    items={[
                                        {
                                            icon: CircleInformationMajor,
                                            description:
                                                'Use "Toggle screen" button to toggle between mobile screen and desktop screen'
                                        },
                                    ]}
                                />
                            </span>}
                        </div>
                    </LegacyCard>}
                </div>


            </HorizontalGrid>
        );

    }

    handleTextFieldChange = (val, id) => {

        let x = this.state.customizeSettings
        x[id] = val;
        if (x[id])
            this.setState({ customizeSettings: x });

        this.somethingChanged();
    }

    ownTemplateTitleChnaged = (val) => {
        this.setState({
            ownTemplateTitle: val
        }, function () {
            this.setState({
                disableAddTemplateButton: false
            });
        });
    }

    ownTemplateTextFieldChanged = (val) => {
        this.setState({
            ownTemplateText: val
        }, function () {
            this.setState({
                disableAddTemplateButton: false
            })
        });
    }

    ownTemplateAbandCartTitleChnaged = (val) => {
        this.setState({
            ownTemplateAbandCartTitle: val
        }, function () {
            this.setState({
                disableAddTemplateAbandCartButton: false
            });
        });
    }

    ownTemplateAbandCartTextFieldChanged = (val) => {
        this.setState({
            ownTemplateAbandCartText: val
        }, function () {
            this.setState({
                disableAddTemplateAbandCartButton: false
            })
        });
    }

    ownTemplateTitleChnagedOrderConfirmation = (val) => {
        this.setState({
            ownTemplateTitleOrderConfirmation: val
        }, function () {
            this.setState({
                disableAddTemplateButtonOrderConfirmation: false
            });
        });
    }


    ownTemplateTextFieldChangedOrderConfirmation = (val) => {
        this.setState({
            ownTemplateTextOrderConfirmation: val
        }, function () {
            this.setState({
                disableAddTemplateButtonOrderConfirmation: false
            })
        });
    }
    ownTemplateTitleChnagedOrderShipAlert = (val) => {
        this.setState({
            ownTemplateTitleOrderShipAlert: val
        }, function () {
            this.setState({
                disableAddTemplateButtonOrderShipAlert: false
            });
        });
    }


    ownTemplateTextFieldChangedOrderShipAlert = (val) => {
        this.setState({
            ownTemplateTextOrderShipAlert: val
        }, function () {
            this.setState({
                disableAddTemplateButtonOrderShipAlert: false
            })
        });
    }


    addTemplate = () => {
        const customizePageThis = this;
        // if (console.log(this.state.disableAddTemplateButton), console.log(this.state.ownTemplateText), !this.state.ownTemplateTitle || this.state.ownTemplateTitle.length < 1) alert("Please provide title for new template");
        if (!this.state.ownTemplateTitle || this.state.ownTemplateTitle.length < 1) alert("Please provide title for new template");
        else if (!this.state.ownTemplateText || this.state.ownTemplateText.length < 1) alert("Invalid template");
        else {

            customizePageThis.setState({
                disableAddTemplateButton: true
            });

            var e = environment.mainAppOriginUrl + environment.routePaths.templates,
                t = {
                    shop: this.shop,
                    value: this.state.ownTemplateTitle.trim().replace(/[^a-z^0-9^_]/gi, "_"),
                    label: this.state.ownTemplateTitle.trim(),
                    message: this.state.ownTemplateText,
                    type: environment.literals.templateTypeBackInStock
                };

            const availableAlready = this.state.templateOptions.some((it) => {
                if (it.value === t.value) {
                    return true;
                }
            });

            if (availableAlready) {
                this.toastMessage = "Template with same title exists please select different title"
                this.setState({
                    showToast: true
                });

                return;
            }

            fetch(e, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(t)
            }).then(function (e) {
                return e.json()
            }).then(function (e) {
                console.log('templateOptions spread...')
                const templateOptions = [...customizePageThis.state.templateOptions]
                templateOptions.push(t);
                customizePageThis.setState({
                    templateOptions,
                    showSaveSuccessToast: true,
                    disableAddTemplateButton: true
                });
            }).catch(function (e) {
                customizePageThis.setState({
                    showSaveErrorToast: true
                });
                console.log(e)

            })
        }
    }


    addTemplateAbandCart = () => {
        const customizePageThis = this;
        // if (console.log(this.state.disableAddTemplateButton), console.log(this.state.ownTemplateText), !this.state.ownTemplateTitle || this.state.ownTemplateTitle.length < 1) alert("Please provide title for new template");
        if (!this.state.ownTemplateAbandCartTitle || this.state.ownTemplateAbandCartTitle.length < 1) alert("Please provide title for new template");
        else if (!this.state.ownTemplateAbandCartText || this.state.ownTemplateAbandCartText.length < 1) alert("Invalid template");
        else {
            customizePageThis.setState({
                disableAddTemplateAbandCartButton: true
            });
            var e = environment.mainAppOriginUrl + environment.routePaths.templates,
                t = {
                    shop: this.shop,
                    value: this.state.ownTemplateAbandCartTitle.trim().replace(/[^a-z^0-9^_]/gi, "_"),
                    label: this.state.ownTemplateAbandCartTitle.trim(),
                    message: this.state.ownTemplateAbandCartText,
                    type: environment.literals.templateTypeAbandonedCheckout
                };

            const availableAlready = this.state.templateoptionsAbandonedCart.some((it) => {
                if (it.value === t.value) {
                    return true;
                }
            });

            if (availableAlready) {
                this.toastMessage = "Template with same title exists please select different title"
                this.setState({
                    showToast: true
                });

                return;
            }

            fetch(e, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(t)
            }).then(function (e) {
                return e.json()
            }).then(function (e) {

                var templateoptionsAbandonedCart = [...customizePageThis.state.templateoptionsAbandonedCart];
                templateoptionsAbandonedCart.push(t)
                customizePageThis.setState({
                    templateoptionsAbandonedCart,
                    showSaveSuccessToast: true,
                    disableAddTemplateAbandCartButton: true
                });
            }).catch(function (e) {
                customizePageThis.setState({
                    showSaveErrorToast: true
                });
                console.log(e)

            })
        }
    }
    addTemplateOrderConfirmation = () => {
        const customizePageThis = this;
        // if (console.log(this.state.disableAddTemplateButton), console.log(this.state.ownTemplateText), !this.state.ownTemplateTitle || this.state.ownTemplateTitle.length < 1) alert("Please provide title for new template");
        if (!this.state.ownTemplateTitleOrderConfirmation || this.state.ownTemplateTitleOrderConfirmation.length < 1) alert("Please provide title for new template");
        else if (!this.state.ownTemplateTextOrderConfirmation || this.state.ownTemplateTextOrderConfirmation.length < 1) alert("Invalid template");
        else {
            customizePageThis.setState({
                disableAddTemplateButtonOrderConfirmation: true
            });
            var e = environment.mainAppOriginUrl + environment.routePaths.templates,
                t = {
                    shop: this.shop,
                    value: this.state.ownTemplateTitleOrderConfirmation.trim().replace(/[^a-z^0-9^_]/gi, "_"),
                    label: this.state.ownTemplateTitleOrderConfirmation.trim(),
                    message: this.state.ownTemplateTextOrderConfirmation,
                    type: environment.literals.templateTypeOrderConfirmation
                };

            const availableAlready = this.state.templateOptionsOrderConfirmation.some((it) => {
                if (it.value === t.value) {
                    return true;
                }
            });

            if (availableAlready) {
                this.toastMessage = "Template with same title exists please select different title"
                this.setState({
                    showToast: true
                });

                return;
            }

            fetch(e, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(t)
            }).then(function (e) {
                return e.json()
            }).then(function (e) {

                var templateOptionsOrderConfirmation = [...customizePageThis.state.templateOptionsOrderConfirmation];
                templateOptionsOrderConfirmation.push(t)
                customizePageThis.setState({
                    templateOptionsOrderConfirmation,
                    showSaveSuccessToast: true,
                    disableAddTemplateButtonOrderConfirmation: true
                });

            }).catch(function (e) {
                customizePageThis.setState({
                    showSaveErrorToast: true
                });
                console.log(e);
            });
        }
    }

    addTemplateOrderShipAlert = () => {
        const customizePageThis = this;
        // if (console.log(this.state.disableAddTemplateButton), console.log(this.state.ownTemplateText), !this.state.ownTemplateTitle || this.state.ownTemplateTitle.length < 1) alert("Please provide title for new template");
        if (!this.state.ownTemplateTitleOrderShipAlert || this.state.ownTemplateTitleOrderShipAlert.length < 1) alert("Please provide title for new template");
        else if (!this.state.ownTemplateTextOrderShipAlert || this.state.ownTemplateTextOrderShipAlert.length < 1) alert("Invalid template");
        else {

            customizePageThis.setState({
                disableAddTemplateButtonOrderShipAlert: true
            });

            var e = environment.mainAppOriginUrl + environment.routePaths.templates,
                t = {
                    shop: this.shop,
                    value: this.state.ownTemplateTitleOrderShipAlert.trim().replace(/[^a-z^0-9^_]/gi, "_"),
                    label: this.state.ownTemplateTitleOrderShipAlert.trim(),
                    message: this.state.ownTemplateTextOrderShipAlert,
                    type: environment.literals.templateTypeOrderShipAlert
                };

            const availableAlready = this.state.templateOptionsOrderShipAlert.some((it) => {
                if (it.value === t.value) {
                    return true;
                }
            });

            if (availableAlready) {
                this.toastMessage = "Template with same title exists please select different title"
                this.setState({
                    showToast: true
                });

                return;
            }

            fetch(e, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(t)
            }).then(function (e) {
                return e.json()
            }).then(function (e) {

                var templateOptionsOrderShipAlert = [...customizePageThis.state.templateOptionsOrderShipAlert];
                templateOptionsOrderShipAlert.push(t)
                customizePageThis.setState({
                    templateOptionsOrderShipAlert,
                    showSaveSuccessToast: true,
                    disableAddTemplateButtonOrderShipAlert: true
                });

            }).catch(function (e) {
                customizePageThis.setState({
                    showSaveErrorToast: true
                });
                console.log(e)

            })
        }
    }


    handleColorChange = (val, id) => {

        let x = this.state.customizeSettings
        x[id] = val.hex
        if (x[id])
            this.setState({ customizeSettings: x });
        this.somethingChanged();

    }

    somethingChanged = () => {

        this.setState({ saveBtnDisabled: false, loadSimulator: true });
        this.setState({ saveBtnDisabledTemplate: false });
        this.setState({ saveBtnDisabledTemplateAbandonedCart: false });
        this.setState({ saveBtnDisabledTemplateOrderConfirmation: false });
        this.setState({ saveBtnDisabledTemplateOrderShipAlert: false });

        let iframe: any = document.getElementsByClassName('simulator-iframe')[0];
        if (iframe)
            iframe.contentWindow.postMessage({ customizeSettings: this.state.customizeSettings }, '*');

    }

    saveCustomizeSettings = () => {

        if (isNaN(this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst) || this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst < 4) {
            alert("Send alert after minutes should be more than 4 minutes");
            return;
        }
        if (this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst > 10080) {
            alert("Send alert after should be less than 7 days ");
            return;
        }

        this.setState({ saveBtnLoading: true });
        let method = 'POST';
        let url_ = environment.mainAppOriginUrl + environment.routePaths.customizeSettings;
        if (!this.state.customizeSettingsIsDefault) {
            method = 'PUT';
            url_ += '/' + this.state.customizeSettings.id
        }

        fetch(url_, {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.customizeSettings)
        }).then((res) => {
            console.log(res);
            return res.json();
        }).then((d) => {
            console.log(d);
            let customizeSettings = this.state.customizeSettings;
            customizeSettings.id = d.id;
            this.setState({
                saveBtnDisabled: true,
                showSaveSuccessToast: true,
                customizeSettings: customizeSettings,
                customizeSettingsIsDefault: false
            });
        }).catch((er) => {
            console.log(er);
            this.setState({ saveBtnDisabled: false, showSaveErrorToast: true });
        }).finally(() => {
            this.setState({ saveBtnLoading: false });
        });
    }

    saveCustomizeSettingsTemplate = () => {

        if (isNaN(this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst) || this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst < 4) {
            alert("Send alert after minutes should be more than 4 minutes");
            return;
        }
        if (this.state.customizeSettings.abandonedCheckoutAlertSendAfterMinutesFirst > 10080) {
            alert("Send alert after should be less than 7 days ");
            return;
        }


        this.setState({ saveBtnLoadingTemplate: true });
        this.setState({ saveBtnLoadingTemplateAbandonedCart: true });
        this.setState({ saveBtnLoadingTemplateOrderConfirmation: true });
        this.setState({ saveBtnLoadingTemplateOrderShipAlert: true });
        let method = 'POST';
        let url_ = environment.mainAppOriginUrl + environment.routePaths.customizeSettings;
        if (!this.state.customizeSettingsIsDefault) {
            method = 'PUT';
            url_ += '/' + this.state.customizeSettings.id
        }
        let idTemp = this.state.customizeSettings.id;

        fetch(url_, {
            method: method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.customizeSettings)
        }).then((res) => {
            console.log(res);
            return res.json();
        }).then((d) => {

            let customizeSettings = this.state.customizeSettings;
            if (!customizeSettings.id && idTemp)
                customizeSettings.id = idTemp;
            else if (!customizeSettings.id && d.id)
                customizeSettings.id = d.id;

            this.setState({
                saveBtnDisabledTemplate: true,
                saveBtnDisabledTemplateAbandonedCart: true,
                saveBtnDisabledTemplateOrderConfirmation: true,
                saveBtnDisabledTemplateOrderShipAlert: true,
                showSaveSuccessToast: true,
                customizeSettings: customizeSettings,
                customizeSettingsIsDefault: false
            });
        }).catch((er) => {
            console.log(er);
            this.setState({ saveBtnDisabledTemplate: false, saveBtnDisabledTemplateAbandonedCart: false, saveBtnDisabledTemplateOrderConfirmation: false, showSaveErrorToast: true });
        }).finally(() => {
            this.setState({ saveBtnLoadingTemplate: false });
        });
    }


    cancelChanges = () => {
        if (!this.customizeSettingsUnchangedCopy) return;
        let unchangedCustomizeSettings = JSON.parse(this.customizeSettingsUnchangedCopy);

        let selectedTemplateIndex = this.getIndexOfTemplateOptionsValue(unchangedCustomizeSettings.whatsappMessageTemplateId);

        let selectedTemplateIndexAbandonedCart = this.getIndexOfTemplateOptionsValueAbandonedCart(unchangedCustomizeSettings.abandonedCartAlertTemplateId);

        let selectedTemplateIndexOrderConfirmation = this.getIndexOfTemplateOptionsValueOrderConfirmation(unchangedCustomizeSettings.templateIdOrderConfirmation);

        let selectedTemplateIndexOrderShipAlert = this.getIndexOfTemplateOptionsValueOrderShipAlert(unchangedCustomizeSettings.templateIdOrderShipAlert);

        this.setState({
            customizeSettings: JSON.parse(this.customizeSettingsUnchangedCopy),
            saveBtnDisabled: true,
            saveBtnDisabledTemplate: true,
            selectedTemplateOption: [unchangedCustomizeSettings.whatsappMessageTemplateId],
            selectedTemplateIndex: selectedTemplateIndex,
            saveBtnDisabledTemplateAbandonedCart: true,
            saveBtnDisabledTemplateOrderConfirmation: true,
            selectedTemplateOptionAbandonedCart: [unchangedCustomizeSettings.abandonedCartAlertTemplateId],
            selectedTemplateOptionOrderConfirmation: [unchangedCustomizeSettings.templateIdOrderConfirmation],
            selectedTemplateIndexAbandonedCart,
            selectedTemplateIndexOrderConfirmation,
            saveBtnDisabledTemplateOrderShipAlert: true,
            selectedTemplateOptionOrderShipAlert: [unchangedCustomizeSettings.templateIdOrderShipAlert],
            selectedTemplateIndexOrderShipAlert
        });

    }

    //<message template stuffs>

    setSelectedTemplateOption = (selected) => {

        let message = this.state.templateMessagePreview;
        let selectedTemplateIndex = this.getIndexOfTemplateOptionsValue(selected[0]);
        message = [this.state.templateOptions[selectedTemplateIndex].message];

        let x = this.state.customizeSettings
        x.whatsappMessageTemplateId = selected[0];


        let disableDeleteSelectedTemplateButton = true;
        this.state.templateOptions.some((it) => {
            if (it.shop && this.state.templateOptions[selectedTemplateIndex]._id === it._id) {
                disableDeleteSelectedTemplateButton = false;
                return true;
            }
        });

        this.setState({
            selectedTemplateOption: selected,
            templateMessagePreview: message,
            customizeSettings: x,
            selectedTemplateIndex: selectedTemplateIndex,
            disableDeleteSelectedTemplateButton
        });
        console.log(this.state.customizeSettings.whatsappMessageTemplateId);

        this.somethingChanged();

    }

    setSelectedTemplateOptionAbandonedCart = (selected) => { //#to change check if this kind of methods can be one

        let message = this.state.templateMessagePreviewAbandonedAlert //.templateMessagePreview;
        let selectedTemplateIndexAbandonedCart = this.getIndexOfTemplateOptionsValueAbandonedCart(selected[0]);
        message = [this.state.templateoptionsAbandonedCart[selectedTemplateIndexAbandonedCart].message];

        let x = this.state.customizeSettings
        x.abandonedCartAlertTemplateId = selected[0];

        let disableDeleteSelectedTemplateButtonAbandonedCart = true;
        this.state.templateoptionsAbandonedCart.some((it) => {
            if (it.shop && this.state.templateoptionsAbandonedCart[selectedTemplateIndexAbandonedCart]._id === it._id) {
                disableDeleteSelectedTemplateButtonAbandonedCart = false;
                return true;
            }
        });

        this.setState({
            selectedTemplateOptionAbandonedCart: selected,
            templateMessagePreviewAbandonedCart: message,
            customizeSettings: x,
            selectedTemplateIndexAbandonedCart: selectedTemplateIndexAbandonedCart,
            disableDeleteSelectedTemplateButtonAbandonedCart
        });

        this.somethingChanged();
    }

    setSelectedTemplateOptionOrderConfirmation = (selected) => {

        let message = this.state.templateMessagePreviewOrderConfirmation //.templateMessagePreview;
        let selectedTemplateIndexOrderConfirmation = this.getIndexOfTemplateOptionsValueOrderConfirmation(selected[0]);
        message = [this.state.templateOptionsOrderConfirmation[selectedTemplateIndexOrderConfirmation].message];

        let x = this.state.customizeSettings
        x.templateIdOrderConfirmation = selected[0];

        let disableDeleteSelectedTemplateButtonOrderConfirmation = true;
        this.state.templateOptionsOrderConfirmation.some((it) => {
            if (it.shop && this.state.templateOptionsOrderConfirmation[selectedTemplateIndexOrderConfirmation]._id === it._id) {
                disableDeleteSelectedTemplateButtonOrderConfirmation = false;
                return true;
            }
        });

        this.setState({
            selectedTemplateOptionOrderConfirmation: selected,
            templateMessagePreviewOrderConfirmation: message,
            customizeSettings: x,
            selectedTemplateIndexOrderConfirmation: selectedTemplateIndexOrderConfirmation,
            disableDeleteSelectedTemplateButtonOrderConfirmation
        });

        this.somethingChanged();
    }

    setSelectedTemplateOptionOrderShipAlert = (selected) => {

        let message = this.state.templateMessagePreviewOrderShipAlert //.templateMessagePreview;
        let selectedTemplateIndexOrderShipAlert = this.getIndexOfTemplateOptionsValueOrderShipAlert(selected[0]);
        message = [this.state.templateOptionsOrderShipAlert[selectedTemplateIndexOrderShipAlert].message];

        let x = this.state.customizeSettings
        x.templateIdOrderShipAlert = selected[0];

        let disableDeleteSelectedTemplateButtonOrderShipAlert = true;
        this.state.templateOptionsOrderShipAlert.some((it) => {
            if (it.shop && this.state.templateOptionsOrderShipAlert[selectedTemplateIndexOrderShipAlert]._id === it._id) {
                disableDeleteSelectedTemplateButtonOrderShipAlert = false;
                return true;
            }
        });

        this.setState({
            selectedTemplateOptionOrderShipAlert: selected,
            templateMessagePreviewOrderShipAlert: message,
            customizeSettings: x,
            selectedTemplateIndexOrderShipAlert: selectedTemplateIndexOrderShipAlert,
            disableDeleteSelectedTemplateButtonOrderShipAlert
        });

        this.somethingChanged();
    }

    handleYesOrNoResult = (result) => {
        this.setState({
            showYesOrNo: false
        });

        if (result === 'yes') {

            let url_ = environment.mainAppOriginUrl + environment.routePaths.customizeSettings + '/default';
            this.setState({ saveBtnDisabled: true });
            fetch(url_, {
                method: 'GET',
                headers: { "Content-Type": "application/json" }
            }).then((res) => {
                console.log(res);
                return res.json();
            }).then((d) => {
                console.log(d);
                let customizeSettings = this.state.customizeSettings;
                console.log(customizeSettings.id);
                console.log(this.state.customizeSettingsId);
                //let whatsappMessageTemplateId = customizeSettings.whatsappMessageTemplateId;
                d.whatsappMessageTemplateId = customizeSettings.whatsappMessageTemplateId;
                console.log(d.whatsappMessageTemplateId);
                this.setState({
                    customizeSettings: d
                });

                this.saveCustomizeSettings();
            }).catch((er) => {
                console.log(er);
            }).finally(() => {
                this.setState({ saveBtnLoading: false });
            });

        }
    }

    getIndexOfTemplateOptionsValue = (value) => {
        let selectedTemplateIndex = -1;
        this.state.templateOptions.some((it, i) => {
            if (it.value === value) {
                selectedTemplateIndex = i;
                return true;
            }
        });

        return selectedTemplateIndex;

    }
    getIndexOfTemplateOptionsValueAbandonedCart = (value) => {
        let selectedTemplateIndex = -1;
        this.state.templateoptionsAbandonedCart.some((it, i) => {
            if (it.value === value) {
                selectedTemplateIndex = i;
                return true;
            }
        });

        return selectedTemplateIndex;

    }

    getIndexOfTemplateOptionsValueOrderConfirmation = (value) => {
        let selectedTemplateIndex = -1;
        this.state.templateOptionsOrderConfirmation.some((it, i) => {
            if (it.value === value) {
                selectedTemplateIndex = i;
                return true;
            }
        });

        return selectedTemplateIndex;

    }

    getIndexOfTemplateOptionsValueOrderShipAlert = (value) => {
        let selectedTemplateIndex = -1;
        this.state.templateOptionsOrderShipAlert.some((it, i) => {
            if (it.value === value) {
                selectedTemplateIndex = i;
                return true;
            }
        });

        return selectedTemplateIndex;

    }

    setToolTip = (id, msg) => {
        setTimeout(() => {
            //console.log(msg)
            let el = document.getElementById(id);
            if (el)
                el.setAttribute("title", msg);
        }, 300);
    }

    minutesOrHours = (v) => {
        console.log(v);
        this.setState({
            minuteOrHour: v.toLowerCase()
        });
    }

    deletionType = null;
    activeTemplateDeletConfirmed = false;
    deleteSelectedTemplate = async () => {
        let templateToDelete = this.state.templateOptions[this.state.selectedTemplateIndex]
        if (templateToDelete.shop) {

            if (this.customizeSettingsUnchangedCopy) {
                let unchangedCustomizeSettings = JSON.parse(this.customizeSettingsUnchangedCopy);
                if (unchangedCustomizeSettings.whatsappMessageTemplateId == templateToDelete.value && !this.activeTemplateDeletConfirmed) {
                    this.deletionType = 'bisTemplate';
                    this.setState({ showYesOrNoForActiveTemplateDelete: true });
                    return;
                }
            }

            this.activeTemplateDeletConfirmed = false;

            const resp = await fetch(environment.mainAppOriginUrl + '/api/Templates/' + templateToDelete.id, {
                method: 'DELETE'
            });
            if (resp.ok) {
                const templateOptions = this.state.templateOptions;
                templateOptions.splice(this.state.selectedTemplateIndex, 1);
                this.setState({
                    templateOptions,
                    showSaveSuccessToast: true
                });
            }
        }
        else {
            this.errorToastMessage = 'You can delete the ones you create, not the default templates'
            this.setState({ showErrorToast: true });
        }
    }
    handleYesOrNoForActiveTemplateDelete = (result) => {

        this.setState({
            showYesOrNo: false
        });

        if (result === 'yes') {
            if (this.deletionType === 'bisTemplate') {
                this.activeTemplateDeletConfirmed = true;
                this.deleteSelectedTemplate();
            }
            else if (this.deletionType === 'abcTemplate') {
                this.activeTemplateAbandonedCartDeletConfirmed = true;
                this.deleteSelectedTemplateAbandonedCart();
            }
        }
    }

    activeTemplateAbandonedCartDeletConfirmed = false;
    deleteSelectedTemplateAbandonedCart = async () => {

        let templateToDelete = this.state.templateoptionsAbandonedCart[this.state.selectedTemplateIndexAbandonedCart];

        if (templateToDelete.shop) {

            if (this.customizeSettingsUnchangedCopy) {
                let unchangedCustomizeSettings = JSON.parse(this.customizeSettingsUnchangedCopy);
                if (unchangedCustomizeSettings.abandonedCartAlertTemplateId == templateToDelete.value && !this.activeTemplateAbandonedCartDeletConfirmed) {
                    this.deletionType = 'abcTemplate';
                    this.setState({ showYesOrNoForActiveTemplateDelete: true });
                    return;
                }
            }

            this.activeTemplateAbandonedCartDeletConfirmed = false;

            const resp = await fetch(environment.mainAppOriginUrl + '/api/Templates/' + templateToDelete.id, {
                method: 'DELETE'
            });
            if (resp.ok) {
                const templateOptionsAbandonedCart = this.state.templateoptionsAbandonedCart;
                templateOptionsAbandonedCart.splice(this.state.selectedTemplateIndexAbandonedCart, 1);
                this.setState({
                    templateOptionsAbandonedCart,
                    showSaveSuccessToast: true
                });
            }
        }
        else {
            this.errorToastMessage = 'You can delete the ones you create, not the default templates'
            this.setState({ showErrorToast: true });
        }
    }
    activeTemplateDeletConfirmedOrderConfirmation = false;
    deleteSelectedTemplateOrderConfirmation = async () => {

        let templateToDelete = this.state.templateOptionsOrderConfirmation[this.state.selectedTemplateIndexOrderConfirmation];

        if (templateToDelete.shop) {

            if (this.customizeSettingsUnchangedCopy) {
                let unchangedCustomizeSettings = JSON.parse(this.customizeSettingsUnchangedCopy);
                if (unchangedCustomizeSettings.OrderConfirmationAlertTemplateId == templateToDelete.value && !this.activeTemplateDeletConfirmedOrderConfirmation) {
                    this.deletionType = 'abcTemplate';
                    this.setState({ showYesOrNoForActiveTemplateDelete: true });
                    return;
                }
            }

            this.activeTemplateDeletConfirmedOrderConfirmation = false;

            const resp = await fetch(environment.mainAppOriginUrl + '/api/Templates/' + templateToDelete.id, {
                method: 'DELETE'
            });
            if (resp.ok) {
                const templateOptionsOrderConfirmation = this.state.templateOptionsOrderConfirmation;
                templateOptionsOrderConfirmation.splice(this.state.selectedTemplateIndexOrderConfirmation, 1);
                this.setState({
                    templateOptionsOrderConfirmation,
                    showSaveSuccessToast: true
                });
            }
        }
        else {
            this.errorToastMessage = 'You can delete the ones you create, not the default templates'
            this.setState({ showErrorToast: true });
        }
    }

    activeTemplateDeletConfirmedOrderShipAlert = false;
    deleteSelectedTemplateOrderShipAlert = async () => {

        let templateToDelete = this.state.templateOptionsOrderShipAlert[this.state.selectedTemplateIndexOrderShipAlert];

        if (templateToDelete.shop) {

            if (this.customizeSettingsUnchangedCopy) {
                let unchangedCustomizeSettings = JSON.parse(this.customizeSettingsUnchangedCopy);
                if (unchangedCustomizeSettings.OrderShipAlertAlertTemplateId == templateToDelete.value && !this.activeTemplateDeletConfirmedOrderShipAlert) {
                    this.deletionType = 'abcTemplate';
                    this.setState({ showYesOrNoForActiveTemplateDelete: true });
                    return;
                }
            }

            this.activeTemplateDeletConfirmedOrderShipAlert = false;

            const resp = await fetch(environment.mainAppOriginUrl + '/api/Templates/' + templateToDelete.id, {
                method: 'DELETE'
            });
            if (resp.ok) {
                const templateOptionsOrderShipAlert = this.state.templateOptionsOrderShipAlert;
                templateOptionsOrderShipAlert.splice(this.state.selectedTemplateIndexOrderShipAlert, 1);
                this.setState({
                    templateOptionsOrderShipAlert,
                    showSaveSuccessToast: true
                });
            }
        }
        else {
            this.errorToastMessage = 'You can delete the ones you create, not the default templates'
            this.setState({ showErrorToast: true });
        }
    }

    textNearButtonTurnedOnChanged = (val) => {
        let x = this.state.customizeSettings
        x.textNearButtonTurnedOn = val
        this.setState({ customizeSettings: x });
        this.somethingChanged();
    }
    handleRangeSliderChange = (val, id) => {
        let x = this.state.customizeSettings;
        x[id] = val;
        if (x[id]) {
            this.setState({ customizeSettings: x });
        }
        this.somethingChanged();
    }

    updateSelection = (selected: string[]) => {
        const selectedValue = selected.map((selectedItem) => {
            const matchedOption = options.find((option) => {
                return option.value.match(selectedItem);
            });
            return matchedOption && matchedOption.label;
        });
        const c = this.state.customizeSettings;
        c.textNearChatButtonFontFamily = selectedValue[0] || ''

        this.setState({
            selectedOptions: selected,
            customizeSettings: c
        });
        this.somethingChanged();

    }


}
