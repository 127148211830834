import * as React from 'react';
import { Link, Page, Card, DataTable, Spinner, TextField, Select, Button, Toast, Icon, Pagination } from '@shopify/polaris';
import { environment } from '../../../environment';
import { ModalWithElements } from '../../sub-components/ModalWithElements';
import { SubscriptionEdit } from '../SubscriptionEdit';
import { YesOrNoModal } from '../../sub-components/YesOrNoModal';
import {  SearchMajor, ExternalMinor } from '@shopify/polaris-icons';
import { DisplayMessageModal } from '../../sub-components/DisplayMessageModal';


export class AbandonedCheckoutAlerts extends React.Component {

    shop: any = environment.shop
    emptyValue: any=null;
    responseJson;
    state = {
        pageSize: 20,
        pageNumber: 0,
        sortedRows: [],
        ready: false,
        rows: this.emptyValue,
        totalRows: 0,
        polarisPaginationHasNext: true,
        polarisPaginationHasPrevious: true,
        showEditModel: false,
        editingObject: null,
        showYesOrNoForRemoval: false,
        showErrorToast: false,
        showChangesSavedToast: false,
        showVatsmessageModel: false,
        showingVatsmessage: null,
        filterColumn: "Product Title",
        filterCondition: "Contains",
        filterKeyword: "",
        showErrorToastForInvalidSearch: false,//to remove if not using after Feb 10
        invalidSearchErrorText: "Please provide search keyword"//to remove if not using after Feb 10

    }
    sort_dir = null;
    sort_by = null;
    async componentDidMount() {
        console.log('AbandonedCheckoutAlerts_before_waitB4Call');
        //#to add after checking why resolve not happeninign s await environment.waitB4Call(); 
        console.log('AbandonedCheckoutAlerts_after_waitB4Call');
        try {
            await this.getPageData()//({ target: { className: "NextButton"}});
        } catch (e) {
            console.log(e);
        }
    }

    abortController = new AbortController();

    whichofnextOrPrevButtonClicked = null;
    getPageData = async (e?) => {
        try {
            let hasPrev = false;
            let hasNext = true;

            //if (e && e.target) {
            //    if (e.target.className.match('PreviousButton'))
            //        this.state.pageNumber--;
            //    else
            //        this.state.pageNumber++;
            //}
            //else {
            //    this.state.pageNumber = 1;
            //    console.log('no event')
            //}

            if (this.whichofnextOrPrevButtonClicked === 'previous')
                this.state.pageNumber--;
            else if (this.whichofnextOrPrevButtonClicked === 'next')
                this.state.pageNumber++;
            else this.state.pageNumber = 1;

            if (this.state.pageNumber > 1) hasPrev = true;
            else hasPrev = false;
          
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.abandonedCartAlerts2 +
                '?shop=' + this.shop + '.myshopify.com&page_number=' + this.state.pageNumber + '&page_size=' + this.state.pageSize +
                '&filter_column=' + this.state.filterColumn + '&filter_condition=' + this.state.filterCondition
                + '&filter_keyword=' + this.state.filterKeyword + (this.sort_by ? '&sort_by=' + this.sort_by : '')
                + (this.sort_dir ? '&sort_dir=' + this.sort_dir : ''))//, { signal: this.abortController.signal });

              

            this.responseJson = await resp.json();

            let dataOptimized: any = [];

            this.responseJson.data.forEach((it) => {
             
                let abCheckout = JSON.parse(it.abandonedCheckoutJson);

                let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/checkouts/' + abCheckout.id;

                abCheckout.created_at = abCheckout.created_at || "";

                dataOptimized.push([abCheckout.created_at.substring(0, 10), <Link external url={link} key="emerald-silk-gown">#{abCheckout.id}</Link>,
                    (abCheckout.customer ? abCheckout.customer.phone : ""), <Button onClick={() => { this.viewMessage(it.id); }} plain>View Message</Button>]);
            });

            let totalRows = this.responseJson.totalCount;
            if (((this.state.pageNumber * this.state.pageSize)) >= totalRows) hasNext = false;

            setTimeout(() => {
                this.setState({
                    rows: dataOptimized,
                    polarisPaginationHasNext: hasNext,
                    polarisPaginationHasPrevious: hasPrev,
                    totalRows: totalRows,
                    pageSize: this.state.pageSize,
                    pageNumber: this.state.pageNumber
                })
            }, 50);
        } catch (e) {
            console.log(e);
            this.setState({
                rows: [],
                polarisPaginationHasNext: false,
                polarisPaginationHasPrevious: false,
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0
            });
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }


    render() {
        var handleFilterColumnChange = (val) => { this.setState({ filterColumn: val }) }
        var handleFilterConditionChange = (val) => { this.setState({ filterCondition: val }) }, handleFilterKeywordValueChange = (val) => { this.setState({ filterKeyword: val }) };

        return (
            
            this.state.rows ? <div className="subscriptions-by-product">
                <h5 className="abca-list-info">Abandoned checkout alerts sent by Vatshub are shown here, <Button plain onClick={() => { window.open('https://' + this.shop + '.myshopify.com/admin/checkouts') }}>Go to all the abandoned checkouts </Button></h5>

                {this.state.showEditModel && <ModalWithElements modalObject={this.state.editingObject} handleYesOrNoResult={this.handleCancelOrSave} elements={SubscriptionEdit} messageToDisplay="Edit Restock Alert Subscription" />}
                {this.state.showYesOrNoForRemoval && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to remove?" secondaryMessageToDisplay="This back in stock alert will be deleted and won't trigger." />}
                {this.state.showVatsmessageModel && <DisplayMessageModal handleOkClick={this.handleMessageModelClose} messageToDisplay="Message sent" secondaryMessageToDisplay={this.vatsMessageToDisplay} />}               
                {this.state.showChangesSavedToast && <Toast content="Changes saved" duration={2000} onDismiss={() => { this.setState({ showChangesSavedToast: false }) }} />}
                {this.state.showErrorToast && <Toast error content="Something went wrong" duration={2000} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
                {this.state.showErrorToastForInvalidSearch && <Toast error content={this.state.invalidSearchErrorText} duration={2000} onDismiss={() => { this.setState({ showErrorToastForInvalidSearch: false }) }} />}

                <div className="paginator-container ">
                    <Pagination
                        hasNext={this.state.polarisPaginationHasNext}
                        onNext={() => { this.whichofnextOrPrevButtonClicked = 'next'; this.setState({ rows: this.emptyValue }); this.getPageData() }}
                        hasPrevious={this.state.polarisPaginationHasPrevious}
                        onPrevious={() => { this.whichofnextOrPrevButtonClicked = 'previous'; this.setState({ rows: this.emptyValue }); this.getPageData() }}
                    />
                </div>

                <Page>
                
                    <div className="filter-conatiner">

                       
                        <div className="filtering-section">
                            <TextField
                                placeholder="Search"
                                prefix={<Icon source={SearchMajor} />}
                                labelHidden
                                label="Search"
                                value={this.state.filterKeyword}
                                onChange={handleFilterKeywordValueChange}
                                clearButton
                                onClearButtonClick={() => { this.setState({ filterKeyword: "" }, this.search); }}
                                autoComplete='off'
                            />
                        </div>
                        <div>
                            <Button primary onClick={this.search}>Search  </Button>
                        </div>


                    </div>

                    <Card>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'numeric',
                                'text',
                            ]}
                            headings={[
                                'Triggered on',
                                'Checkout',
                                'Whatsapp Number',
                                'View Message'
                            ]}
                            rows={this.state.rows}
                            sortable={[true ]}
                            defaultSortDirection="descending"
                            initialSortColumnIndex={0}
                            onSort={this.handleSort}
                            footerContent={`Showing ${(((this.state.pageNumber - 1) * this.state.pageSize) + 1) + ' to ' + (((this.state.pageNumber - 1) * this.state.pageSize) + this.state.rows.length)} of ${this.state.totalRows} results`}

                        />
                    </Card>
                </Page></div> : <div className="home-page-loading-spinner-container"><Spinner accessibilityLabel="Loading the data" size="large" /></div>



        );

    }
    handleSort = (headingIndex, direction) => {
        console.log({ headingIndex, direction });

        if (direction === 'descending') {
            this.sort_dir = 'desc'
        }
        else if (direction === 'ascending') {
            this.sort_dir = 'asc'
        }

        if (headingIndex == 0) {
            this.sort_by = 'Triggered on';
        }
        else if (headingIndex == 2) {
            this.sort_by = 'Vatshubnumber';
        }
        else return;

        this.state.pageNumber = 0;
        this.getPageData(); //sort_dir
    }

    editingId
    editClicked = (id) => {
        console.log('edit clicked');//edit things from a separate page
        this.editingId = id;

        let vatshubSubscription;
        this.responseJson.data.some((it) => {
            if (it.id == this.editingId) {
                vatshubSubscription = it;
                return;
            }
        });
        console.log(vatshubSubscription)
        this.setState({
            showEditModel: true,
            editingObject: vatshubSubscription
        });
    }


    handleCancelOrSave = async (resultingObject) => {
        let showSuccessMsg = false;
        let showErrorMsg = false;
        let editingObject;
        try {
            if (resultingObject && resultingObject.id) {
                const updateResponse = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + resultingObject.id, {
                    method: 'PUT',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(resultingObject)
                });


                if (updateResponse.ok) {
                    editingObject = resultingObject;
                    showSuccessMsg = true;
                }
            }

        } catch (e) {
            console.log(e);
            showErrorMsg = true
        }

        if (editingObject && editingObject.id) {
            this.responseJson.data.some((it, i) => {
                if (it.id === editingObject.id) {
                    let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
                    this.state.rows[i] = [it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{it.productTitle.substring(0, 30)}</Link>,
                    it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No", <div> <Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button> <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button></div>];
                    return true;
                }
            });
        }


        this.setState({
            showEditModel: false,
            showErrorToast: showErrorMsg,
            showChangesSavedToast: showSuccessMsg
        });
    }

    idToRemove = null;
    removeClick = (id) => {
        this.idToRemove = id;
        this.setState({ showYesOrNoForRemoval: true });
    }

    handleYesOrNoResult = async (val) => {
        let showChangesSavedToast = false;
        try {

            if (val === 'yes') {
                const resp = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + this.idToRemove, {
                    method: 'DELETE'
                });
                console.log(resp);
                if (resp.ok) {
                    this.responseJson.data.forEach((it, i) => {
                        if (it.id === this.idToRemove) {
                            this.state.rows.splice(i, 1);
                        }
                    })
                    showChangesSavedToast = true;
                }

            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            showYesOrNoForRemoval: false,
            showChangesSavedToast: showChangesSavedToast
        });
    }


    search = () => {
        console.log(this.state.filterColumn)
        console.log(this.state.filterCondition)
        //////if (!this.state.filterKeyword) {

        //////    this.setState({
        //////        showErrorToastForInvalidSearch: true,
        //////        invalidSearchErrorText: "Please provide search keyword"
        //////    });
        //////    return;
        //////}
        console.log(this.state.filterKeyword);
        this.getPageData();
    }

    //openMessageFromTemplateId = async () => {
    //    try {
    //        let element:any = window.event.target;
    //        //element.id

    //        //const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.abandonedCartAlerts2 +
    //        //    '?shop=' + this.shop + '.myshopify.com&page_number=' + this.state.pageNumber + '&page_size=' + this.state.pageSize +
    //        //    '&filter_column=' + this.state.filterColumn + '&filter_condition=' + this.state.filterCondition + '&filter_keyword=' + this.state.filterKeyword);

    //        //this.responseJson = await resp.json();
    //    } catch (e) {
    //        console.log(e);
    //    }
        
    //}

    viewingId
    vatsMessageToDisplay
    viewMessage = async (id) => {
        //if (this) return;
        try {//to check this loading not displayed showVatsmessageModel
            let showingVatsmessage: any = {
                body: "Loading"
            };

            this.vatsMessageToDisplay = <div className="text-align-center" ><Spinner /></div>;
            this.setState({
                // showModalSpinner: true
                //<div className="text-align-center" ><Spinner  /></div>
                showVatsmessageModel: true
            }, () => {
                console.log('done');
            });

            
            this.viewingId = id;

            let vatshubSubscription;
            this.responseJson.data.some((it) => {
                if (it.id == this.viewingId) {
                    vatshubSubscription = it;
                    return;
                }
            });

            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.apiVatsMessagesGetVatsMessageBySuubscriptionId +
                '?check=' + vatshubSubscription.id);

            console.log(resp);
            console.log("before redirect check");
            if (resp && resp.redirected === true) {
                console.log("inside redirected");
                window.location.href = "/account/login"
                return
            }
            console.log("after redirect check");
            let responseJson = await resp.json();
            console.log(responseJson);

            // let showVatsmessageModel = {body:"Not found"};
            if (!Array.isArray(responseJson) || responseJson.length < 1) {
                console.log('invalid response');
                showingVatsmessage = { body: <span>Not found</span> };
            }
            else {
                showingVatsmessage = responseJson[0];
            }
            this.vatsMessageToDisplay = showingVatsmessage.body

            // setTimeout(() => {
            this.setState({
                showingVatsmessage: showingVatsmessage,
                showVatsmessageModel: true
            });
            //  }, 2000);
        } catch (e) {
            console.log(e);
            console.log("something went wrong");
        }
    }

    handleMessageModelClose = () => {

        this.setState({
            showVatsmessageModel: false,
            showingVatsmessage: null
        }, () => {

        });

    }
}