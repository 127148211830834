import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import NotFoundPage from './pages/NotFoundPage';
import { ProfilePage } from './pages/ProfilePage';
import { CustomizePage } from './pages/CustomizePage';
import { Customers } from './pages/Customers';
import { Login } from './pages/Login';
import { AuthService } from '../commons/AuthService';
import { ChoosePlan } from './pages/ChoosePlan';
import { Portfolio } from './pages/portfolio';
import { Chat } from './pages/Chat';
import { ChatSingle } from './pages/ChatSingle';
import  CheckCode from './pages/CheckCode'
import DataLists from './pages/DataLists';
import ConnectWA from './pages/ConnectWA';
import Chart from './pages/Chart';
import FileUpload from './pages/FileUpload';

// <Route {...rest} render={(props) => (

function MyRoutes() {

    const ProtectedRoute = ({ children }) => {
        if (AuthService.isAuthed) {
            if (!AuthService.planName) {
                return <Navigate to='/choose-plan' />
            }
            return children;
        }

        else return <Navigate to='/account/login' />
    }

    return (
        <Routes >
            <Route path='/' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            <Route path='/home' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
            <Route path='/data-lists' element={<ProtectedRoute><DataLists /></ProtectedRoute>} />
            <Route path='/profile' element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path='/customize?tab=subscription-form' element={<ProtectedRoute><CustomizePage /></ProtectedRoute>} />
            <Route path='/customize' element={<ProtectedRoute><CustomizePage /></ProtectedRoute>} />
            <Route path='/customers' element={<ProtectedRoute><Customers /></ProtectedRoute>} />
            <Route path='/chat' element={<ProtectedRoute><Chat /></ProtectedRoute>} />
            <Route path='/chat-with/:vatsNumber' element={<ProtectedRoute><ChatSingle /></ProtectedRoute>} />
            <Route path='/connect-wa' element={<ProtectedRoute><ConnectWA /></ProtectedRoute>} />
            <Route path='/bulk-send' element={<ProtectedRoute><FileUpload /></ProtectedRoute>} />
            <Route path='/choose-plan' element={<ChoosePlan />} />
            <Route path='/account/login' element={<Login />} />
            <Route path='/check-code' element={<CheckCode />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/chart' element={<Chart />} />
            <Route path='/404' element={<NotFoundPage />} />
        </Routes>
    );
}


// class MyRoutes extends React.Component {



//     render() {
//         return ( 
//             <Routes >                
//                 <Route path='/' element={<DashboardPage/>} />
//                 <Route path='/home' element={<DashboardPage/>} />
//                 <Route path='/profile' element={<ProfilePage />} />
//                 <Route path='/customize' element={<CustomizePage/>} />
//                 <Route path='/customers' element={<Customers/>} />
//                 <Route path='/chat' element={<Chat/>} />                
//                 <Route path='/chat-with/:vatsNumber' element={<ChatSingle/>} />
//                 <Route path='/choose-plan' element={<ChoosePlan/>} />
//                 <Route path='/account/login' element={<Login/>} />
//                 <Route path='/portfolio' element={<Portfolio/>} />
//                 <Route path='/404' element={<NotFoundPage/>} />
//             </Routes>
//         );
//     }
// }

export default MyRoutes;
