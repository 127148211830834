import { environment } from '../environment';
import { PageInteractor } from './PageInteractor';


export class AuthService {
    static isAuthed: any = false;
    static shop: string;
    static planName: string;
    static user: string;
    static email:string;
    static charge_active:boolean;
    static async authenticate() {
        return new Promise((resolve, reject) => {

            let doingLocalTest = false;
            if(doingLocalTest)    {
                this.user = ''
                this.isAuthed = true;
                this.shop = 'sourcesexpress2'
                this.planName = 'Free'
                this.charge_active = true;
                return resolve(1);
            }

            let resolved = false;
            //let delay = 2000;
            //let d: any = window.localStorage.getItem('isAuthedUserData');
            //if (d) d = JSON.parse(d);
            //else d = {};
            //if (d.user != null) {
            //    this.user = d.user
            //    this.isAuthed = true;
            //    this.shop = d.shop;
            //    this.planName = d.planName;
            //    resolved = true;
            //    resolve(true);
            //}
            //else {
            //    delay = 100;
            //}

            //setTimeout(() => {
            let isAuthedUserData: any = window.localStorage.getItem('isAuthedUserData');
            try {
                if (isAuthedUserData) isAuthedUserData = JSON.parse(isAuthedUserData)
                else console.log('no isAuthedUserData');
            } catch (e) {
                isAuthedUserData = null;
                console.log(e)
            }
            let shopString = isAuthedUserData ? '?shop=' + isAuthedUserData.shop : "";

            let shopNameFromNewLoginUrl = PageInteractor.getQueryValueByName('storeName');
            if (typeof shopNameFromNewLoginUrl === 'string' && shopNameFromNewLoginUrl.length > 0 && typeof shopString === "string" && shopString.length > 0 && shopString !== '?shop=' + shopNameFromNewLoginUrl) {
                shopString = '?shop=' + shopNameFromNewLoginUrl;
            }
            //let shopFromUrl =  //vatshub-preview

            console.log('shopString is: ' + shopString);

            fetch(environment.mainAppOriginUrl + environment.routePaths.isAuthedUser + shopString)
                .then((d) => {
                    console.log('isAuthedUser response');
                    console.log(d);

                    return d.json();
                }).then((d) => {
                    if (d.user != null) {
                        this.user = d.user
                        this.isAuthed = true;
                        this.shop = d.shop ? d.shop.replace(/.myshopify.com/gi, '') : null;
                        this.planName = d.planName;
                        this.charge_active = d.charge_active
                        window.localStorage.setItem('isAuthedUserData', JSON.stringify(d));
                    }
                    else {
                        this.isAuthed = false;
                    }
                }).catch((er) => {
                    this.isAuthed = false;
                })
                .finally(() => {
                    console.log("this.isAuthed)" + this.isAuthed)
                    if (!resolved)
                        resolve(this.isAuthed);
                });
            //}, delay);


        });
    }
    static signout() {
        window.localStorage.removeItem('isAuthedUserData');
        this.user = "";
        this.isAuthed = false;
        this.shop = "";
        this.planName = "";
    }
}