import * as React from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';


export class ModalWithElements extends React.Component<any, any> {
   
    constructor(props: any) {
        super(props);
        console.log('ModalWithElements contsructor');
    }


    outputObject = null;
    state = {
        active: true,
        model: null,
        modelType:'subscriptionEdit'
    }
    /*
          primaryAction={{
                    content: 'Yes',
                    onAction: this.yesClicked,
                }}
                secondaryActions={[
                    {
                        content: 'No',
                        onAction: this.noClicked,
                    },
                ]}
     
     */
    render = () => {

        /*
         
         doNotShowButtons
         */

        let primaryAction:any = {
            content: 'Save',
            onAction: this.yesClicked,
        };
        let secondaryActions:any = [
            {
                content: 'Cancel',
                onAction: this.noClicked,
            },
        ];

        if (this.props.doNotShowButtons) {
            primaryAction = null;
            secondaryActions = null;
        }

        return <div className="model-we-container">

            <Modal
                open={this.state.active}
                onClose={this.closed}
                title={this.props.messageToDisplay}
                primaryAction={primaryAction}
                secondaryActions={secondaryActions}
            >
                <Modal.Section>
                    <this.props.elements modalObject={this.props.modalObject} recieveOutputObject={this.recieveOutputObject} />
                </Modal.Section>
            </Modal>
        </div>
    }

    yesClicked = () => {

        this.setState({
            active: false
        });
        if (this.props.handleYesOrNoResult)
            this.props.handleYesOrNoResult(this.outputObject);
    }

    noClicked = () => {
        this.setState({
            active: false
        });

        if (this.props.handleYesOrNoResult)
            this.props.handleYesOrNoResult("no");
    }

    closed = () => {
        console.log('close clickd')
        this.setState({
            active: false
        });

        if (this.props.handleYesOrNoResult)
            this.props.handleYesOrNoResult({something:'something'});
    }
    recieveOutputObject = (outputObject) => {
        console.log('from recieveOutputObject')
        console.log(outputObject)
        this.outputObject = outputObject;
    }
}