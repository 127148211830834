import React from 'react';
import { Page, Card, LegacyCard } from '@shopify/polaris';
import { PieChart, Pie, Cell } from 'recharts';

const data = [
  { name: 'Category A', value: 30 },
  { name: 'Category B', value: 40 },
  { name: 'Category C', value: 30 },
];

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56'];

function MyPieChart() {
  return (
    <Page title="Pie Chart Example">
      <LegacyCard sectioned>
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data}
            cx={200}
            cy={200}
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </LegacyCard>
    </Page>
  );
}

export default MyPieChart;
