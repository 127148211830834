import * as React from 'react';
import { Link, Page, Card, DataTable, Spinner, TextField, Select, Button, Toast, Icon, Pagination, Tooltip, IconProps } from '@shopify/polaris';
import { environment } from '../../environment';
import { ModalWithElements } from '../sub-components/ModalWithElements'; 
import { SubscriptionEdit } from './SubscriptionEdit';
import { YesOrNoModal } from '../sub-components/YesOrNoModal';
import { SearchMajor, CircleInformationMajor } from '@shopify/polaris-icons';
import PolarisTooltip from '../sub-components/PolarisTooltip';
import { Navigate } from 'react-router-dom';


export class Customers extends React.Component {

    shop: any = environment.shop
    emptyValue: any = null;
    responseJson;
    state = {
        pageSize: 20,
        pageNumber: 0,
        sortedRows: [],
        ready: false,
        rows: this.emptyValue,
        totalRows: 0,//to add figure out pagination
        polarisPaginationHasNext: true,
        polarisPaginationHasPrevious: true,
        showEditModel: false,
        editingObject: null,
        showYesOrNoForRemoval: false,
        showErrorToast: false,
        showChangesSavedToast: false,
        filterColumn: "Product Title",
        filterCondition: "Contains",
        filterKeyword: "",
        showErrorToastForInvalidSearch: false,//to remove if not using after Feb 10
        invalidSearchErrorText: "Please provide search keyword",//to remove if not using after Feb 10
        showErrorToastNoCustomersToDownload: false,
        phoneDirect: null,
        redirectToPhoneDirect: false

    }
    async componentDidMount() {
        try {
            document.title = "Customers-Vatshub Dashboard";
            await this.getPageData()//({ target: { className: "NextButton"}});
        } catch (e) {
            console.log(e);
        }
    }

    getPageData = async (e?) => {
        try {
            let hasPrev = false;
            let hasNext = true;

            if (e && e.target) {
                if (e.target.className.match('PreviousButton'))
                    this.state.pageNumber--;
                else
                    this.state.pageNumber++;
            }
            else {
                this.state.pageNumber = 1;
                console.log('no event')
            }

            if (this.state.pageNumber > 1) hasPrev = true;
            else hasPrev = false;
                        
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.vatshubSubscriptions2 +
                '?shop=' + this.shop + '.myshopify.com&page_number=' + this.state.pageNumber + '&page_size=' + this.state.pageSize +
                '&filter_column=' + this.state.filterColumn + '&filter_condition=' + this.state.filterCondition + '&filter_keyword=' + this.state.filterKeyword +
                '&req_type=customers_only');

            this.responseJson = await resp.json();
            console.log('  this.responseJson : ' + JSON.stringify(this.responseJson ))

            let dataOptimized: any = [];
//<Button plain onClick={() => { this.setState({ phoneDirect: it.number, redirectToPhoneDirect: true }); }}>Chat  </Button>,
            this.responseJson.data.forEach((it) => {                
                dataOptimized.push([it.number,  it.count]);
            });

            let totalRows = this.responseJson.totalCount;
            if (((this.state.pageNumber * this.state.pageSize)) >= totalRows) hasNext = false;

            setTimeout(() => {
                this.setState({
                    rows: dataOptimized,
                    polarisPaginationHasNext: hasNext,
                    polarisPaginationHasPrevious: hasPrev,
                    totalRows: totalRows,
                    pageSize: this.state.pageSize,
                    pageNumber: this.state.pageNumber
                })
            }, 50);
        } catch (e) {
            console.log(e);
            this.setState({
                rows: [],
                polarisPaginationHasNext: false,
                polarisPaginationHasPrevious: false,
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0
            });
        }
    }


    render() {
        
        var handleFilterColumnChange = (val) => { this.setState({ filterColumn: val }) }
        var handleFilterConditionChange = (val) => { this.setState({ filterCondition: val }) }, handleFilterKeywordValueChange = (val) => { this.setState({ filterKeyword: val }) };

        return (
            this.state.rows ? <div className="customers-page subscriptions-by-product">

                {this.state.showEditModel && <ModalWithElements modalObject={this.state.editingObject} handleYesOrNoResult={this.handleCancelOrSave} elements={SubscriptionEdit} messageToDisplay="Edit Restock Alert Subscription" />}
                {this.state.showYesOrNoForRemoval && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to remove?" secondaryMessageToDisplay="This back in stock alert will be deleted and won't trigger." />}
                {this.state.showChangesSavedToast && <Toast content="Changes saved" duration={2000} onDismiss={() => { this.setState({ showChangesSavedToast: false }) }} />}
                {this.state.showErrorToast && <Toast error content="Something went wrong" duration={2000} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
                {this.state.showErrorToastForInvalidSearch && <Toast error content={this.state.invalidSearchErrorText} duration={2000} onDismiss={() => { this.setState({ showErrorToastForInvalidSearch: false }) }} />}
                {this.state.showErrorToastNoCustomersToDownload && <Toast error content="No customers to download" duration={2000} onDismiss={() => { this.setState({ showErrorToastNoCustomersToDownload: false }) }} />}
                {this.state.redirectToPhoneDirect && <Navigate to={'/chat-with/' + this.state.phoneDirect} />}
                
                <div className="paginator-container ">
                    <Pagination
                        hasNext={this.state.polarisPaginationHasNext}
                        onNext={this.getPageData}
                        hasPrevious={this.state.polarisPaginationHasPrevious}
                        onPrevious={this.getPageData}
                    />
                </div>
                <Page>
                    
                    <div className="filter-conatiner">

                      
                        <div className="filtering-section">
                            <TextField
                                placeholder="Search by WA Number"
                                prefix={<Icon source={SearchMajor} />}
                                labelHidden
                                label="Search by WA Number"
                                value={this.state.filterKeyword}
                                onChange={handleFilterKeywordValueChange}
                                clearButton
                                onClearButtonClick={() => { this.setState({ filterKeyword: "" }, this.search); }}
                                autoComplete='off'
                            />
                        </div>
                        <div>
                            <Button primary onClick={this.search}>Search  </Button>
                        </div>
                        <div className="top-bar-menu-icon">
                            <PolarisTooltip active tooltipText="Download all numbers as Shopify customers in CSV format. You can use that file to import all customers into Shopify"
                                content={<div className="flex-div icon-1-container"><Button onClick={this.downloadAsCustomers}>Downlaod CSV  </Button><Icon color='highlight' source={CircleInformationMajor} /></div>} >
                            
                            </PolarisTooltip>
                        </div>

                    </div>

                    <Card>
                        <DataTable
                      
                            columnContentTypes={[
                                'numeric',
                                'numeric'
                            ]}
                            headings={[
                                'Whatsapp Number',
                                'Subscription Count'
                            ]}
                            rows={this.state.rows}
                            sortable={[false,false]}
                            defaultSortDirection="descending"
                            initialSortColumnIndex={1}
                            onSort={this.handleSort}
                            footerContent={`Showing ${(((this.state.pageNumber - 1) * this.state.pageSize) + 1) + ' to ' + (((this.state.pageNumber - 1) * this.state.pageSize) + this.state.rows.length)} of ${this.state.totalRows} results`}

                        />
                    </Card>
                </Page></div> : <div className="home-page-loading-spinner-container"><Spinner accessibilityLabel="Loading the data" size="large" /></div>
        );

    }
    handleSort = () => { }//#to add todo

    editingId
    editClicked = (id) => {
        console.log('edit clicked');//edit things from a separate page
        this.editingId = id;

        let vatshubSubscription;
        this.responseJson.data.some((it) => {
            if (it.id == this.editingId) {
                vatshubSubscription = it;
                return;
            }
        });
        console.log(vatshubSubscription)
        this.setState({
            showEditModel: true,
            editingObject: vatshubSubscription
        });
    }


    handleCancelOrSave = async (resultingObject) => {
        console.log("resultingObject: ");
        console.log(resultingObject);
        
        let showSuccessMsg = false;
        let showErrorMsg = false;
        let editingObject;
        try {
            if (resultingObject && resultingObject.id) {
                const updateResponse = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + resultingObject.id, {
                    method: 'PUT',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(resultingObject)
                });


                if (updateResponse.ok) {
                    editingObject = resultingObject;
                    showSuccessMsg = true;
                }
            }

        } catch (e) {
            console.log(e);
            showErrorMsg = true
        }

        if (editingObject && editingObject.id) {
            this.responseJson.data.some((it, i) => {
                if (it.id === editingObject.id) {
                    let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
                    it.createdTime = it.createdTime || "";
                    it.productTitle = it.productTitle || "";
                    it.variantTitle = it.variantTitle || "";
                    this.state.rows[i] = [it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{it.productTitle.substring(0, 30)}</Link>,
                    it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No", <div> <Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button> <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button></div>];
                    return true;
                }
            });
        }


        this.setState({
            showEditModel: false,
            showErrorToast: showErrorMsg,
            showChangesSavedToast: showSuccessMsg
        });
    }

    idToRemove = null;
    removeClick = (id) => {
        this.idToRemove = id;
        this.setState({ showYesOrNoForRemoval: true });
    }

    handleYesOrNoResult = async (val) => {
        let showChangesSavedToast = false;
        try {

            if (val === 'yes') {
                const resp = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + this.idToRemove, {
                    method: 'DELETE'
                });
                console.log(resp);
                if (resp.ok) {
                    this.responseJson.data.forEach((it, i) => {
                        if (it.id === this.idToRemove) {
                            this.state.rows.splice(i, 1);
                        }
                    })
                    showChangesSavedToast = true;
                }

            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            showYesOrNoForRemoval: false,
            showChangesSavedToast: showChangesSavedToast
        });
    }


    search = () => {
        //console.log(this.state.filterColumn)
        //console.log(this.state.filterCondition)
        //////if (!this.state.filterKeyword) {

        //////    this.setState({
        //////        showErrorToastForInvalidSearch: true,
        //////        invalidSearchErrorText: "Please provide search keyword"
        //////    });
        //////    return;
        //////}
        console.log(this.state.filterKeyword);
        this.getPageData();
    }

    downloadAsCustomers = ()=> {
        console.log(this);
        if (!Array.isArray(this.state.rows)) {
            this.setState({
                showErrorToastNoCustomersToDownload: true
            });
            return;
        }
        let csvContentString = "First Name,Last Name,Email,Company,Address1,Address2,City,Province,Province Code,Country,Country Code,Zip,Phone";
        this.state.rows.forEach((it, i) => {
            console.log(it[0]);
            csvContentString += '\n,,,,,,,,,,,,' + it[0];
        });



        environment.download(csvContentString, 'customers_from_vatshub.csv', 'text/csv;encoding:utf-8');


        //////console.log(csvContentString);

        //////var encodedUri = encodeURI(csvContentString);
        //////var link = document.createElement("a");
        //////link.setAttribute("href", encodedUri);
        //////link.setAttribute("download", "customers_from_vatshub.csv");
        //////document.body.appendChild(link); 

        //////link.click(); 
    }


}