import * as React from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';


export class DisplayMessageModal extends React.Component<any,any>{

    constructor(props: any) {
        super(props);
    }

    state = {
        active: true
    }

    render = () => {
        return <div >

            <Modal
                open={this.state.active}
                onClose={this.closed}
                title={this.props.messageToDisplay}
                primaryAction={{
                    content: 'Ok',
                    onAction: this.okClicked,
                }}
            
            >
                <Modal.Section>
                    <TextContainer>
                        <div>
                            {this.props.secondaryMessageToDisplay}
                        </div>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    }

    okClicked = () => {

        this.setState({
            active: false

        });

        if (typeof this.props.handleOkClick === 'function')
            this.props.handleOkClick("ok");
    }

   
    closed = () => {
        this.setState({
            active: false
        });

        if (typeof this.props.handleOkClick === 'function')
            this.props.handleOkClick();
    }
}