import * as React from 'react';
import { Button, Modal, TextContainer } from '@shopify/polaris';


export class YesOrNoModal extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }



    state = {
        active: true
    }

    render = () => {
        setTimeout(() => {
            const chDivs = window.document.querySelectorAll('.Polaris-HorizontalGrid > div'); //> selects only first level children
            if (chDivs && chDivs.length > 0) {
                for (let i2 = 0; i2 < chDivs.length; i2++) {
                    if (chDivs[i2].innerHTML === '') {
                        chDivs[i2].remove();
                    }
                }
            }
        }, 100);
        return <div >

            <Modal
                open={this.state.active}
                onClose={this.closed}
                title={this.props.messageToDisplay}
                primaryAction={{
                    content: this.props.affirmativeText || 'Yes',
                    onAction: this.yesClicked,
                }}
                secondaryActions={[
                    {
                        content: this.props.negativeText || 'No',
                        onAction: this.noClicked,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>
                            {this.props.secondaryMessageToDisplay}
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    }

    yesClicked = () => {

        this.setState({
            active: false
        });

        this.props.handleYesOrNoResult("yes");
    }

    noClicked = () => {
        this.setState({
            active: false
        });

        this.props.handleYesOrNoResult("no");
    }

    closed = () => {
        this.setState({
            active: false
        });

        this.props.handleYesOrNoResult();
    }

    /*    <Modal.Section>
               
                */
}