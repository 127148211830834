import * as React from 'react';
//import * as ReactDOM  from 'react-dom';
//import { useParams } from 'react-router-dom';
import { Link, Page, Card, DataTable, Spinner, TextField, Select, Button, Toast, Icon, Pagination } from '@shopify/polaris';
import PolarisBanner from '../sub-components/PolarisBanner';
import { environment } from '../../environment';
import { ModalWithElements } from '../sub-components/ModalWithElements';
import { SubscriptionEdit } from './SubscriptionEdit';
import { YesOrNoModal } from '../sub-components/YesOrNoModal';
import { SearchMajor, ComposeMajor } from '@shopify/polaris-icons'; 
import PolarisTooltip from '../sub-components/PolarisTooltip';
import { NavLink, Navigate } from 'react-router-dom';



export class Chat extends React.Component<any, any> {

    constructor(props: any) {
        super(props);       
    }

    shop: any = environment.shop
    emptyValue: any = null;
    responseJson;
    vatsNumber
    state = {
        pageSize: 500,
        pageNumber: 0,
        sortedRows: [],
        ready: false,
        rows: this.emptyValue,
        totalRows: 0,//to add figure out pagination
        polarisPaginationHasNext: true,
        polarisPaginationHasPrevious: true,
        showEditModel: false,
        editingObject: null,
        showYesOrNoForRemoval: false,
        showErrorToast: false,
        showChangesSavedToast: false,
        filterColumn: "Product Title",
        filterCondition: "Contains",
        filterKeyword: "",
        showErrorToastForInvalidSearch: false,//to remove if not using after Feb 10
        invalidSearchErrorText: "Please provide search keyword",//to remove if not using after Feb 10
        showErrorToastNoCustomersToDownload: false,
        phoneDirect: '',
        redirectToPhoneDirect:false

    }



    async componentDidMount() {
        console.log('chat_before_waitB4Call');
        await environment.waitB4Call();
        console.log('chat_after_waitB4Call');
        try {
            //let { vatsNumber } = this.props
            //console.log('############## vNumber.props2 '); 
            //let lastIndex1 = this.props.location.pathname.lastIndexOf('/');
            //let vNumber = this.props.location.pathname.substring(lastIndex1 + 1);
            //if (vNumber) {
            //   this.vatsNumber = vNumber.replace(/[^0-9]/g, '');
            //    this.getMessagesByNumber(this.vatsNumber); 
            //}
            //else {
            await this.getPageData()//({ target: { className: "NextButton"}});
            //}


        } catch (e) {
            console.log(e);
        }
    }

    getPageData = async (e?) => {
        try {
            let hasPrev = false;
            let hasNext = true;

            if (e && e.target) {
                if (e.target.className.match('PreviousButton'))
                    this.state.pageNumber--;
                else
                    this.state.pageNumber++;
            }
            else {
                this.state.pageNumber = 1;
                console.log('no event')
            }

            if (this.state.pageNumber > 1) hasPrev = true;
            else hasPrev = false;
            //filterKeyword
            let qString = '?shop=' + this.shop + '.myshopify.com&page_number=' + this.state.pageNumber + '&page_size=' + this.state.pageSize;
            if (this.state.filterKeyword && this.state.filterKeyword.length > 0) {
                qString += '&phone=' + this.state.filterKeyword;
            }
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.vatshubNumbers + qString);

            this.responseJson = await resp.json();
            console.log('  this.responseJson : ' + JSON.stringify(this.responseJson))

            let dataOptimized: any = [];

            this.responseJson.data.forEach((it, i) => {
                let link = '/chat-with/' + it.phone;

                let numberContainer = <div>    <NavLink to={link} >{it.phone}</NavLink>
                    {it.hasUnread && <div><strong> Unread Messages</strong></div>}
                </div>

                dataOptimized.push([numberContainer]);
            });

            let totalRows = this.responseJson.totalCount;
            if (((this.state.pageNumber * this.state.pageSize)) >= totalRows) hasNext = false;

            setTimeout(() => {
                this.setState({
                    rows: dataOptimized,
                    polarisPaginationHasNext: hasNext,
                    polarisPaginationHasPrevious: hasPrev,
                    totalRows: totalRows,
                    pageSize: this.state.pageSize,
                    pageNumber: this.state.pageNumber
                })
            }, 50);
        } catch (e) {
            console.log(e);
            this.setState({
                rows: [],
                polarisPaginationHasNext: false,
                polarisPaginationHasPrevious: false,
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0
            });
        }
    }


    render() {
        var handleFilterColumnChange = (val) => { this.setState({ filterColumn: val }) }
        var handleFilterConditionChange = (val) => { this.setState({ filterCondition: val }) }, handleFilterKeywordValueChange = (val) => { this.setState({ filterKeyword: val }) };
        var handlePhoneDirectChange = (val) => { this.setState({ phoneDirect: val }) };

        return (
            this.state.rows ? <div className="customers-page subscriptions-by-product">

                {this.state.showEditModel && <ModalWithElements modalObject={this.state.editingObject} handleYesOrNoResult={this.handleCancelOrSave} elements={SubscriptionEdit} messageToDisplay="Edit Restock Alert Subscription" />}
                {this.state.showYesOrNoForRemoval && <YesOrNoModal handleYesOrNoResult={this.handleYesOrNoResult} messageToDisplay="Are you sure to remove?" secondaryMessageToDisplay="This back in stock alert will be deleted and won't trigger." />}
                {this.state.showChangesSavedToast && <Toast content="Changes saved" duration={2000} onDismiss={() => { this.setState({ showChangesSavedToast: false }) }} />}
                {this.state.showErrorToast && <Toast error content="Something went wrong" duration={2000} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
                {this.state.showErrorToastForInvalidSearch && <Toast error content={this.state.invalidSearchErrorText} duration={2000} onDismiss={() => { this.setState({ showErrorToastForInvalidSearch: false }) }} />}
                {this.state.showErrorToastNoCustomersToDownload && <Toast error content="No customers to download" duration={2000} onDismiss={() => { this.setState({ showErrorToastNoCustomersToDownload: false }) }} />}
                {this.state.redirectToPhoneDirect && <Navigate to={'/chat-with/' + this.state.phoneDirect}  />}


                <div className="paginator-container ">
                    <Pagination
                        hasNext={this.state.polarisPaginationHasNext}
                        onNext={this.getPageData}
                        hasPrevious={this.state.polarisPaginationHasPrevious}
                        onPrevious={this.getPageData}
                    />
                </div>
                <Page>

                    <div className="filter-conatiner">
                        <div className="filtering-section">
                            <PolarisTooltip active tooltipText="Search within the numbers who replied previously"
                                content={
                                    <TextField
                                        placeholder="Search by WA Number"
                                        prefix={<Icon source={SearchMajor} />}
                                        labelHidden
                                        type='number'
                                        label="Search by WA Number"
                                        value={this.state.filterKeyword}
                                        onChange={handleFilterKeywordValueChange}
                                        clearButton
                                        onClearButtonClick={() => { this.setState({ filterKeyword: "" }, this.search); }}
                                        autoComplete='off'
                                    />
                                } >
                            </PolarisTooltip>
                        </div>
                        <div>
                            <PolarisTooltip active tooltipText="Search within the numbers who replied previously"
                                content={<Button primary onClick={this.search}>Search  </Button>} >
                            </PolarisTooltip>
                        </div>



                        <div className="filtering-section filtering-section-right">
                            <TextField
                                placeholder="Message directly to a WA Number"
                                prefix={<Icon source={ComposeMajor} />}
                                labelHidden
                                type='number'
                                label="Message directly to any WA Number"
                                value={this.state.phoneDirect}
                                onChange={handlePhoneDirectChange}
                                clearButton
                                onClearButtonClick={() => { this.setState({ phoneDirect: "" }); }}
                                autoComplete='off'
                            />
                        </div>
                        <div>
                            <Button primary onClick={this.chatDirectly}>Chat  </Button>
                        </div>
                    </div>

                    <Card>

                        <DataTable

                            columnContentTypes={[
                                'numeric'
                            ]}
                            headings={[
                                'This page shows replied Whatsapp Numbers only'
                            ]}
                            rows={this.state.rows}
                            sortable={[false]}
                            defaultSortDirection="descending"
                            initialSortColumnIndex={1}
                            onSort={this.handleSort}
                            footerContent={`Showing ${(((this.state.pageNumber - 1) * this.state.pageSize) + 1) + ' to ' + (((this.state.pageNumber - 1) * this.state.pageSize) + this.state.rows.length)} of ${this.state.totalRows} results`}

                        />
                    </Card>
                </Page></div> : <div className="home-page-loading-spinner-container"><Spinner accessibilityLabel="Loading the data" size="large" /></div>



        );

    }
    handleSort = () => { }//#to add todo

    editingId
    editClicked = (id) => {
        console.log('edit clicked');//edit things from a separate page
        this.editingId = id;

        let vatshubSubscription;
        this.responseJson.data.some((it) => {
            if (it.id == this.editingId) {
                vatshubSubscription = it;
                return;
            }
        });
        console.log(vatshubSubscription)
        this.setState({
            showEditModel: true,
            editingObject: vatshubSubscription
        });
    }


    handleCancelOrSave = async (resultingObject) => {
        console.log("resultingObject: ");
        console.log(resultingObject);

        let showSuccessMsg = false;
        let showErrorMsg = false;
        let editingObject;
        try {
            if (resultingObject && resultingObject.id) {
                const updateResponse = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + resultingObject.id, {
                    method: 'PUT',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(resultingObject)
                });


                if (updateResponse.ok) {
                    editingObject = resultingObject;
                    showSuccessMsg = true;
                }
            }

        } catch (e) {
            console.log(e);
            showErrorMsg = true
        }

        if (editingObject && editingObject.id) {
            this.responseJson.data.some((it, i) => {
                if (it.id === editingObject.id) {
                    let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
                    it.createdTime = it.createdTime || "";
                    it.productTitle = it.productTitle || "";
                    it.variantTitle = it.variantTitle || "";
                    this.state.rows[i] = [it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{it.productTitle.substring(0, 30)}</Link>,
                    it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No", <div> <Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button> <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button></div>];
                    return true;
                }
            });
        }


        this.setState({
            showEditModel: false,
            showErrorToast: showErrorMsg,
            showChangesSavedToast: showSuccessMsg
        });
    }

    idToRemove = null;
    removeClick = (id) => {
        this.idToRemove = id;
        this.setState({ showYesOrNoForRemoval: true });
    }

    handleYesOrNoResult = async (val) => {
        let showChangesSavedToast = false;
        try {

            if (val === 'yes') {
                const resp = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + this.idToRemove, {
                    method: 'DELETE'
                });
                console.log(resp);
                if (resp.ok) {
                    this.responseJson.data.forEach((it, i) => {
                        if (it.id === this.idToRemove) {
                            this.state.rows.splice(i, 1);
                        }
                    })
                    showChangesSavedToast = true;
                }

            }
        } catch (e) {
            console.log(e);
        }

        this.setState({
            showYesOrNoForRemoval: false,
            showChangesSavedToast: showChangesSavedToast
        });
    }


    search = () => {
        //console.log(this.state.filterColumn)
        //console.log(this.state.filterCondition)
        //////if (!this.state.filterKeyword) {

        //////    this.setState({
        //////        showErrorToastForInvalidSearch: true,
        //////        invalidSearchErrorText: "Please provide search keyword"
        //////    });
        //////    return;
        //////}
        console.log(this.state.filterKeyword);
        this.getPageData();
    }

    chatDirectly = () => {
        this.setState({ redirectToPhoneDirect: true });
    }

    getMessagesByNumber = (number) => {
        return [];
    }

}