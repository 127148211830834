import {
  ActionList,
  AppProvider,
  ContextualSaveBar,
  Frame,
  Navigation,
  TopBar,
  Icon,
  Button,
  SubNavigationItem,
} from '@shopify/polaris';
import {
  HomeMajor,
  ThemeEditMajor,
  ProfileMajor,
  ChatMajor,
  BillingStatementDollarMajor,
  ExitMajor,
  AnalyticsTableMinor,
  ConnectMinor
} from '@shopify/polaris-icons';
import { useState, useCallback, useRef, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import MyRoutes from './components/MyRoutes';
import { environment } from './environment';
import { YesOrNoModal } from './components/sub-components/YesOrNoModal';
import PolarisTooltip from './components/sub-components/PolarisTooltip';
import { Logo } from '@shopify/polaris/build/ts/src/utilities/frame/types';

function App() {

  console.log('app_calling')

  const location = useLocation();


  const defaultState = useRef({
    emailFieldValue: 'dharma@jadedpixel.com',
    nameFieldValue: 'Jaded Pixel',
  });
  const skipToContentRef = useRef<HTMLAnchorElement>(null);

  const [toastActive, setToastActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue,
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue,
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue,
  );

  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const handleSearchFieldChange = useCallback((value: string) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const setTopBarAndSideNav = () => {
    console.log('setTopBarAndSideNav_path_' + window.location.pathname + '___');
    if (window.location.pathname.indexOf('account/login') > -1) {
      setTopBarMarkupRef(null);
      setNavigationMarkupRef(null);
      //window.document.getElementById('AppFrameNav').style.display = 'none';
      //window.document.getElementById('AppFrameTopBar').style.display = 'none';
    }
    else {
      setTopBarMarkupRef(topBarMarkup);




      let navigatedLocations2: any = {
        ...navigatedLocations
      };
      let loc = location.pathname.replace('/', '').replace(/\//gi, '_').toLowerCase();
      if (!loc) loc = 'home';
      navigatedLocations2[loc] = true;
      for (const prop in navigatedLocations2) {
        if (prop !== loc && navigatedLocations2[prop] === true) {
          navigatedLocations2[prop] = false;
        }
      }
      console.log('nav_loc_going_to_set_' + JSON.stringify(navigatedLocations2));
      setNavigatedLocations(navigatedLocations2);

    }
  }


  const userMenuActions = [
    {
      items: [{ content: 'Logout', onAction: () => { console.log(window); setNavigateTologinPage(false); setShowYesOrNo(true) } }],
    },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={environment.shop}
      detail={null}
      initials={(environment.shop + '').substring(0, 1).toUpperCase()}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[{ content: 'Shopify help center' }, { content: 'Community forums' }]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const whatsAppDeveloper = useCallback(() => {
    window.open('https://wa.me/918220889374', '_blank');
  }, []);


  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={<span style={{paddingTop:'4px'}} className='cust-user'>{environment.shop}</span>}
      searchResultsVisible={searchActive}
      searchField={null}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );


  const navigate = useNavigate();
  const [navigatedLocations, setNavigatedLocations] = useState({});
  const setNavedLocation = (loc) => {

    for (const prop in navigatedLocations) {
      navigatedLocations[prop] = false;
    }

    let navigatedLocations2: any = {
      ...navigatedLocations
    }

    navigatedLocations2[loc] = true;

    setNavigatedLocations(navigatedLocations2);
  }

  const subNavigationItems = [
    {
      url: '/customize?tab=subscription-form',
      excludePaths: ['#'],
      label: 'Subscription Form - Restock Alert',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=subscription-form'); navigate('/customize?tab=subscription-form');
      }
    },
    {
      url: '/customize?tab=bis-template',
      excludePaths: ['#'],
      label: 'Template-Restock alert',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=bis-template'); navigate('/customize?tab=bis-template');
      }
    },
    {
      url: '/customize?tab=abandoned-checkout',
      excludePaths: ['#'],
      label: 'Abandoned Checkout',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=abandoned-checkout'); navigate('/customize?tab=abandoned-checkout');
      }
    },

    {
      url: '/customize?tab=order-confirmation',
      excludePaths: ['#'],
      label: 'Order Confirmation',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=order-confirmation'); navigate('/customize?tab=order-confirmation');
      }
    },
    {
      url: '/customize?tab=order-ship-alert',
      excludePaths: ['#'],
      label: 'Order Ship Alert',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=order-ship-alert'); navigate('/customize?tab=order-ship-alert');
      }
    },
    {
      url: '/customize?tab=chat-button',
      excludePaths: ['#'],
      label: 'Chat Button',
      onClick: () => {
        setSubNavSelectedItemColor(window.event.target);
        setNavedLocation('customize?tab=chat-button'); navigate('/customize?tab=chat-button');
      }
    }
  ]

  const si=null;
  useEffect(() => {

    setTopBarAndSideNav()

    console.log('__________path_search1234333' + location.pathname + '____' + location.search);
setTimeout(() => {
  
  subNavigationItems.some((it) => {
    if (location.pathname + location.search === it.url) {
      let allAEl: any = window.document.querySelectorAll('.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text');
      const tdowal = () => {
        for (let i = 0; i < allAEl.length; i++) {
          allAEl[i].style.color = 'rgba(97, 106, 117, 1)';
          allAEl[i].parentElement.style.backgroundColor = 'rgba(241, 242, 244, 1)';
          if (allAEl[i].innerText == it.label) {
            allAEl[i].style.color = 'rgba(0, 122, 92, 1)';
            allAEl[i].parentElement.style.backgroundColor = '#dfe9e3';
          }
        }
      }
      if (allAEl && allAEl.length > 0) {
        tdowal();
    
      }
      else {
        setTimeout(() => {
          allAEl = window.document.querySelectorAll('.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text');
          if (allAEl && allAEl.length > 0) {
            tdowal();
      
          }
        }, 2000);
      }
    }
  });
}, 200);

  }, [location]);



  useEffect(() => {

    setNavigationMarkupRef(navigationMarkup);
  }, [navigatedLocations])


  const customersIcon = () => {
    const t = () => {
      return <i className="fa fa-users customers-icon" ></i>
    }

    return <Icon source={t}></Icon>
  }

  const setSubNavSelectedItemColor = (el: any, notAnEvent?) => {
    try {
      if (window.event) {
        window.event.stopPropagation();
        window.event.preventDefault();
      }

      const allAEl: any = window.document.querySelectorAll('.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text');
      for (let i = 0; i < allAEl.length; i++) {
        allAEl[i].style.color = 'rgba(97, 106, 117, 1)';
        el.parentElement.style.backgroundColor = 'rgba(241, 242, 244, 1)';
      }

      if (notAnEvent && !el) {
        const curentUrl = window.location.pathname + window.location.search;
        subNavigationItems.some((subNavIt) => {
          if (subNavIt.url == curentUrl) {

            return true;
          }
        });
      }

      if (el) {
        el.style.color = 'rgba(0, 122, 92, 1)';
        el.parentElement.style.backgroundColor = '#dfe9e3';
      }
    } catch (error) {
      console.log(error.stack);
    }
  }


  const navigationMarkup = (
    <Navigation location={location.pathname}>

      <Navigation.Section
        items={[
          {
            label: 'Home',
            icon: HomeMajor,
            onClick: () => { setNavedLocation('home'); navigate('/home') },
            selected: navigatedLocations['home']
          },
          {
            label: 'Customize',
            icon: ThemeEditMajor,
            onClick: () => {
              window.event.stopPropagation();
              window.event.preventDefault();
              setNavedLocation('customize?tab=subscription-form'); navigate('/customize?tab=subscription-form')
            },
            url: '/customize',
            selected: navigatedLocations['customize'],
            expanded:true,
            subNavigationItems
          },
          // {
          //   label: 'Connect WA',
          //   icon: ConnectMinor,
          //   onClick: () => { setNavedLocation('connect-wa'); navigate('/connect-wa') },
          //   selected: navigatedLocations['connect-wa']
          // },
          {
            label: 'Data Lists',
            icon: AnalyticsTableMinor,
            onClick: () => { setNavedLocation('data-lists'); navigate('/data-lists') },
            selected: navigatedLocations['data-lists']
          },
          {
            label: 'Profile',
            icon: ProfileMajor,
            onClick: () => { setNavedLocation('profile'); navigate('/profile') },
            selected: navigatedLocations['profile']
          },
          {
            label: 'Customers',
            icon: customersIcon,
            onClick: () => { setNavedLocation('customers'); navigate('/customers') },
            selected: navigatedLocations['customers']
          },
          // {
          //   label: 'WA Chat',
          //   icon: ChatMajor,
          //   onClick: () => { setNavedLocation('chat'); navigate('/chat') },
          //   selected: navigatedLocations['chat']
          // },
          {
            label: 'Plans', 
            icon: BillingStatementDollarMajor,
            onClick: () => { setNavedLocation('choose-plan'); navigate('/choose-plan') },
            selected: navigatedLocations['choose-plan']
          },
          {
            label: 'Logout',
            icon: ExitMajor,
            onClick: () => { setNavigateTologinPage(false); setShowYesOrNo(true) },
            selected: navigatedLocations['account/login']
          }
        ]}

      />
    </Navigation>
  );

  const navigationMarkuptoDelete = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            url: '#',
            excludePaths: ['#'],
            label: 'Home',
            icon: HomeMajor,
          },
          {
            url: '#',
            excludePaths: ['#'],
            label: 'Orders',
            icon: HomeMajor,
            badge: '15',
            subNavigationItems: [
              {
                url: '#',
                excludePaths: ['#'],
                disabled: false,
                label: 'Collections',
              },
              {
                url: '#',
                excludePaths: ['#'],
                disabled: false,
                label: 'Inventory',
              },
            ],
          },
          {
            url: '#',
            excludePaths: ['#'],
            label: 'Marketing',
            icon: HomeMajor,
            badge: '15',
            selected: true,
            subNavigationItems: [
              {
                url: '#',
                excludePaths: ['#'],
                disabled: false,
                label: 'Reports',
              },
              {
                url: '#',
                excludePaths: ['#'],
                disabled: false,
                label: 'Live view',
              },
            ],
          },
          {
            url: '#',
            label: 'Products',
            excludePaths: ['#'],
            icon: HomeMajor,
            subNavigationItems: [
              {
                url: '#',
                excludePaths: ['#'],
                disabled: false,
                label: 'Collections',
              },
              {
                url: '#',
                disabled: false,
                label: 'Inventory',
              },
            ],
          },
        ]}
      />
    </Navigation>
  )

  const pageMarkup = (() => {
    return (<MyRoutes></MyRoutes>)
  })();

  const logo = {
    width: 133,
    topBarSource:
      '/vatshub_with_text.png',
    contextualSaveBarSource:
      '/vatshub_with_text.png',
    url: '#',
    accessibilityLabel: 'Vatshub topbar logo',
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/')
      navigate('/');
    else console.log('already in home')
  }

  const [topBarMarkupRef, setTopBarMarkupRef] = useState(null);
  const [navigationMarkupRef, setNavigationMarkupRef] = useState(null);

  useEffect(() => {
    console.log('app_tx_useeffect2');
    const tbl = document.getElementsByClassName('Polaris-TopBar__LogoLink');

    setTopBarAndSideNav();

    if (tbl.length > 0) {
      console.log('got it');
      tbl[0].addEventListener('click', handleLogoClick);

      return () => {
        if (tbl[0]) tbl[0].removeEventListener('click', handleLogoClick);
      }
    }


  }, []);

  const [showYesOrNo, setShowYesOrNo] = useState(false);
  const [navigateTologinPage, setNavigateTologinPage] = useState(false);
  const handleYesOrNoResult = (result) => {
    if (result === 'yes') {
      setNavigateTologinPage(true);
      setShowYesOrNo(false);
    }
    else {
      setShowYesOrNo(false);
    }
  }
  const modals = (
    <div>
      {showYesOrNo && <YesOrNoModal handleYesOrNoResult={handleYesOrNoResult} messageToDisplay="Are you sure to logout?" secondaryMessageToDisplay="You can login back by opening Vatshub app from your Shopify admin" />}
      {navigateTologinPage && <Navigate to='/account/login' />}
    </div>
  )

  return (
    <div style={{ height: '500px' }}>
      <AppProvider
        i18n={{
          Polaris: {
            Avatar: {
              label: 'Avatar',
              labelWithInitials: 'Avatar with initials {initials}',
            },
            ContextualSaveBar: {
              save: 'Save',
              discard: 'Discard',
            },
            TextField: {
              characterCount: '{count} characters',
            },
            TopBar: {
              toggleMenuLabel: 'Toggle menu',

              SearchField: {
                clearButtonLabel: 'Clear',
                search: 'Search',
              },
            },
            Modal: {
              iFrameTitle: 'body markup',
            },
            Frame: {
              skipToContent: 'Skip to content',
              navigationLabel: 'Navigation',
              Navigation: {
                closeMobileNavigationLabel: 'Close navigation',
              },
            },
          },
        }}
      >
        <Frame
          logo={logo}
          topBar={topBarMarkupRef}
          navigation={navigationMarkupRef}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef}

        >
          {contextualSaveBarMarkup}

          {pageMarkup}

          {modals}
        </Frame>

      </AppProvider>
    </div>
  );
}

export default App;
