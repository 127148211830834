import * as React from 'react';
import { Card, DataTable, Spinner, Button, Link } from '@shopify/polaris';
import { environment } from '../../environment';

export class ChatSingle extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    shop: any = environment.shop
    emptyValue: any = null;
    responseJson;
    vatsNumber
    ngrokUrl = 'https://www.wa-api.vatshub.com';
    state = {
        pageSize: 20,
        pageNumber: 0,
        ready: false,
        rows: this.emptyValue,
        totalRows: 0,
        polarisPaginationHasNext: true,
        polarisPaginationHasPrevious: true,
        messageToSend: "",
        sendBtnName: "Send"

    }

    removeHoverListenerOn = true;
    async componentDidMount() {
        try {
            await environment.waitB4Call();
            setTimeout(() => {
              
                let lastIndex1 = window.location.pathname.lastIndexOf('/');
                let vNumber = window.location.pathname.substring(lastIndex1 + 1);
                if (vNumber) {
                    console.log('vatsNumber ' + vNumber);
                    this.vatsNumber = vNumber.replace(/[^0-9]/g, '');
                    this.getPageData();
                } else {
                    console.log('no vats number');
                }


                //make hovering not changing the bg
                let count1 = 0;
                let si1 = setInterval(() => {
                    if (!this.removeHoverListenerOn) return;
                    count1++;
                    if (count1 > 110) {
                        this.removeHoverListenerOn = false;
                        count1 = 0;
                        //clearInterval(si1)
                    }
                    try {
                        let chatSingPage = window.document.getElementsByClassName('chat-single-page');
                        if (chatSingPage.length > 0) {
                            let ths = window.document.getElementsByClassName('chat-single-page')[0].getElementsByTagName('th');
                            for (var i = 0; i < ths.length; i++) {
                                if (!ths[i].className.match('e0ddd0-listener-added')) {
                                    ths[i].addEventListener('mouseenter', (e: any) => {
                                        e.target.style.backgroundColor = '#e0ddd0';
                                    });
                                    ths[i].classList.add('e0ddd0-listener-added');
                                }
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }, 500);
                //this.receiveMessages();
            }, 200);
        } catch (e) {
            console.log(e);
        }
    }

    getPageData = async (e?) => {
        try {

            this.receiveMessagesWS();
            this.setState({
                rows: [[<div id="fetchingLast">Fecthing last few messages...</div>]]
            });

            //console.log('getPageDataCalled')
            //return    
            let hasPrev = false;
            let hasNext = true;

            if (e && e.target) {
                if (e.target.className.match('PreviousButton'))
                    this.state.pageNumber--;
                else
                    this.state.pageNumber++;
            }
            else {
                this.state.pageNumber = 1;
                console.log('no event')
            }

            if (this.state.pageNumber > 1) hasPrev = true;
            else hasPrev = false;
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.getWhatsappMessages +
                '?from=' + this.vatsNumber);


            this.responseJson = await resp.json();
            //   console.log('  this.responseJson : ' + JSON.stringify(this.responseJson))

            let dataOptimized: any = [];

            /*
              this.responseJson : {"totalCount":10,"pageSize":10,"pageNumber":1,"data":{"result":[{"id":"true_919080451154@c.us_91X6RGPZ4J2YX4RPJIZR","body":"Test from subbu at hour15 , now: 6/21/2021 3:02:05 PM","type":"chat","t":1624267925,"notifyName":"","from":"918610283383@c.us","to":"919080451154@c.us","self":"in","ack":3,"invis":true,"star":false,"isFromTemplate":false,"mentionedJidList":[],"isVcardOverMmsDocument":false,"isForwarded":false,"labels":[],"productHeaderImageRejected":false,"isDynamicReplyButtonsMsg":false,"isMdHistoryMsg":false,"chatId":{"server":"c.us","user":"919080451154","_serialized":"919080451154@c.us"},"fromMe":true,"sender":{"id":{"server":"c.us","user":"918610283383","_seria
             */
            if( this.responseJson.data)
            this.responseJson.data.result.forEach((it, i) => { //TypeError: this.responseJson.data.forEach is not a function
                let allRow = <div className="not-from-me msg-divs" > {it.body} </div>;
                if (it.fromMe) allRow = <div className="from-me msg-divs" > {it.body} </div>;
                dataOptimized.push([allRow]);
            });

            let totalRows = this.responseJson.totalCount;
            if (((this.state.pageNumber * this.state.pageSize)) >= totalRows) hasNext = false;

            setTimeout(() => {
                this.setState({
                    rows: dataOptimized,
                    polarisPaginationHasNext: hasNext,
                    polarisPaginationHasPrevious: hasPrev,
                    totalRows: totalRows,
                    pageSize: this.state.pageSize,
                    pageNumber: this.state.pageNumber
                });

                setTimeout(() => {
                    try {//auto scroll into last message
                        let msgDivs = window.document.getElementsByClassName('msg-divs');
                        if (msgDivs.length > 0) {
                            msgDivs[msgDivs.length - 1].scrollIntoView();
                        }
                    } catch (e) {
                        console.log(e);
                    }

                }, 300);
            }, 50);
        } catch (e) {
            console.log(e);
            this.setState({
                rows: [],
                polarisPaginationHasNext: false,
                polarisPaginationHasPrevious: false,
                totalRows: 0,
                pageSize: 0,
                pageNumber: 0
            });
        }
    }


    render() {
        var handleFilterColumnChange = (val) => { this.setState({ filterColumn: val }) }
        var handleFilterConditionChange = (val) => { this.setState({ filterCondition: val }) }, handleFilterKeywordValueChange = (val) => { this.setState({ filterKeyword: val }) };

        return (

            this.state.rows ? <div className="customers-page chat-single-page subscriptions-by-product">
                <Card>
                    {/*<Link onClick={ this.loadEarlierMessages}>Load earlier messages</Link>*/}
                    <DataTable

                        columnContentTypes={[
                            'text'
                        ]}
                        headings={[
                            'chat with ' + this.vatsNumber,
                        ]}
                        rows={this.state.rows}
                        sortable={[false]}
                        defaultSortDirection="descending"
                        initialSortColumnIndex={1}

                        footerContent={``}

                    />
                </Card>

                <div className="send-message-container" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <span style={{ paddingRight: '47px', paddingTop: '6px' }}>{this.vatsNumber}</span>
                    <input style={{ border: 'none', width: '70%' }} id="messageToSend" onChange={(d) => { this.setState({ messageToSend: d.target.value }); }} value={this.state.messageToSend} />

                    {this.state.messageToSend && <Button onClick={this.sendMessage}>{this.state.sendBtnName} </Button>}
                    {!this.state.messageToSend && <Button disabled>Send </Button>}
                </div>

            </div> : <div className="home-page-loading-spinner-container"><Spinner accessibilityLabel="Loading the data" size="large" /></div>

        );

    }
    messageToSend
    sendMessage = async () => {
        console.log("at send message " + this.state.messageToSend);
        this.setState({ sendBtnName: 'Sending...' })
        const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.sendWhatsappMessage +
            '?to=' + this.vatsNumber + "&msgToSend=" + this.state.messageToSend);


        //this.responseJson = await resp.json();
        //console.log('send message response ');
        //this.responseJson
        let dataOptimized = this.state.rows;
        let id = 'addedChatElem_' + new Date().getTime();
        dataOptimized.push([<div className="from-me msg-divs" id={id}> {this.state.messageToSend} </div>]);

        this.setState({
            rows: dataOptimized,
            messageToSend: "",
            sendBtnName: 'Send'
        }, () => {
            console.log('after adding a row');
            let addedElem = window.document.getElementById(id);
            addedElem.scrollIntoView();
            this.removeHoverListenerOn = true;

        });
    }


    socket
    receiveMessagesWS = () => {
        let thisRef: any = this;
        //console.log('connection thing');
        //console.log(environment.ngrokUrl.replace('https:', 'ws:'));
        //if (thisRef != 'fdgdfgd') return;//api/JsonStorages
        //let ngrokurl = environment.ngrokUrl;//.replace('https:', 'wss:')

        console.log('receiveMessagesWS')

        this.socket = new WebSocket(this.ngrokUrl.replace('https:', 'wss:'), 'echo-protocol');

        this.socket.addEventListener('message', function (event) {
            try {
                console.log('Message from server ', typeof event.data);
                let dataOptimized = thisRef.state.rows;
                if (!Array.isArray(dataOptimized)) dataOptimized = [];
                let id = 'addedChatElem_' + new Date().getTime();
                let data = JSON.parse(event.data);
                dataOptimized.push([<div className="not-from-me msg-divs" id={id}> {data.body} </div>]);
                thisRef.setState({
                    rows: dataOptimized
                }, () => {
                    console.log('after adding a row');
                    let addedElem = window.document.getElementById(id);
                    addedElem.scrollIntoView();
                    thisRef.removeHoverListenerOn = true;
                });
            } catch (error) {
                console.log(error)
            }
        });

        this.socket.addEventListener('open', function (event) {
            console.log('sending to ws');
            thisRef.socket.send(JSON.stringify({ from: thisRef.vatsNumber + '@c.us', listeningForIncomingMessages: true }));
            // socket.send('Hello Server!');
        });
    }
    //handleCancelOrSave = async (resultingObject) => { //get_new_chat_messages_uuurrrlll
    //    console.log("resultingObject: ");
    //    console.log(resultingObject);

    //    let showSuccessMsg = false;
    //    let showErrorMsg = false;
    //    let editingObject;
    //    try {
    //        if (resultingObject && resultingObject.id) {
    //            const updateResponse = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + resultingObject.id, {
    //                method: 'PUT',
    //                headers: { "Content-Type": "application/json" },
    //                body: JSON.stringify(resultingObject)
    //            });


    //            if (updateResponse.ok) {
    //                editingObject = resultingObject;
    //                showSuccessMsg = true;
    //            }
    //        }

    //    } catch (e) {
    //        console.log(e);
    //        showErrorMsg = true
    //    }

    //    if (editingObject && editingObject.id) {
    //        this.responseJson.data.some((it, i) => {
    //            if (it.id === editingObject.id) {
    //                let link = 'https://' + this.shop.replace('.myshopify.com', '') + '.myshopify.com/admin/products/' + it.productid;
    //                it.createdTime = it.createdTime || "";
    //                it.productTitle = it.productTitle || "";
    //                it.variantTitle = it.variantTitle || "";
    //                this.state.rows[i] = [it.createdTime.substring(0, 10), <Link external url={link} key="emerald-silk-gown">{it.productTitle.substring(0, 30)}</Link>,
    //                it.variantTitle.substring(0, 30), it.vatshubnumber, it.triggerCount > 0 ? "Yes" : "No", <div> <Button onClick={() => { this.editClicked(it.id); }} plain>Edit</Button> <Button onClick={() => { this.removeClick(it.id) }} plain destructive>Remove</Button></div>];
    //                return true;
    //            }
    //        });
    //    }


    //    this.setState({
    //        showEditModel: false,
    //        showErrorToast: showErrorMsg,
    //        showChangesSavedToast: showSuccessMsg
    //    });
    //}

    idToRemove = null;
    removeClick = (id) => {
        this.idToRemove = id;
        this.setState({ showYesOrNoForRemoval: true });
    }

    //handleYesOrNoResult = async (val) => {
    //    let showChangesSavedToast = false;
    //    try {

    //        if (val === 'yes') {
    //            const resp = await fetch(environment.mainAppOriginUrl + '/api/VastshubSubscriptions/' + this.idToRemove, {
    //                method: 'DELETE'
    //            });
    //            console.log(resp);
    //            if (resp.ok) {
    //                this.responseJson.data.forEach((it, i) => {
    //                    if (it.id === this.idToRemove) {
    //                        this.state.rows.splice(i, 1);
    //                    }
    //                })
    //                showChangesSavedToast = true;
    //            }

    //        }
    //    } catch (e) {
    //        console.log(e);
    //    }

    //    this.setState({
    //        showYesOrNoForRemoval: false,
    //        showChangesSavedToast: showChangesSavedToast
    //    });
    //}


    // search = () => {
    //console.log(this.state.filterColumn)
    //console.log(this.state.filterCondition)
    //////if (!this.state.filterKeyword) {

    //////    this.setState({
    //////        showErrorToastForInvalidSearch: true,
    //////        invalidSearchErrorText: "Please provide search keyword"
    //////    });
    //////    return;
    //////}
    //////////console.log(this.state.filterKeyword);
    //////////this.getPageData();
    //  }

    componentWillUnmount() {
        try {
            if (this.socket)
                this.socket.close();
        } catch (e) {
            console.log(e);
        }
    }
    loadEarlierMessages = async () => {
        const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.getWhatsappMessages +
            '?from=' + this.vatsNumber + "&earlier=" + true);

        this.responseJson = await resp.json();
        //   console.log('  this.responseJson : ' + JSON.stringify(this.responseJson))

        let dataOptimized = this.state.rows;

        /*
          this.responseJson : {"totalCount":10,"pageSize":10,"pageNumber":1,"data":{"result":[{"id":"true_919080451154@c.us_91X6RGPZ4J2YX4RPJIZR","body":"Test from subbu at hour15 , now: 6/21/2021 3:02:05 PM","type":"chat","t":1624267925,"notifyName":"","from":"918610283383@c.us","to":"919080451154@c.us","self":"in","ack":3,"invis":true,"star":false,"isFromTemplate":false,"mentionedJidList":[],"isVcardOverMmsDocument":false,"isForwarded":false,"labels":[],"productHeaderImageRejected":false,"isDynamicReplyButtonsMsg":false,"isMdHistoryMsg":false,"chatId":{"server":"c.us","user":"919080451154","_serialized":"919080451154@c.us"},"fromMe":true,"sender":{"id":{"server":"c.us","user":"918610283383","_seria
         */
        if( this.responseJson.data)
        this.responseJson.data.result.forEach((it, i) => { //TypeError: this.responseJson.data.forEach is not a function
            let allRow = <div className="not-from-me msg-divs" > {it.body} </div>;
            if (it.fromMe) allRow = <div className="from-me msg-divs" > {it.body} </div>;
            dataOptimized.unshift([allRow]);
        });
    }
    async getNgrokUrl() {
        try {
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.jsonStorages +
                '?typeOrTag=wa_api_origin');


            this.responseJson = await resp.json();

            let jsonStorageObj = this.responseJson.data;
            if (typeof jsonStorageObj === 'string') {
                jsonStorageObj = JSON.parse(jsonStorageObj);
            }

            if (Array.isArray(jsonStorageObj) && jsonStorageObj[0]) {
                let jsonData = jsonStorageObj[0].jsonData;
                if (typeof jsonData === 'string') {
                    jsonData = JSON.parse(jsonData);
                }

                return jsonData.waApiOrigin;
            }
            return null;
        } catch (e) {
            console.log(e);
            return null;
        }




    }
}