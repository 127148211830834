import * as React from 'react';
import { Text, LegacyCard, Button, TextContainer, BannerProps, BannerStatus, Banner } from '@shopify/polaris'; //changed from card to legacy card and heading from Text
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { AuthService } from '../../commons/AuthService';
import { environment } from '../../environment'; //from '@shopify/polaris-icons';
import { PageInteractor } from '../../commons/PageInteractor';
import PolarisBanner from '../sub-components/PolarisBanner';
//import { Card, Button, Layout } from '@shopify/polaris';
//import {PolarisLayout } from '../sub-components/PolarisLayout';

//declare var Layout1: any;
//Layout = Layout1;

export class ChoosePlan extends React.Component<any, any> {

    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        console.log('choode_plan_called');
        document.title = "Choose Plan-Vatshub Dashboard";
        
        const shopsToAvailEssentialPlan = 'harrowhouse-lk,saadaa-design,vardenchi-motorcycle-lifestyle,sourcesexpress,bitstate,nutriworld-it,aganya-kreation' + 
        'crystal-blooms-guatemala,ubaid-collections,kalishoes,smartcrtechnology'
        if(this.state.planName === this.state.planNameEssential || shopsToAvailEssentialPlan.indexOf(AuthService.shop)>-1){
            this.setState({showEssentialPlan:true});
        }
    }

    state = {
        planName: AuthService.planName,
        planNameFree: environment.literals.planNameFree,
        planNameEssential: environment.literals.planNameEssential,
        planNameStandard: environment.literals.planNameStandard,
        planNameBusiness: environment.literals.planNameBusiness,
        planNameElite: environment.literals.planNameElite,
        isAuthed: AuthService.isAuthed,
        showEssentialPlan:false,
    }

    render() {
      

        return (
            <div className="ChoosePlan-container">

                {(!this.state.planName && this.state.isAuthed) && <PolarisBanner
                    title="You have logged in successfully, please choose a plan to continue"
                    status="success"

                />}
                <MDBRow className="plan-card-row">
                    <MDBCol md="4" className="plan-card-column">
                        <LegacyCard title={this.state.planNameFree} >
                            <p className="pricing__tag">
                                <span className="pricing__tag--small">$</span>
                                0<span className="pricing__tag--small">/month</span>
                            </p>
                            {this.state.planName != this.state.planNameFree
                                ? <Button primary onClick={() => { this.planChosen(this.state.planNameFree) }}>Choose this plan</Button>
                                : <div className="current-plan-container">  <MDBIcon icon="check" className="mr-3 current-plan-check" />Current Plan</div>}
                            <br />
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Automated WhatsApp Messages</Text>
                                <p style={{ color: 'red', fontWeight: 'bold' }}>
                                    30 per month
                                </p>
                                <p style={{ opacity: 0 }}>
                                    30
                                </p>
                                <p style={{ opacity: 0,marginBottom:'-7px' }}>
                                    30
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Trial Period </Text>
                                <p>
                                    Glimpse the app. Free, No time limit.
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />

                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Connect & automate your own WA number in minutes </Text>
                                <p style={{ color: 'red', fontWeight: 'bold' }}>
                                    No
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />

                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Auto notifications</Text>
                                <p>
                                    Back in stock, Abandoned checkout, Order Confirmation, Order shipped
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Back In Stock Subscriptions </Text>
                                <p>
                                    Unlimited
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />
                        </LegacyCard>
                    </MDBCol>
                    <MDBCol md="4" className="plan-card-column">
                        <LegacyCard title={this.state.planNameStandard}  >
                            <p className="pricing__tag">
                                <span className="pricing__tag--small">$</span>
                                2.95<span className="pricing__tag--small">/month</span>
                            </p>
                            {this.state.planName != this.state.planNameStandard
                                ? <Button primary onClick={() => { this.planChosen(this.state.planNameStandard) }}>Choose this plan</Button>
                                : <div className="current-plan-container">  <MDBIcon icon="check" className="mr-3 current-plan-check" />Current Plan</div>}
                            <br />
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Automated WhatsApp Messages</Text>
                                <p style={{ color: 'green', fontWeight: 'bold' }}>
                                        Unlimited
                                </p>
                                <p style={{ color: 'green' }}>
                                (Capped 1 per minute, once your own WhatsApp number connected 2 per minute )
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Trial Period </Text>
                                <p>
                                    7 Days
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />

                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Connect & automate your own WA number in minutes </Text>
                                <p style={{ color: 'green', fontWeight: 'bold' }}>
                                    Yes
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />

                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Auto notifications</Text>
                                <p>
                                    Back in stock, Abandoned checkout, Order Confirmation, Order shipped
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />
                            <TextContainer spacing="tight">
                                <Text variant="headingMd" as="h2">Back In Stock Subscriptions </Text>
                                <p>
                                    Unlimited
                                </p>
                            </TextContainer>
                            <br />
                            <br />
                            <br />
                        </LegacyCard>
                    </MDBCol>

                    {this.state.showEssentialPlan &&
                        <MDBCol md="4" className="plan-card-column">
                            <LegacyCard title={this.state.planNameEssential}  >
                                <p className="pricing__tag">
                                    <span className="pricing__tag--small">$</span>
                                    2.95<span className="pricing__tag--small">/month</span>
                                </p>
                                {this.state.planName != this.state.planNameEssential
                                    ? <Button primary onClick={() => { this.planChosen(this.state.planNameEssential) }}>Choose this plan</Button>
                                    : <div className="current-plan-container">  <MDBIcon icon="check" className="mr-3 current-plan-check" />Current Plan</div>}
                                <br />
                                <br />
                                <br />  
                                <TextContainer spacing="tight">
                                <p style={{ color: 'green', fontWeight: 'bold' }}>
                                    Same as 'Standard' plan with reduced price for existing shops
                                </p>
                                <br />
                                <br />
                                <br />  
                            </TextContainer>
                            </LegacyCard>
                        </MDBCol>
                    }
                </MDBRow>
            </div>

        )
    }

    planChosen = (chosenPlan) => {
        let confirm = false;
        let confirmNeeded = false;
        let previousPlan = null;
        if(this.state.planName === this.state.planNameStandard  && chosenPlan === this.state.planNameFree){
            confirmNeeded=true;
            confirm = window.confirm('You have choosen "Free" plan, this will remove you from existing "Standard" plan , are you sure to continue?');      
            previousPlan = this.state.planNameStandard       
        }
        else if(this.state.planName === this.state.planNameEssential  && chosenPlan === this.state.planNameFree){
            confirmNeeded=true;
            confirm = window.confirm('You have choosen "Free" plan, this will remove you from existing "Essential" plan , are you sure to continue?');
            previousPlan = this.state.planNameStandard 
        }

        if(confirmNeeded && !confirm){
            return;
        }

        // let history1 = useHistory();
        //let x = new window.History();//  history1.push("/account/login");
        try {
            if (!AuthService.shop) {
                //history1.push("/account/login");
                // x.pushState({}, "title", "/account/login");
                AuthService.shop = PageInteractor.getQueryValueByName('shop');
                if (!AuthService.shop) {
                    this.props.history.push("/account/login");
                    AuthService.user = 'dec01_2019@22_05.com';
                    return;
                }
            }
            let srch = window.location.search
            if (srch && srch.indexOf("?") > -1) srch += "&plan_name=" + chosenPlan;
            else srch += "?plan_name=" + chosenPlan;

            if(previousPlan){
                srch += "&previous_plan=" + previousPlan;
            }
            

            let shop = AuthService.shop.replace(".myshopify.com", "") + ".myshopify.com";
            if (!PageInteractor.getQueryValueByName('shop'))
                srch += "&shop=" + shop;
            srch += "&start_charge=true&email=" + AuthService.user;//#to remove this email before checking nothing affected

            if (chosenPlan === 'Free') {
                window.location.href = "/api/charge_activated" + srch;
            }
            else {
                window.location.href = "/api/ShopifyAuth/Auth" + srch;
            }

        } catch (e) {
            console.log(e.stack);
            alert(e.message);
            // x.pushState({},"title","/account/login");
        }
    }
}