import { AbandonedCheckoutAlerts } from './sections/AbandonedCheckoutAlerts';
import { useEffect, useState } from 'react';
import { LegacyCard, Tabs } from '@shopify/polaris';
import { useSearchParams } from 'react-router-dom';
import { SubscriptionsTable } from './sections/SubscriptionsTable';
//import SubscriptionsTableNew from './sections/SubscriptionsTableNew';

function DataLists() {
    console.log('DataLists_calling')
    var tabs = [

        {
            id: 'restockAlertSubscriptions',
            content: 'Restock Alert Subscriptions', //'Subscription Form',
            accessibilityLabel: 'Restock Alert Subscriptions',
            panelID: 'RestockAlertSubscriptions',
        },
        {
            id: 'AbandonedCarts',
            content: 'Abandoned Checkouts',
            accessibilityLabel: 'AbandonedCarts',
            panelID: 'AbandonedCarts',
        }
    ];

    const [searchParams,setSearchParams] = useSearchParams();
    useEffect(()=>{
        const tabId =  searchParams.get('tab');
        
        console.log('DataLists_tabs_count_' + tabs.length);
        tabs.some((tab,tabIndex)=>{
            if(tab.id == tabId){
                setSelected(tabIndex);
                return true;
            }
        });
        if(!tabId)setSearchParams({'tab':tabs[0].id});
    },[]);

    const [selected, setSelected] = useState(0);
    const handleTabChange = (v) => {
        setSelected(v);
        setSearchParams({'tab':tabs[v].id});
    };

    return <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section >
            {selected === 0 && <SubscriptionsTable />}
            {selected === 1 && <AbandonedCheckoutAlerts />}
        </LegacyCard.Section>
    </Tabs>

}

export default DataLists