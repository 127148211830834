
export class PageInteractor {

    static executeAfterCondition = function (inCondition, handler, timeLimit, executeAfterTimeLimit, inInterval) {
        if (timeLimit === void 0) { timeLimit = 120000; }
        if (executeAfterTimeLimit === void 0) { executeAfterTimeLimit = false; }
        if (inInterval === void 0) { inInterval = 25; }
        if (executeAfterTimeLimit === void 0) {
            executeAfterTimeLimit = false;
        }
        var x = typeof inCondition;
        var y = typeof handler;
        if (typeof inCondition !== "function" || typeof handler !== "function") {
            console.log('In condition and handler should be a function ');
            return;
        }
        try {
            if (inCondition() === true || inCondition() === false) {
                console.log('A bool returned');
            }
            else {
                console.log('suppplied condition not returned a boolean...');
                return;
            }
        }
        catch (e: any) {
            console.log('executing condition function thrown following error...' + e.stack);
            return;
        }
        if (typeof timeLimit === 'undefined' || timeLimit === null || timeLimit < 0) {
            timeLimit = 120000;
        }
        var checkTimer = setInterval(function () {
            try {
                if (inCondition()) {
                    try {
                        handler();
                    }
                    catch (e: any) {
                        console.log(e.stack);
                    }
                    clearInterval(checkTimer);
                }
            }
            catch (e: any) {
                console.log(e.stack);
            }
        }, inInterval);
        setTimeout(function () {
            try {
                if (typeof executeAfterTimeLimit !== undefined && executeAfterTimeLimit !== null && executeAfterTimeLimit) {
                    handler();
                }
                //console.log('timer about to clear for long run...');
                clearInterval(checkTimer);
            }
            catch (e: any) {
                console.log(e.stack);
            }
        }, timeLimit);
    };

    static getQueryValueByName(queryName) {
        if (typeof window.location.search === "undefined") {
            return null;
        }
        if (typeof queryName === "undefined" || queryName === null) {
            console.log('queryName does not provided...');
            return null;
        }
        var queryPart = window.location.search.replace('?', '');
        var queryArray = queryPart.split('&');
        let queryObjectArray: any[] = [];
        try {
            queryArray.forEach(function (item, index) {
                var nameVal = item.split('=');
                queryObjectArray.push({ qName: nameVal[0], qValue: nameVal[1] });
            });
        }
        catch (e: any) {
            console.log(e.stack);
            return null;
        }
        var queryValue;
        try {
            queryObjectArray.forEach(function (item) {
                if (item.qName === queryName) {
                    queryValue = item.qValue;
                    return false;
                }
            });
            return queryValue;
        }
        catch (e: any) {
            console.log(e.stack);
            return null;
        }
    };

    static attachNewQueryString(urlString: string, queryString: string): string {
        let returnVal;
        if (queryString.substring(0, 1) == '&')
            queryString = queryString.substring(1);
        try {
            if (urlString.indexOf('?') > -1) {
                returnVal = urlString + '&' + queryString;
            }
            else
                returnVal = urlString + '?' + queryString;
        } catch (e: any) {
            console.log(e.stack);
        }
        return returnVal;
    }

    static replaceNewQueryString(urlString: string, queryString: string): string {

        let url = new URL(urlString);

        if (queryString.substring(0, 1) == '&')
            queryString = queryString.substring(1);

        let splitted = queryString.split('=');

        url.searchParams.set(splitted[0], splitted[1])

        return url.href;

    }

    static addJquery(callback?) {
        return new Promise((resolve, reject) => {
            if (!document.getElementById('jqueryAddedByBrowserPAContentPA')) {
                console.log('jquery not available');
                var jqScript = document.createElement('script');
                jqScript.setAttribute('integrity', "sha256-hwg4gsxgFZhOsEEamdOYGBf13FyQuiTwlAQgxVSNgt4=");
                jqScript.setAttribute('crossorigin', "anonymous");
                jqScript.id = 'jqueryAddedByBrowserPAContentPA';
                jqScript.src = "https://code.jquery.com/jquery-3.2.1.min.js";
                jqScript.onload = () => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                };
                document.head.appendChild(jqScript);
                resolve('done')
            }
            else {
                console.log('jquery already available');
                if (typeof callback === 'function') {
                    callback();
                }
                resolve('done')
            }
        });
    }

    static setQueryString(name, value) {
        const url: any = new URL(window.location.href);
        url.searchParams.set(name, value);
        window.history.pushState({}, '', url);
    }
}