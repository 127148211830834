import * as React from 'react';
import { LegacyCard, Button, Badge,  TextField, SkeletonBodyText, SkeletonDisplayText, VerticalStack, Toast, DescriptionList } from '@shopify/polaris';
import { AuthService } from '../../commons/AuthService';
import { ModalWithElements } from '../sub-components/ModalWithElements';
import { environment } from '../../environment';
import PolarisBanner from '../sub-components/PolarisBanner';
import { Navigate, redirect } from 'react-router-dom';
import axios from 'axios';

var localHomePageData: any = {};
// var setCountsTemporarilyFromLocalstorage = () => {
//     try {

//         localHomePageData = localStorage.getItem('localHomePageData');
//         if (typeof localHomePageData === "string") {
//             try {
//                 localHomePageData = JSON.parse(localHomePageData);

//             } catch (e) {
//                 console.log(e)
//             }
//             if(localHomePageData && localHomePageData.vastshubSubscriptionCount && localHomePageData.vastshubSubscriptionCount.status ==='error'){
//                 localHomePageData = {};
//             }
//         }
//         localHomePageData = localHomePageData || {};


//     } catch (e) {
//         console.log(e)
//     }
// }
// setCountsTemporarilyFromLocalstorage();
delete localStorage['localHomePageData']


class Welcome extends React.Component<any, any> {

    shop: any = environment.shop
    responseJson = null;
    constructor(props) {
        super(props);
    }

    state = {
        selected: 0,
        restocktotal: localHomePageData.vastshubSubscriptionCount || 0,
        abandonedCartAlertsCount: localHomePageData.abandonedCartAlertsCount || 0,
        //BISTurnedOn: typeof localHomePageData.bisTurnedOn === 'boolean' ? localHomePageData.bisTurnedOn : true,
        BISTurnedOn: 'loading',
        BISTurnedOnInprogress: false,
        // abandonedCartAlertsTurnedOn: typeof localHomePageData.abandonedCheckoutAlertTurnedOn === 'boolean' ?
        //     localHomePageData.abandonedCheckoutAlertTurnedOn : true,
        abandonedCartAlertsTurnedOn: 'loading',
        abandonedCartAlertsTurnedOnInprogress: false,
        showVideoPopup: false,
        videoIndex: 0,
        overallTotalCount: localHomePageData.overallTotalCount || '...',
        thisMonthCount: (localHomePageData.thisMonthCount || localHomePageData.thisMonthCount == 0 ? localHomePageData.thisMonthCount : '...'),
        biSalertSentCountTotal: localHomePageData.biSalertSentCountTotal || '...',
        waNumberToNotify: localHomePageData['waNumberToNotify'] || '',
        chatSettingsSaving: false,
        autoRespondMsg: 'loading',// localHomePageData['autoRespondMsg'] || "",

        orderConfirmationTurnedOn: 'loading',
        orderConfirmationTurnedOnInprogress: false,
        orderConfirmationCount: 0,

        orderShipAlertTurnedOn: 'loading',
        orderShipAlertTurnedOnInprogress: false,
        orderShipAlertCount: 0,

        navTo: null,

        chatButtonTurnedOn: 'loading',
        chatButtonTurnedOnInprogress: false,
        chatButtonClickCount: null,
        whatsappPhoneNumber: null,
        showErrorToast: false,
        showNormalToast: false
    }
    normalToastMessage;
    errorToastMessage;

    abortController = new AbortController();

    async componentDidMount() {
        document.title = "Vatshub Dashboard Welcome";

        await environment.waitB4Call();

        let varToDetroy2 = setTimeout(async () => {
            try {
                //await environment.waitB4Call();
                console.log('componentDidMount______2')
                let resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.getHomePageData
                    + '?shop=' + this.shop);

                let responseJson = await resp.json();

                if (!responseJson) responseJson = {};

                if (responseJson && responseJson.vastshubSubscriptionCount && responseJson.vastshubSubscriptionCount.status === 'error') {
                    return;
                }

                this.responseJson = responseJson;

                this.setState({
                    restocktotal: responseJson.vastshubSubscriptionCount || 0,
                    abandonedCartAlerts: responseJson.abandonedCartAlertsCount || 0,
                    BISTurnedOn: typeof responseJson.bisTurnedOn === "boolean" ? responseJson.bisTurnedOn : true,
                    abandonedCartAlertsTurnedOn: typeof responseJson.abandonedCheckoutAlertTurnedOn === "boolean" ?
                        responseJson.abandonedCheckoutAlertTurnedOn : true,
                    biSalertSentCountTotal: responseJson.biSalertSentCountTotal || 0,
                    overallTotalCount: responseJson.overallTotalCount || 0,
                    thisMonthCount: responseJson.thisMonthCount || 0,
                    waNumberToNotify: responseJson.waNumberToNotify || '',
                    autoRespondMsg: responseJson.autoRespondMsg,
                    orderConfirmationTurnedOn: typeof responseJson.orderConfirmationTurnedOn === "boolean" ?
                        responseJson.orderConfirmationTurnedOn : false,
                    orderShipAlertTurnedOn: typeof responseJson.orderShipAlertTurnedOn === "boolean" ?
                        responseJson.orderShipAlertTurnedOn : false,
                    chatButtonTurnedOn: typeof responseJson.chatButtonTurnedOn === "boolean" ?
                        responseJson.chatButtonTurnedOn : false,

                    whatsappPhoneNumber: responseJson.whatsappPhoneNumber

                });

                resp = null; resp = undefined;
            } catch (e) {
                console.log(e);
            }
            varToDetroy2 = null;
            varToDetroy2 = undefined;
        }, 2);


    }

    componentWillUnmount() {
        this.abortController.abort();
        //setCountsTemporarilyFromLocalstorage();
    }

    render_(): React.ReactNode {
        return (<div>Welcome</div>);
    }

    WhiteIcon = () => {
        return (
            <svg fill='rgb(255 255 255)' viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3.515-8.858l-5-3A1.001 1.001 0 0 0 7 7v6a1.001 1.001 0 0 0 1.515.857l5-3a1.002 1.002 0 0 0 0-1.715zM9 11.233V8.766L11.057 10 9 11.233z"></path></svg>
        )
    }

    skeletonCard = (<LegacyCard sectioned>
        <VerticalStack>
            <SkeletonBodyText />
            <hr />
            <SkeletonDisplayText size="small" />
        </VerticalStack>
    </LegacyCard>)

    render() {

        return (<React.Fragment>
            {this.state.showErrorToast && <Toast content={this.errorToastMessage} duration={3000} error={true} onDismiss={() => { this.setState({ showErrorToast: false }) }} />}
            {this.state.showNormalToast && <Toast content={this.normalToastMessage} duration={3000} onDismiss={() => { this.setState({ showNormalToast: false }) }} />}
            {this.state.showVideoPopup && <ModalWithElements modalObject={{ videoIndex: this.state.videoIndex }}
                handleYesOrNoResult={() => { this.setState({ showVideoPopup: false }); }}
                elements={this.videoElement}
                messageToDisplay="   "
                doNotShowButtons={true}
            />}

            <div className="welcome-container" >
                {/* <LegacyCard sectioned title="Getting started" actions={[{ content: 'Back To Shopify Admin', onAction: () => { window.location.href = 'https://' + AuthService.shop + '.myshopify.com/admin'; } }]}>
                    <div>
                        <h4>Check out our short videos:</h4>

                        <div data-test="row" className="row videos-row">
                            <div data-md="4" data-test="col" className="col-md-4 videos-col"> <div className="col-inner" data-index="1" onClick={this.playVideo}> Customize WhatsApp Me button for back in stock
                                <div data-index="1" style={{ display: 'contents' }}><img data-index="1" style={{ marginTop: '14px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div> </div>

                            <div data-md="4" data-test="col" className="col-md-4 videos-col"> <div className="col-inner" data-index="2" onClick={this.playVideo}><div data-index="2" >Customize back in stock popup form</div>
                                <div data-index="2" style={{ display: 'contents' }}><img data-index="2" style={{ marginTop: '14px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div> </div>

                            <div data-md="4" data-test="col" className="col-md-4 videos-col" > <div className="col-inner" data-index="3" onClick={this.playVideo}> Choose a template for back in stock WhatsApp alert
                                <div data-index="3" style={{ display: 'contents' }}><img data-index="3" style={{ marginTop: '14px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div></div>

                            <div data-md="4" data-test="col" className="col-md-4 videos-col" > <div className="col-inner" data-index="4" onClick={this.playVideo}> Choose a template for abandoned checkout alerts
                                <div data-index="4" style={{ display: 'contents' }}><img data-index="4" style={{ marginTop: '14px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div></div>

                            <div data-md="4" data-test="col" className="col-md-4 videos-col" > <div className="col-inner" data-index="5" onClick={this.playVideo}>Change pricing plan <br />

                                <div data-index="5" style={{ display: 'contents' }} className="skip-one-line" ><img data-index="5" style={{ marginTop: '33px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div></div>
                            <div data-md="4" data-test="col" className="col-md-4 videos-col" > <div className="col-inner" data-index="6" onClick={this.playVideo}>Data Lists <br /> <br />

                                <div data-index="6" style={{ display: 'contents' }} className="skip-one-line" ><img data-index="6" style={{ marginTop: '14px' }} onClick={this.playVideo} src='1.png' /></div>
                            </div></div>
                        </div>

                        <div></div>
                        <div></div>
                    </div>

                </LegacyCard> */}

                <LegacyCard sectioned title="Getting started" actions={[{ content: 'Back To Shopify Admin', onAction: () => { window.location.href = 'https://' + AuthService.shop + '.myshopify.com/admin'; } }]}>
                   
                    <DescriptionList
                        items={[
                            {
                                term: 'Turn On/Off available features',
                                description:
                                   <>
                                   <div><Button onClick={()=>{window.document.getElementById('turnOnOff').scrollIntoView();}} plain>Here</Button></div>
                                   <p>The <strong>Back in stock</strong> messaging turned <strong>ON by default</strong>, Other automated WhatsApp alerts like <strong>Abandoned checkout, Order confirmation, Order shipment alert</strong> and <strong>Chat button</strong> are turned<strong> OFF by default</strong>. Please turn ON or OFF these features at bottom of this page based on your shop requirements.</p></> ,
                            },
                            // {
                            //     term: 'Respond to queries',
                            //     description:
                            //         'Customers used to reply/ask questions after they receive the automated WhatsApp notifications. Respond to them by setting autometed first response, you can also manually reply them for any specific questions.',
                            // },
                            {
                                term: 'Customize',
                                description:
                                   <><div><Button plain onClick={() => { this.setState({ navTo: '/customize?tab=subscription-form' }); }}>Customize</Button></div><p>Change texts, appearances of buttons & Back in stock subscription form. Change and use your own templates for automated messages.</p></> ,
                            },
                            // {
                            //     term: 'Connect WhatsApp',
                            //     description:
                            //        <><div><Button plain onClick={() => { this.setState({ navTo: '/connect-wa' }); }}>Here</Button></div><p>Connect your own WhatsApp number instead of Vatshub app's default bot number</p></> ,
                            // },
                        ]}
                    />
                </LegacyCard>


                {this.state.autoRespondMsg === 'loading' && this.skeletonCard}
                {this.state.autoRespondMsg !== 'loading' && <LegacyCard sectioned title="Respond to queries" >

                    <div className="chat-settings-containers">

                        <div className="autoRespondMsg chat-settings-containers-inner" >
                            <div>
                                <TextField autoComplete='off' id="autoRespondMsg"
                                    label="This is the message auto-replied when your customers first reply to your WhatsApp notifications"
                                    value={this.state.autoRespondMsg}
                                    onChange={this.handleTextFieldChange}
                                    multiline={3}
                                />

                            </div>

                        </div>
                    </div>

                    <div className="chat-settings-containers">
                        <div className="whatsno-to-notify chat-settings-containers-inner">
                            <div>
                                <TextField autoComplete='off' type="number" id="waNumberToNotify"
                                    label="Your WhatsApp number to send notification to you when your customer message"
                                    value={this.state.waNumberToNotify}
                                    onChange={this.handleTextFieldChange}
                                />
                                <Button loading={this.state.chatSettingsSaving} primary onClick={() => { this.setState({ chatSettingsSaving: true }, this.saveChatSettings); }}>Save</Button>
                            </div>

                        </div>
                    </div>

                </LegacyCard>}

              
                <LegacyCard sectioned title="Overall Whatsapp Messages">
                <p id='turnOnOff' ></p>
                    <p className="do-float-left">Total sent: {this.state.overallTotalCount}</p> <p style={{ "marginLeft": "60%" }}>Sent this month: {this.state.thisMonthCount}</p>
                
                </LegacyCard>


                <div>
                    <h1 className='turn-on-off-feat Polaris-Text--headingMd'>Turn ON/OFF Features</h1>
                </div>

                {this.state.BISTurnedOn === 'loading' && this.skeletonCard}
                {this.state.BISTurnedOn !== 'loading' && <LegacyCard sectioned title="Back in stock alerts" actions={[{ content: 'Customize', onAction: () => { this.setState({ navTo: '/customize?tab=subscription-form' }); } }, { content: 'Change template', onAction: () => { this.setState({ navTo: '/customize?tab=bis-template' }); } }, { content: 'Show data list', onAction: () => { this.setState({ navTo: '/data-lists?tab=restockAlertSubscriptions' }); } }]}>

                    <p>Total sent: {this.state.biSalertSentCountTotal}</p>
                    <p>Total alert subscriptions: {this.state.restocktotal} </p>
                    <div className="status-p">Status: {this.state.BISTurnedOn ? <React.Fragment><Badge status="success">Turned On</Badge>
                        <Button loading={this.state.BISTurnedOnInprogress} onClick={() => { this.setState({ BISTurnedOn: false, BISTurnedOnInprogress: true }, this.turnOnOff); }} destructive>Turn Off</Button></React.Fragment> : <React.Fragment><Badge status="attention">Turned Off</Badge>
                        <Button loading={this.state.BISTurnedOnInprogress} primary onClick={() => { this.setState({ BISTurnedOn: true, BISTurnedOnInprogress: true }, this.turnOnOff); }}>Turn On</Button>
                        <div className='not-sending-banner'>
                            <PolarisBanner status="warning"
                                title="Currently not sending alerts, turn on to resume sending"

                            />
                        </div>
                    </React.Fragment>}</div>
                </LegacyCard>



                }

                {this.state.chatButtonTurnedOn === 'loading' && this.skeletonCard}
                {this.state.chatButtonTurnedOn !== 'loading' && <LegacyCard sectioned title="Chat button" actions={[{ content: 'Customize', onAction: () => { this.setState({ navTo: '/customize?tab=chat-button' }); } }]}>



                    <div className='home-page-wa-number-container'>
                        <div className='home-page-wa-number'>
                            <TextField id="whatsappPhoneNumber"
                                label="WhatsApp phone number"
                                value={this.state.whatsappPhoneNumber}
                                onChange={this.handleTextFieldChange}
                                autoComplete='off'
                            />
                        </div>
                        <div className='home-page-wa-number-save'>
                            <span>
                                <Button onClick={this.saveWhatsappPhoneNumber} plain>Save number</Button></span>
                        </div>
                    </div>


                    <div style={{ height: '16px' }}></div>

                    <div className="status-p">Status: {this.state.chatButtonTurnedOn ? <React.Fragment>

                        <Badge status="success">Turned On</Badge>
                        <Button loading={this.state.chatButtonTurnedOnInprogress} onClick={() => { this.setState({ chatButtonTurnedOn: false, chatButtonTurnedOnInprogress: true }, this.turnOnOff); }} destructive>Turn Off</Button></React.Fragment> : <React.Fragment><Badge status="attention">Turned Off</Badge>
                        <Button loading={this.state.chatButtonTurnedOnInprogress} primary onClick={() => {
                            if (!this.state.whatsappPhoneNumber || this.state.whatsappPhoneNumber.length < 1) {
                                this.errorToastMessage = 'Please provide WhatsApp number with country code to turn on this feature';
                                this.setState({ showErrorToast: true });
                                return;
                            }
                            this.setState({ chatButtonTurnedOn: true, chatButtonTurnedOnInprogress: true }, this.turnOnOff);
                        }}>Turn On</Button>
                        <div className='not-sending-banner'>
                            <PolarisBanner status="warning"
                                title="Chat button turned off , turning it ON will display the WhatsApp chat button in your site, so that your customers can contact you through WhatsApp"

                            />
                        </div>
                    </React.Fragment>}</div>
                </LegacyCard>

                }

                {this.state.abandonedCartAlertsTurnedOn === 'loading' && this.skeletonCard}
                {this.state.abandonedCartAlertsTurnedOn !== 'loading' && <LegacyCard sectioned title="Abandoned checkout alerts" actions={[{ content: 'Change template', onAction: () => { this.setState({ navTo: '/customize?tab=abandoned-checkout' }); } }, { content: 'Show data list', onAction: () => { this.setState({ navTo: '/data-lists?tab=AbandonedCarts' }); } }]}>                    <p>Total sent: {this.state.abandonedCartAlertsCount} </p>
                    <div className="status-p">Status: {this.state.abandonedCartAlertsTurnedOn ? <React.Fragment><Badge status="success">Turned On</Badge>
                        <Button loading={this.state.abandonedCartAlertsTurnedOnInprogress} destructive onClick={() => { this.setState({ abandonedCartAlertsTurnedOn: false, abandonedCartAlertsTurnedOnInprogress: true }, this.turnOnOff); }}>Turn Off
                        </Button>


                    </React.Fragment> : <React.Fragment><Badge status="attention">Turned Off</Badge>
                        <Button loading={this.state.abandonedCartAlertsTurnedOnInprogress} primary onClick={() => { this.setState({ abandonedCartAlertsTurnedOn: true, abandonedCartAlertsTurnedOnInprogress: true }, this.turnOnOff); }}>Turn On</Button>

                        <div className='not-sending-banner'>
                            <PolarisBanner status="warning"
                                title="Currently not sending alerts, turn on to resume sending"

                            />
                        </div>
                    </React.Fragment>}</div>


                </LegacyCard>}


                {this.state.orderConfirmationTurnedOn === 'loading' && this.skeletonCard}
                {this.state.orderConfirmationTurnedOn !== 'loading' && <LegacyCard sectioned title="Order confirmation" actions={[{ content: 'Change template', onAction: () => { this.setState({ navTo: '/customize?tab=order-confirmation' }); } }, { content: 'Show data list', disabled: true, onAction: () => { this.setState({ navTo: '/data-lists?tab=order-confirmation' }); } }]}>                    <p>Total sent: {this.state.orderConfirmationCount} </p>
                    <div className="status-p">Status: {this.state.orderConfirmationTurnedOn ? <React.Fragment><Badge status="success">Turned On</Badge>
                        <Button loading={this.state.orderConfirmationTurnedOnInprogress} destructive onClick={() => { this.setState({ orderConfirmationTurnedOn: false, orderConfirmationTurnedOnInprogress: true }, this.turnOnOff); }}>Turn Off
                        </Button>


                    </React.Fragment> : <React.Fragment><Badge status="attention">Turned Off</Badge>
                        <Button loading={this.state.orderConfirmationTurnedOnInprogress} primary onClick={() => { this.setState({ orderConfirmationTurnedOn: true, orderConfirmationTurnedOnInprogress: true }, this.turnOnOff); }}>Turn On</Button>

                        <div className='not-sending-banner'>
                            <PolarisBanner status="warning"
                                title="Currently not sending alerts, turn on to resume sending"

                            />
                        </div>
                    </React.Fragment>}</div>


                </LegacyCard>}


                {this.state.orderShipAlertTurnedOn === 'loading' && this.skeletonCard}
                {this.state.orderShipAlertTurnedOn !== 'loading' && <LegacyCard sectioned title="Order ship alert" actions={[{ content: 'Change template', onAction: () => { this.setState({ navTo: '/customize?tab=order-ship-alert' }); } }, { content: 'Show data list', disabled: true, onAction: () => { this.setState({ navTo: '/data-lists?tab=order-ship-alert' }); } }]}>                    <p>Total sent: {this.state.orderShipAlertCount} </p>
                    <div className="status-p">Status: {this.state.orderShipAlertTurnedOn ? <React.Fragment><Badge status="success">Turned On</Badge>
                        <Button loading={this.state.orderShipAlertTurnedOnInprogress} destructive onClick={() => { this.setState({ orderShipAlertTurnedOn: false, orderShipAlertTurnedOnInprogress: true }, this.turnOnOff); }}>Turn Off
                        </Button>


                    </React.Fragment> : <React.Fragment><Badge status="attention">Turned Off</Badge>
                        <Button loading={this.state.orderShipAlertTurnedOnInprogress} primary onClick={() => { this.setState({ orderShipAlertTurnedOn: true, orderShipAlertTurnedOnInprogress: true }, this.turnOnOff); }}>Turn On</Button>

                        <div className='not-sending-banner'>
                            <PolarisBanner status="warning"
                                title="Currently not sending alerts, turn on to resume sending"

                            />
                        </div>
                    </React.Fragment>}</div>


                </LegacyCard>}






            </div>
            {this.state.navTo && <Navigate to={this.state.navTo} />}

        </React.Fragment>);
        /*
         <p className="status-p">Status: {this.state.abandonedCartAlertsTurnedOn ? "Turned on" : "Turned Off"} {this.state.abandonedCartAlertsTurnedOn && <Button destructive>Turn Off</Button>}</p>
         */

    }

    playVideo = (e) => {
        //e.persist();
        e.preventDefault();
        e.stopPropagation();
        try {
            if (!e.target) {
                console.log('can"t find target element of the click');
                return;
            }
            let videoIndex = e.target.getAttribute('data-index');
            if (videoIndex) {
                this.setState({
                    showVideoPopup: true,
                    videoIndex: videoIndex
                });

            }


            /*

            let eTargetclasses = e.target.className.baseVal ? e.target.className.baseVal : e.target.className;
            if (typeof eTargetclasses !== 'string') eTargetclasses = "";
            if (!eTargetclasses.match('col-inner')) {
                let el2 = e.target['parentElement']
                for (var i = 0; i < 20; i++) {

                    let classes = el2.className.baseVal ? el2.className.baseVal : el2.className
                    if (!classes.match('col-inner')) {
                        el2 = el2['parentElement']; 
                    }
                    else {
                        videoIndex = el2.getAttribute('data-index');
                        break;
                    }
                }
                if (isNaN(videoIndex)) videoIndex = null;
            }

            if (!videoIndex && !eTargetclasses.match('col-inner')) {

                let el2 = e.target.getElementsByClassName('col-inner')[0];

                if (el2)
                    videoIndex = el2.getAttribute('data-index');
                if (isNaN(videoIndex)) videoIndex = null;
            }


            if (eTargetclasses.match('col-inner')) videoIndex = e.target.getAttribute('data-index');
            this.setState({
                showVideoPopup: true,
                videoIndex: videoIndex
            });
            */
        } catch (e) {

        }
    }

    videoElement = () => {
        //return <div><iframe className="video-player-iframe" src="https://app.vatshub.xyz/static/media/Select%20BIS%20message%20template.webm"> </iframe></div>

        let link = 'https://player.vimeo.com/video/';

        console.log(this.state.videoIndex);

        if (this.state.videoIndex == 1) {
            link += '392780299';
        }
        else if (this.state.videoIndex == 2) {
            link += '392780701';
        }
        else if (this.state.videoIndex == 3) {
            link += '392780407';
        }
        else if (this.state.videoIndex == 4) {
            link += '392780558';
        }
        else if (this.state.videoIndex == 5) {
            link += '392780649';
        }
        else if (this.state.videoIndex == 6) {
            link += '392780492';
        }

        return <div><iframe className="video-player-iframe" src={link} width="640" height="324" allow="autoplay; fullscreen" ></iframe></div>
    }


    turnOnOff = async () => {
        // let localHomePageData: any = localStorage.getItem('localHomePageData');
        // if (typeof localHomePageData === "string") {
        //     try {
        //         localHomePageData = JSON.parse(localHomePageData);

        //     } catch (e) {
        //         console.log(e)
        //     }
        // }

        // localHomePageData.bisTurnedOn = this.state.BISTurnedOn;
        // localHomePageData.abandonedCheckoutAlertTurnedOn = this.state.abandonedCartAlertsTurnedOn;
        // localHomePageData.vastshubSubscriptionCount = localHomePageData.vastshubSubscriptionCount || 0;
        // localHomePageData.abandonedCartAlertsCount = localHomePageData.abandonedCartAlertsCount || 0;
        // localStorage.setItem('localHomePageData', JSON.stringify(localHomePageData));

        const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.setHomePageData + '?shop=' + this.shop +
            '&backInStockTurnedOn=' + this.state.BISTurnedOn + '&abandonedCheckoutAlertsTurnedOn=' + this.state.abandonedCartAlertsTurnedOn
            + '&orderConfirmationTurnedOn=' + this.state.orderConfirmationTurnedOn
            + '&orderShipAlertTurnedOn=' + this.state.orderShipAlertTurnedOn
            + '&chatButtonTurnedOn=' + this.state.chatButtonTurnedOn
            + '&whatsappPhoneNumber=' + this.state.whatsappPhoneNumber);

        let responseJson = await resp.json();
        if (!responseJson) responseJson = {};
        console.log(responseJson);

        setTimeout(() => {
            this.setState({
                BISTurnedOnInprogress: false,
                abandonedCartAlertsTurnedOnInprogress: false,
                orderConfirmationTurnedOnInprogress: false,
                orderShipAlertTurnedOnInprogress: false,
                chatButtonTurnedOnInprogress: false
            });
        }, 20);

    }

    handleTextFieldChange = (val, id) => {
        this.setState({ [id]: val });
    }

    saveChatSettings = async () => {
        this.setState({ chatSettingsSaving: true });
        try {
            // alert('abount to save this.state.waNumberToNotify ' + this.state.waNumberToNotify);

            // let localHomePageData: any = localStorage.getItem('localHomePageData');
            // if (typeof localHomePageData === "string") {
            //     try {
            //         localHomePageData = JSON.parse(localHomePageData);

            //     } catch (e) {
            //         console.log(e)
            //     }
            // }

            // localHomePageData.bisTurnedOn = this.state.BISTurnedOn;
            // localHomePageData.abandonedCheckoutAlertTurnedOn = this.state.abandonedCartAlertsTurnedOn;
            // localHomePageData.vastshubSubscriptionCount = localHomePageData.vastshubSubscriptionCount || 0;
            // localHomePageData.abandonedCartAlertsCount = localHomePageData.abandonedCartAlertsCount || 0;
            // localHomePageData.waNumberToNotify = this.state.waNumberToNotify || null;
            // localHomePageData.autoRespondMsg = this.state.autoRespondMsg || null;

            // localStorage.setItem('localHomePageData', JSON.stringify(localHomePageData));

            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.setHomePageData + '?shop=' + this.shop +
                '&backInStockTurnedOn=' + this.state.BISTurnedOn + '&abandonedCheckoutAlertsTurnedOn=' + this.state.abandonedCartAlertsTurnedOn
                + '&waNumberToNotify=' + this.state.waNumberToNotify + '&autoRespondMsg=' + this.state.autoRespondMsg + '&orderConfirmationTurnedOn=' + this.state.orderConfirmationTurnedOn
                + '&chatButtonTurnedOn=' + this.state.chatButtonTurnedOn + '&whatsappPhoneNumber=' + this.state.whatsappPhoneNumber);
            //#to add shipalert not added yet?

            let responseJson = await resp.json();
            if (!responseJson) responseJson = {};
            console.log(responseJson);
        } catch (e) {
            console.log(e);
        }

        this.setState({ chatSettingsSaving: false });
    }

    //saveAutoRespondMsg = () => {
    //    setTimeout(() => {
    //        this.setState({ autoRespondMsgSaving: false });
    //        alert('abount to save this.state.autoRespondMsg ' + this.state.autoRespondMsg);
    //    }, 7000);
    //}

    saveWhatsappPhoneNumber = async () => {

        //  console.log(324345545)
        //  console.log(this);
        //  return;
        try {
            const req = await axios({
                method: 'POST',
                url: environment.mainAppOriginUrl + '/api/db/update',
                data: {
                    collectionName: 'customizeSetting', filter: { shop: environment.removeMyshopifyDotCom(this.shop) },
                    docData: { whatsappPhoneNumber: this.state.whatsappPhoneNumber }
                }
            });
            if (req && req.data && req.data.acknowledged === true) {
                this.normalToastMessage = 'Updated';
                this.setState({ showNormalToast: true });
            }
            else {
                this.errorToastMessage = 'Something went wrong ' + (req.data.message || '');
                this.setState({ showErrorToast: true });
            }

        } catch (error) {
            console.log(error);
            this.errorToastMessage = 'Error:' + error.message;
            this.setState({ showErrorToast: true });
        }
    }
}

export default Welcome;