import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Page, LegacyCard, Button } from '@shopify/polaris';
import { AuthService } from '../../commons/AuthService';

export class ProfilePage extends React.Component {
    constructor(props: any) {

       super(props);
       
    }

    state = { choosePlanClicked: false }
    shop = AuthService.shop;
    email = AuthService.user;
    planName = AuthService.planName;
    componentDidMount() {
        document.title = "Profile-Vatshub Dashboard";
    }
    render() {

        return <div className="profile-page">
            {(!this.shop || this.state.choosePlanClicked )&& <Navigate to='/choose-plan' />}
            <Page
                title=" "            >

                <LegacyCard title="Store myshopify.com domain" sectioned>
                    <p>{this.shop}.myshopify.com</p>
                </LegacyCard>

                <LegacyCard title="Plan" sectioned>
                    <p>{this.planName ? this.planName : <Button primary onClick={() => { this.setState({ choosePlanClicked:true}); }}>Choose plan</Button>}</p>
                </LegacyCard>
            </Page>
        </div>
    }
}