import * as React from 'react';
import { SketchPicker } from 'react-color';

export class VatsColorPicker extends React.Component<any,any> {

    //constructor(props:any) {
    //    super(props);

    //}

    state = {
        hexColor: this.props.initialColor//'#fff'
    }

    render() {
        return <SketchPicker
            color={this.state.hexColor}
            onChangeComplete={this.handleChangeComplete}
        />
    }

    handleChangeComplete = (color) => {
     
        this.setState({ hexColor: color.hex });
        this.props.onColorChange(color,this.props.id);
    }
}