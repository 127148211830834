import * as React from 'react';
import { TextField, Checkbox } from '@shopify/polaris';
import { environment } from '../../environment';

var val1 = "sdsdsd";
export class SubscriptionEdit extends React.Component<any,any> {

    constructor(props: any) {
        super(props);
    }

    state = {
        model: this.props.modalObject,
        alertSent:false
    }

    componentDidMount() {

        if (this.state.model.triggerCount > 0) {
            this.setState({
                alertSent:true
            });
        }
        else {
            this.setState({
                alertSent: false
            });
        }
      
    }

    render() {
        return <div className="subscription-edit-conatiner">
            <TextField autoComplete='off' id="vatshubnumber"
                label="WhatsApp number"
                value={this.state.model.vatshubnumber}
                onChange={this.handleTextFieldChange}
            />
            <Checkbox
                label="Mark as alert sent"
                checked={this.state.alertSent}
                value={val1}
                onChange={this.handleAlertSentChange}
            />
        </div>
    }

    handleTextFieldChange = (val, id) => {

        let x = this.state.model
        x[id] = val;
        if (x[id])
            this.setState({ model: x });

        this.somethingChanged();
    }

    handleAlertSentChange = (val, ds) => {
        let triggerCount;

        if (val === true && (isNaN(this.state.model.triggerCount) || this.state.model.triggerCount < 1)) {
            triggerCount = 1
        }
        else if (val === true && this.state.model.triggerCount >= 1) {
            triggerCount = this.state.model.triggerCount
        }
        else {
            triggerCount = 0;
        }

        let model = this.state.model;
        model.triggerCount = triggerCount;

        this.setState({
            alertSent: val,
            model: model
        });

        this.somethingChanged();
    }

    somethingChanged = () => {
      console.log('something changed')
        this.props.recieveOutputObject(this.state.model);
    }
}