import * as React from 'react';
import { TextField, Button, Select, Toast } from '@shopify/polaris';
import { environment } from '../../environment';
import { AuthService } from '../../commons/AuthService';
import PolarisBanner from '../sub-components/PolarisBanner';

//const css1 = require('../pages/loginStyle.css');


export class Login extends React.Component {

    //UNSAFE_componentWillMount = () => {
    //    setTimeout(() => {
    //        let heck1: any = document.getElementsByClassName('vatshub-sidebar')[0];//.style.display = 'none';
    //        let heck2: any = document.getElementsByClassName('vatshub-topbar')[0];//.style.display = 'none';
    //        heck1.style.display = 'none';
    //        heck2.style.display = 'none';

    //    },10)
    //}

    UNSAFE_componentWillMount = async () => {
        try {
            AuthService.signout();
            const resp = await fetch(environment.mainAppOriginUrl + environment.routePaths.logout,
                { headers: { Referer2: window.location.origin + '/account/login' } });

            let data = await resp.json();
            if (data.status === 'success') {
                console.log('logged out existing logins if any');
            }
        } catch (e) {
            console.log(e);
        }
        try {
            // window.document.getElementById('AppFrameNav').style.display = 'none';
            // window.document.getElementById('AppFrameTopBar').style.display = 'none';
        } catch (error) {
            console.log(error);
        }


    }

    componentWillUnmount() {
        document.title = "Login-Vatshub Dashboard";

        // document.getElementById('AppFrameNav').style.display = 'unset';
        // document.getElementById('AppFrameTopBar').style.display = 'unset';

    }

    state = {
        storeDomain: "",
        showToast: false
    }

    render() {


        return (

            <div className="login-root">
                <TextField id="storeDomain"
                    label="Store myshopify domain "
                    value={this.state.storeDomain}
                    onChange={this.handleTextFieldChange}
                    connectedRight={
                        <Select
                            label=".myshopify.com"
                            labelInline
                            disabled={true}
                        />
                    }
                    autoComplete='off'
                />

                <div className="login-btn-container"><Button primary onClick={this.loginClick}>Login</Button></div>
                {this.state.showToast && <Toast error={true} content="Please enter a store name" onDismiss={this.closeToast} />}

                <div className="login-page-banner">
                    <PolarisBanner status="default"
                        title="Login/signup by providing your Shopify shop's myshopify domain name above. You can also log in by opening the Vatshub app from your Shopify admin."

                    />
                </div>
            </div>

        );

    }

    handleTextFieldChange = (val, id) => {
        //let x = this.state.storeDomain
        //console.log(x);

        this.setState({ [id]: val });
    }

    loginClick = () => {
        if (!this.state.storeDomain) {
            // alert('Please enter a store name');//##to make this with polaris alert
            this.setState({
                showToast: true
            });
            return;
        }
        this.state.storeDomain = this.state.storeDomain.toLowerCase().replace('.myshopify.com', '');
        window.location.href = '/api/ShopifyAuth/AuthInit?shop=' + this.state.storeDomain + '.myshopify.com'
    }

    closeToast = () => {
        this.setState({
            showToast: false
        });
    }

}