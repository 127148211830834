import React from 'react';
import { Banner } from '@shopify/polaris';

export default class PolarisBanner extends React.Component {

    constructor(props) {

        super(props);
        this?this.props = props:console.log('no this')
    }

    state = {
        show:true
    }

    context; setState; forceUpdate; refs;

    render() {
        return (
            <div>
                {this.state.show && <Banner
                    title={this.props.title}
                    status={this.props.status}
                    action={this.props.action}
                    onDismiss={() => { this.setState({ show: false }) }}
                />}
            </div>
        );
    }
}
