import React from 'react';
import { Tooltip } from '@shopify/polaris';

/**
 * directly use from shopify ToolTip check customizepage for example
 */
export default class PolarisTooltip extends React.Component {

    constructor(props) {

        super(props);
        this ? this.props = props : console.log('no this')
    }

    state = {
       
    }

    context; setState; forceUpdate; refs;

    render() {
        return (
            <Tooltip  content={this.props.tooltipText}>
                {this.props.content}
            </Tooltip>
        )
    }
}
